import CommentService from '../../services/comments';

export const SET_COMMENTS = 'SET_COMMENTS';

export const fetchGetComments = (postId) => async (dispatch) => {
  try {
    const comments = await CommentService.getAllComments(postId);
    console.log(comments)
    dispatch(setComments(comments.data));
  } catch (error) {
    console.error("fetchGetComments ERROR : ", error);
  }
};

export const setComments = (comments) => ({
  type: SET_COMMENTS,
  payload: comments,
});