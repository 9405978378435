import { authenticateAxios } from "./authAxios";

class ReportServices {
    createReport = async (reportReason, itemId, itemType) => {
        try {
            const res = authenticateAxios().post('report/create_report',{
                reportReason,
                itemId,
                itemType,
              })
              console.log('create report res : ',res)
              return res
        } catch (error) {
            console.log('createreport error : ', error)
        }
    }
}

export default new ReportServices;