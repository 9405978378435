import React, { useState, useEffect } from 'react';
import '../../../../assets/css/Post.css';
import { formatDistanceToNow } from 'date-fns';
import PostContent from './PostContent';
import HangShare from './HangShare';
import PostImage from './PostImage';
import PostUserContent from './PostUserContent';
import { FaEllipsisV, FaUserAlt, FaCog, FaSignOutAlt, FaStar } from 'react-icons/fa';
import { FaRegMessage } from "react-icons/fa6";
import LikeIcon from '../../../elements/LikeIcon';
import { FaRegStar, FaShare } from "react-icons/fa";
import Swap from './Swap';
import CommentsPopup from './CommentsPopup';
import { useSelector } from 'react-redux';
import PostService from '../../../../services/post';
import authServices from '../../../../services/auth.services';


const Post = React.memo(function Post({ post , type}) {

    const [liked, setliked] = useState(post.isUserLiked)
    const [likedCount, setlikedCount] = useState(post.numberOfReactions)
    const [commentCount, setcommentCount] = useState(0)
    const [shareCount, setshareCount] = useState(0)
    const [commentOpen, setcommentOpen] = useState(false)
    const [isLoading, setLoading] = useState(false);
    const userDetails = useSelector(state => state.auth.userDetails);
    const [myPost, setmyPost] = useState(false)

    const openComment = () => {
        console.log('openComment tıklandı')
        setcommentOpen(true);
    };



    useEffect(() => {
        if(post.id === authServices.getCurrentUser().id){
            setmyPost(true)
        }
    }, [])

    const handleLikeClick = () => {
        setliked(!liked)
        setlikedCount(post.numberOfReactions+(liked ? -1 : 1))
        PostService.likeUnlikePost(post.id, post.userId)
    }

    return (
        <div>
            <div className='central-meta newsfeed'>
                <PostUserContent post={post} myPost={myPost} type={type}/>
                <div className="mt-2" style={{
                    maxHeight: '500px',
                    border: 'none',
                    overflow: 'hidden',
                }}  >
                    {post.content && <PostContent>{post.content}</PostContent>}
                    {post.postType === 'Normal' && <PostImage media={post.media} />}
                    {post.postType === 'HangShare' && post.hangShareItems.length !== 0 && <HangShare hangshare={post.hangShareItems} />}
                    {post.postType === 'HangShare' && post.hangShareItems.length === 0 && <PostImage media={post.media} />}
                    {post.postType === 'Swap' && <Swap post={post} />}
                </div>
                <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between', marginTop: '5px' }}>
                    <div style={{ display: 'flex', marginTop: '4px' }}>
                        <LikeIcon liked={liked} onClick={handleLikeClick} />
                        <span style={{ marginLeft: '5px', fontSize: '20px', marginTop: '3px' }}>{likedCount}</span>
                    </div>
                    <div style={{ display: 'flex', marginTop: '5px' }}>
                        <Icon icon={<FaRegStar />} text={likedCount} />
                        <Icon icon={<FaRegMessage />} onClick={openComment} text={post.numberOfComments} />
                        <Icon icon={<FaShare />} text={0} />
                    </div>
                </div>
            </div>
            <CommentsPopup open={commentOpen} post={post} userId={userDetails.data.id} postId={post.id} onClose={() => setcommentOpen(false)} />
        </div>
    );
});




function Icon({ icon, style, text, onClick }) {
    return (
        <div onClick={onClick} style={{
            display: 'flex',
            justifyContent: 'center',
            marginLeft: 'auto',
            alignItems: 'center',
            background: 'rgba(51, 51, 51, 0.7)',
            borderRadius: '20px',
            color: 'white',
            width: '45px',
            height: '30px',
            margin: '0 5px',
            cursor: 'pointer',
            ...style
        }}>

            <span style={{ marginRight: '4px' }}>{icon}</span>
            <span style={{ marginTop: '2px' }} >{text}</span>
        </div>
    );
}


export default Post;