import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAndSetUserDetails } from '../../store/actions/authActions';
import AuthService from '../../services/auth.services';
import UserService from '../../services/user';
import encryptionService from '../../services/encryptionService';

function ConversationsList({ conversations, selectedConversation, onConversationClick, onStartNewConversation }) {
  const [userDetails, setUserDetails] = useState({});
  const [secretKeys, setSecretKeys] = useState({});
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.auth.userDetails);

  useEffect(() => {
    const fetchUserDetails = async () => {
      const currentUser = AuthService.getCurrentUser();
      
      if (!currentUser || !currentUser.id) {
        console.error('Current user not found');
        return;
      }
  
      const details = {};
      const secrets = {};
      for (const conversation of conversations) {
        const userId = conversation.user1 === currentUser.id ? conversation.user2 : conversation.user1;
        if (!userId) {
          console.error(`Invalid userId for conversation ${conversation.id}`);
          continue;
        }
  
        try {
          const response = await UserService.getUserById(userId);
          details[conversation.id] = response.data;

          const secretKey = await encryptionService.generateSecretKeys(conversation.publicKey);
          secrets[conversation.id] = secretKey;

          
        } catch (error) {
          console.error(`Error fetching user details for conversation ${conversation.id}`, error);
        }
      }
      setUserDetails(details);
      setSecretKeys(secrets);
    };
  
    fetchUserDetails();
  }, [conversations]);

  useEffect(() => {
    if (!currentUser) {
      dispatch(fetchAndSetUserDetails(AuthService.getCurrentUser().id));
    }
  }, [dispatch, currentUser]);

  return (
    <div className="conversations-list-container">
      <button className="new-conversation-button" onClick={onStartNewConversation}>Start New Chat</button>
      {conversations.length === 0 ? (
        <div className="no-conversations">There is no chat yet.</div>
      ) : (
        conversations.map(conversation => (
          <div
            key={conversation.id}
            className={`conversation-item ${selectedConversation && selectedConversation.id === conversation.id ? 'active' : ''}`}
            onClick={() => onConversationClick(conversation)}
          >
            <img
              src={userDetails[conversation.id]?.profilePic || '../../assets/images/unnamed.png'}
              alt="Avatar"
              className="conversation-avatar"
            />
            <div>
              {userDetails[conversation.id] ? `${userDetails[conversation.id].firstName} ${userDetails[conversation.id].lastName}` : 'Unknown User'}
            </div>
          </div>
        ))
      )}
    </div>
  );
}

export default ConversationsList;
