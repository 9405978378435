import React, { useEffect, useState } from 'react';
import '../../assets/css/Alert.css';  

function Alert({ text }) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 3000);  
    return () => clearTimeout(timer);
  }, [text]);

  return (
    <div className={`alert ${isVisible ? 'slide-in' : 'slide-out'}`}>
      {text}
    </div>
  );
}

export default Alert;