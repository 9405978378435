import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchAndSetUserDetails } from '../../store/actions/authActions'
import AuthServices from '../../services/auth.services'
import LayoutComponent from '../LayoutComponent'
import { fetchStories } from '../../store/actions/storyActions'
import Posts from '../app/post/post/Posts'




export default function SwapPosts() {

    //redux
    const userDetails = useSelector(state => state.auth.userDetails);
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchAndSetUserDetails(AuthServices.getCurrentUser().id))
        dispatch(fetchStories())
    }, [dispatch])

    return (
        <>
            {userDetails && (

                <LayoutComponent user={userDetails.data}>
                    <div>
                        <p className="Posts-Title">Swap Posts</p>
                    </div>
                    <Posts type='Swap' user_id={userDetails.id} />
                </LayoutComponent>
            )}
        </>
    )
}
