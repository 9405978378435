import "./DeleteProfile.css";
import React, { useState } from "react";
import { MdCancel } from "react-icons/md";
import axios from "axios";

const baseUrl = "https://backend.shareupx.com/api/v1/auth/request/delete_account/";
const devUrl = "http://localhost:8080/api/v1/auth/request/delete_account/";

const DeleteProfile = () => {
  const [email, setEmail] = useState("");

  const onDelete = async (e) => {
    e.preventDefault();
    // Check if email is empty
    if (email === "") {
      alert("Please enter your email");
      return;
    }
    try {
      const response = await axios.delete(
        `${baseUrl}${email}`
      );
      if (response.status === 200) {
        alert("Request sent successfully");
      }
    } catch (error) {
      alert("Request failed");
    }
  };

  const onCancel = () => {
    alert("Request cancelled");
  };
  return (
    <div className="delete-profile-modal">
      <form className="delete-profile-content" onSubmit={onDelete}>
        <h2 className="delete-profile-title">
          Request Account Deletion
          <MdCancel className="delete-profile-close" onClick={onCancel} />
        </h2>
        <p className="delete-profile-warning">
          We're sorry to see you go! Please fill out the form below to request
          the deletion of your account. Once you submit the request, you will
          receive an email to confirm the deletion. This process is
          irreversible, and all your data will be permanently removed from our
          system upon confirmation. If you have any concerns or need assistance,
          feel free to contact our support team. Thank you for being a part of
          our community.
        </p>
        <input
          type="text"
          placeholder="Enter user email"
          className="user-detail-input"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <div className="button-group">
          <button className="delete-button" type="submit">
            Request
          </button>
          <button className="cancel-button" onClick={onCancel}>
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default DeleteProfile;
