import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAndSetUserDetails } from '../../store/actions/authActions';
import { fetchStories } from '../../store/actions/storyActions';
import AuthServices from '../../services/auth.services';
import AppHeader from "../app/layout/AppHeader";
import FriendService from '../../services/FriendService';
import "../../assets/css/profile.css";
import PexelDrawer from './PexelDrawer';
import UserService from '../../services/user';
import Story from '../app/story/Story';
import contance from '../../config/contance';
import lockimg from '../../assets/images/lock_11081444.png';
import userChat from '../../assets/images/user_chat.png';
import Posts from '../app/post/post/Posts';
import FollowModal from './FollowModal';
import CreatePosts from '../app/post/createpost/CreatePosts';
import defaultCoverImg from '../../assets/images/default-cover-picture.jpg'

export default function ProfileComponent() {
  const [profileRender, setProfileRender] = useState(null);
  const [showProfilePicture, setShowProfilePicture] = useState(false);
  const [profilePicture, setProfilePicture] = useState(null);
  const [coverPhoto, setCoverPhoto] = useState(null);
  const [showPexelDrawer, setShowPexelDrawer] = useState(false);
  const [posts, setPosts] = useState([]);
  const [userFollow, setUserFollow] = useState({ followers: [], following: [] });
  const [show, setShow] = useState("timeline");
  const [modalContent, setModalContent] = useState({ isOpen: false, type: '', users: [] });
  const [loading, setLoading] = useState(true);

  const userDetails = useSelector(state => state.auth.userDetails?.data);
  const dispatch = useDispatch();

  useEffect(() => {
    const loadUserDetails = async () => {
      setLoading(true);
      await dispatch(fetchAndSetUserDetails(AuthServices.getCurrentUser().id));
      await dispatch(fetchStories());
      await loadSocialConnections();
      setLoading(false);
    };

    loadUserDetails();
  }, [dispatch] );

  const loadSocialConnections = async () => {
    try {
      const postsList = await UserService.getUserPost(userDetails.id);
      setPosts(postsList);
    } catch (error) {
      console.error('Social connections loading failed:', error);
    }
  };

  const getFollowersandFollowings = async () => {
    try {
      const getfollowers = await FriendService.getFollowers(userDetails.id);
      const getfollowings = await FriendService.getFollowings(userDetails.id);
      setUserFollow({
        followers: getfollowers.map(follower => ({
          ...follower,
          isFollower: true,
          isFollowing: getfollowings.some(f => f.id === follower.id)
        })),
        following: getfollowings.map(following => ({
          ...following,
          isFollower: getfollowers.some(f => f.id === following.id),
          isFollowing: true
        }))
      });
    } catch (error) {
      console.log("getFollowersandFollowings Error", error);
    }
  };

  const loadFollowersOrFollowing = async (type) => {
    try {
      const users = type === 'followers'
        ? userFollow.followers
        : userFollow.following;
      setModalContent({
        isOpen: true,
        type: type,
        users: users
      });
    } catch (error) {
      console.error(`Failed to load ${type}:`, error);
    }
  };

  const closeModal = () => {
    setModalContent(prev => ({ ...prev, isOpen: false }));
    getFollowersandFollowings();
  };

  useEffect(() => {
    if (profilePicture) {
      uploadProfilePicture();
    }
  }, [profilePicture]);

  useEffect(() => {
    if (userDetails) {
      getFollowersandFollowings();
      loadSocialConnections();
      setCoverPhoto(userDetails.coverPic)

    }
  }, [userDetails]);

  const handleProfileImage = (event) => {
    const file = event.target.files[0];
    if (!file) {
      console.log("Dosya seçilmedi.");
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setProfileRender(reader.result);
        setShowProfilePicture(true);
      }
    };
    reader.readAsDataURL(file);
    setProfilePicture(file);
  };

  const uploadProfilePicture = async () => {
    if (!profilePicture) {
      console.log("Profil resmi seçilmedi!");
      return;
    }

    const formData = new FormData();
    const userDetails = {
      user: {},
      userDetails: {
        profilePic: ""
      }
    };
    formData.append("data", JSON.stringify(userDetails));
    formData.append("file", profilePicture);
    formData.append("folder", contance.folderNames.PROFILE_PICTURES);

    try {
      const response = await UserService.editProfile(formData);
      console.log('Profil resmi güncellendi:', response);
      dispatch(fetchAndSetUserDetails(AuthServices.getCurrentUser().id));
    } catch (error) {
      console.error('Profil resmi yükleme hatası:', error);
    }
  };

  const handleCoverPhotoChange = (url) => {
    setCoverPhoto(url);
  };


  const isSocialLinkActive = (link, base) => {
    return link && link !== base && link.length > base.length;
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleShow = () => {
    if (show === "timeline") {
      return (
        <div className="container">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="col-lg-10">
                  <div className="row" style={{ marginLeft: "20%" }}>
                    <CreatePosts user={userDetails} />
                    <Posts type='user' user_id={userDetails.id} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (show === "about") {
      return (
        <div className="container">
          <div className="container-fluid">
            <div className="col-lg-12">
              <div className="col-lg-10">
                <div className="row" style={{ marginLeft: "20%" }}>
                  <div>
                    <div className='central-meta newsfeed'>
                      <div style={{ display: 'flex', marginTop: '4px' }}>
                        <span style={{ marginLeft: '12%', fontSize: '20px', marginTop: '5%', marginBottom: '5%' }}>
                          When you share photos and videos, they'll appear in your profile
                        </span>
                      </div>
                      <span style={{ marginLeft: '33%', fontSize: '18px', marginTop: '5%', color: "darkblue" }}>
                        Share your first photo and video
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (show === "friends") {
      return (
        <div className="container">
          <div className="container-fluid">
            <div className="col-lg-12">
              <div className="col-lg-10">
                <div className="row" style={{ marginLeft: "20%" }}>
                  <div>
                    <div className='central-meta newsfeed'>
                      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '4%' }}>
                        <img src={userChat} alt="User chat icon" style={{ width: '150px', height: 'auto', marginBottom: '5%' }} />
                        <span style={{ fontSize: '18px', marginTop: '5%' }}>
                          When people tag you in photos or videos, they will appear here
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div>
      {userDetails && (
        <>
          <AppHeader />
          <div className="row merged">
            <section style={{ marginBottom: '20px' }}>
              <div className="feature-photo">
                <div className="cover-photo pdng1">
                  <hr className="new1" />
                  {console.log("COVER İMG ", coverPhoto)}
                  {coverPhoto ? (
                    <div className="coverimg-container">
                      <img
                        src={coverPhoto}
                        className="coverimg"
                        alt="Cover"
                      />
                      <div
                        className="edit-overlay"
                        onClick={() => setShowPexelDrawer(true)}
                      >
                        +
                      </div>
                    </div>
                  ) : (
                    <div className="coverimg-container">
                      <img
                        src={defaultCoverImg}
                        className="coverimg"
                        alt="Default Cover"
                      />
                      <div
                        className="edit-overlay"
                        onClick={() => setShowPexelDrawer(true)}
                      >
                        +
                      </div>
                    </div>
                  )}
                </div>
                <div className="row pdng1 person-details">
                  <div className="col-lg-1"></div>
                  <div className="col-lg-2 profsts"></div>
                  <div className="col-lg-2 profsts"></div>
                  <div className="col-lg-2">
                    <div style={{ float: 'left', marginTop: '-4%', marginLeft: '300px' }}>
                      <a href="/editprofile" className="button edit-profile-button">
                        Edit Profile
                      </a>
                    </div>
                  </div>
                </div>
                <div className="container pdng1">
                  <div className="row">
                    <div className="col-lg-3">
                      <div className="user-avatar">
                        {showProfilePicture ? (
                          <img id="preview" src={profileRender} />
                        ) : userDetails.profilePic ? (
                          <img
                            style={{ zIndex: 1 }}
                            className="border-gradient1"
                            src={userDetails.profilePic}
                          />
                        ) : (
                          <img
                          style={{ backgroundColor: '#033347', }}                            
                          className="border-gradient1"
                            src="/assets/images/vector-34@2x.png"
                          />
                        )}
                      </div>
                      <form className="edit-photo">
                        <label className="fileContainer">
                          <div className="add-profile mrgnFileCntnrVwProf">+</div>
                          <input
                            id="file-input"
                            type="file"
                            name="profile_image"
                            accept="image/*"
                            onChange={handleProfileImage}
                          />
                        </label>
                      </form>
                    </div>
                    <div className="col-lg-6">
                      <div className="profile-header">
                        <h5>{userDetails.firstName ? `${userDetails.firstName} ${userDetails.lastName}` : ''}</h5>
                        {userDetails.isPrivate ? (
                          <img
                            src={lockimg}
                            alt=""
                            style={{ width: '5%', height: 'auto', marginTop: '20px', opacity: '50%' }}
                          />
                        ) : (
                          <div></div>
                        )}
                      </div>
                      <div className="profile-stats-container">
                        <div className="profile-stats">
                          <div className="profsts">
                            <ul>
                              <li>
                                <span className="center-text-span">{posts.length}</span>
                                <span>Posts</span>
                              </li>
                              <li>
                                <span
                                  className="center-text-span pointer"
                                  onClick={() => loadFollowersOrFollowing('followers')}
                                >
                                  {userFollow.followers.length}
                                </span>
                                <span className="pointer" onClick={() => loadFollowersOrFollowing('followers')}>
                                  Followers
                                </span>
                              </li>
                              <li>
                                <span
                                  className="center-text-span pointer"
                                  onClick={() => loadFollowersOrFollowing('following')}
                                >
                                  {userFollow.following.length}
                                </span>
                                <span className="pointer" onClick={() => loadFollowersOrFollowing('following')}>
                                  Following
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <span className="myCity">
                      {userDetails.country && userDetails.city
                        ? `${userDetails.country}/${userDetails.city}`
                        : userDetails.country || userDetails.city}
                    </span>
                    <span className="about-me">{userDetails.aboutme}</span>
                    <span className="about-me">{userDetails.hobbies}</span>
                  </div>
                  <div className="stories">
                    <Story />
                  </div>
                  <div className="col-lg-1"></div>
                  <div className="col-lg-6">
                    <div className="col-lg-6">
                      <div className="profsts">
                        <div className="profstss">
                          <ul>
                            <li>
                              <span>
                                <i
                                  style={{ cursor: 'pointer' }}
                                  className={`fa-brands fa-facebook-f fa-lg ${isSocialLinkActive(userDetails.facebook, 'https://facebook.com/')
                                    ? 'active-social'
                                    : ''
                                    }`}
                                  onClick={() =>
                                    isSocialLinkActive(userDetails.facebook, 'https://facebook.com/') &&
                                    window.open(userDetails.facebook, '_blank')
                                  }
                                ></i>
                              </span>
                            </li>
                            <li>
                              <span>
                                <i
                                  style={{ cursor: 'pointer' }}
                                  className={`fa-brands fa-instagram fa-lg ${isSocialLinkActive(userDetails.instagram, 'https://instagram.com/')
                                    ? 'active-social'
                                    : ''
                                    }`}
                                  onClick={() =>
                                    isSocialLinkActive(userDetails.instagram, 'https://instagram.com/') &&
                                    window.open(userDetails.instagram, '_blank')
                                  }
                                ></i>
                              </span>
                            </li>
                            <li>
                              <span>
                                <i
                                  style={{ cursor: 'pointer' }}
                                  className={`fa-brands fa-twitter fa-lg ${isSocialLinkActive(userDetails.twitter, 'https://twitter.com/') ? 'active-social' : ''
                                    }`}
                                  onClick={() =>
                                    isSocialLinkActive(userDetails.twitter, 'https://twitter.com/') &&
                                    window.open(userDetails.twitter, '_blank')
                                  }
                                ></i>
                              </span>
                            </li>
                            <li>
                              <span>
                                <i
                                  style={{ cursor: 'pointer' }}
                                  className={`fa-brands fa-linkedin-in fa-lg ${isSocialLinkActive(userDetails.linkedin, 'https://linkedin.com/in/') ? 'active-social' : ''
                                    }`}
                                  onClick={() =>
                                    isSocialLinkActive(userDetails.linkedin, 'https://linkedin.com/in/') &&
                                    window.open(userDetails.linkedin, '_blank')
                                  }
                                ></i>
                              </span>
                            </li>
                            <li>
                              <span>
                                <i
                                  style={{ cursor: 'pointer' }}
                                  className={`fa-brands fa-pinterest fa-lg ${isSocialLinkActive(userDetails.pinterest, 'https://pinterest.com/') ? 'active-social' : ''
                                    }`}
                                  onClick={() =>
                                    isSocialLinkActive(userDetails.pinterest, 'https://pinterest.com/') &&
                                    window.open(userDetails.pinterest, '_blank')
                                  }
                                ></i>
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="timeline-info">
                    <div className="row">
                      <div className="col">
                        <a
                          className={show === 'timeline' ? 'active button' : 'button'}
                          onClick={() => setShow('timeline')}
                        >
                          <i className="las la-rss white-icon"></i>
                        </a>
                      </div>
                      <div className="col brdrmid">
                        <a className={show === 'about' ? 'active button' : 'button'} onClick={() => setShow('about')}>
                          <i className="las la-icons white-icon"></i>
                        </a>
                      </div>
                      <div className="col">
                        <a
                          className={show === 'friends' ? 'active button' : 'button'}
                          onClick={() => setShow('friends')}
                        >
                          <i className="las la-user-friends white-icon"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {handleShow()}
            </section>
          </div>

          {console.log("ASDASDASDASD",showPexelDrawer)}
          <PexelDrawer
            isVisible={showPexelDrawer}
            setIsVisible={setShowPexelDrawer}
            handleEditCover={handleCoverPhotoChange}
            coverLoading={false}
          />
          <FollowModal
            isOpen={modalContent.isOpen}
            title={modalContent.type}
            users={modalContent.users}
            onClose={closeModal}
          />
        </>
      )}
    </div>
  );
}
