import React, { useState, useEffect, useContext } from 'react';
import { Redirect } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import user from '../../services/user';
import AuthService from '../../services/auth.services';
import fileStorage from '../../config/fileStorage';

function FollowingWidgetComponent() {


    const [followers, setFollowers] = useState([]);
	const [searchedFollowers, setSearchedFollowers] = useState([]);

    return (
        followers.length>0?
            <div className="widget sidebar-flwrs">
                <div className="row"><img src="../assets/images/Public-Account-300x300.png" style={{width: '13%'}}/>
                <p className="widget-title" style={{position:'absolute', left: '50px',}}>Who's following</p></div>
                <ul className="followers">
                    {
                        followers.slice(0, 4).map((follower_user) =>
                            <li key={follower_user.id} style={{display:'flex', width:'100%' ,padding:'10px',alignItems:'center'}}>
                                <figure style={{width:'20%'}}>
                                    <img src={ fileStorage.baseUrl+follower_user.profilePicturePath} alt="" />
                                </figure>
                                <div className="" style={{width:'55%',textAlign: 'left' ,paddingLeft:'10px',display:'flex',flexDirection:'column'}}>
                                    <h4>
                                        <a href={`/profile/${follower_user.email}`} title="">{`${follower_user.firstName} ${follower_user.lastName}`}</a>
                                    </h4>
                                    <p style={{fontSize:'12px',paddingTop:'5px'}}>{follower_user.numberOfFollowers} Followers</p>
                                </div>
                                <button title="" className="button" style={{width:'25%',margin:'10px',padding:'0 5px'}}>Unfollow</button>

                            </li>
                        )
                    }
                </ul>
            </div>
            :<></>
    );
}
export default FollowingWidgetComponent;