import React, { useState, useRef } from 'react';
import Layout from './Layout';
import { useNavigate, Link } from 'react-router-dom';
import AuthServices from '../../services/auth.services';
import TermsModal from './TermsModal';
import encryptionService from '../../services/encryptionService';
import logo from '../../assets/images/logo/kare.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default function Register() {
    const navigate = useNavigate();

    // state
    const [registerError, setRegisterError] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [gender, setGender] = useState('');
    const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: ''
    });
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [showTermsModal, setShowTermsModal] = useState(false);

    // ref
    const emailRef = useRef(null);
    const confirmPasswordRef = useRef(null);
    const passwordRef = useRef(null);
    const firstNameRef = useRef(null);
    const lastNameRef = useRef(null);
    const genderRef = useRef(null);

    // Register
    const validateRegister = (event) => {
        event.preventDefault();
        setRegisterError("");
        setErrors({ firstName: '', lastName: '', email: '', password: '', confirmPassword: '' });

        let newErrors = {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            confirmPassword: ''
        };

        if (!firstNameRef.current.value) {
            newErrors.firstName = "First name is required";
        }
        if (!lastNameRef.current.value) {
            newErrors.lastName = "Last name is required";
        }
        if (!emailRef.current.value) {
            newErrors.email = "Email is required";
        } else if (!validateEmail(emailRef.current.value)) {
            newErrors.email = "Email is invalid";
        }
        if (!passwordRef.current.value) {
            newErrors.password = "Password is required";
        } else if (!validatePassword(passwordRef.current.value)) {
            newErrors.password = "Enter a stronger password";
        }
        if (passwordRef.current.value !== confirmPasswordRef.current.value) {
            newErrors.confirmPassword = "Passwords do not match";
        }

        setErrors(newErrors);

        if (Object.values(newErrors).every(err => err === "") && termsAccepted) {
            submitForm();
        } else if (!termsAccepted) {
            setRegisterError("You must accept the terms and conditions");
        }
    };

    const validateEmail = (email) => {
        return /\S+@\S+\.\S+/.test(email);
    };

    const validatePassword = (password) => {
        return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.]{8,}$/.test(password);
    };

    const submitForm = async () => {
        let user = { firstName, lastName, email, password, confirmPassword, gender };
        await AuthServices.createUser(user).then(async => {
            navigate('/email-verification', { state: { email: user.email } });
        }).catch(error => {
            setRegisterError("User Already Registered");
        });
    };

    const handleTermsChange = () => {
        if (termsAccepted) {
            setTermsAccepted(false);
        } else {
            setShowTermsModal(true);
        }
    };

    return (
        <Layout>
            <div className="row justify-content-center">


                <div className="card col-lg-4 reg justify-content-center align-items-center">
                    <div className="logo justify-content-center" style={{ width: 'auto', margin: '1rem 0' }}>
                        <a href="/"><img src={logo} alt="" width='50px' height='50px' /></a>
                    </div>
                    <h2 className="card-title text-center" style={{ fonstSize: '30px', fontWeight:'bold' }}>Register new Account</h2>
                    <form onSubmit={validateRegister} style={{ color: 'white', padding: '1rem 0' }}>
                        <h2 className="text-center successfull-msg" style={{ color: 'red' }}>{registerError}</h2>
                        <div className='row'>
                            <div className="col-md-6 py-3 pl-1 form-icon txt_field">
                                <input
                                    ref={firstNameRef}
                                    type="text" id="validationCustom01" placeholder='Enter first name'
                                    className={`form-control m-0 border-radius`}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    required />
                                {errors.firstName && <small>{errors.firstName}<i className="fas fa-exclamation-circle input-error-icon"></i></small>}
                            </div>
                            <div className="col-md-6 py-3 pl-1 form-icon">
                                <input
                                    ref={lastNameRef}
                                    type="text" placeholder='Enter last name'
                                    className={`form-control m-0 border-radius`}
                                    onChange={(e) => setLastName(e.target.value)}
                                    required />
                                {errors.lastName && <small>{errors.lastName}<i className="fas fa-exclamation-circle input-error-icon"></i></small>}
                            </div>
                            <div className="col-md-6 py-3 pl-1 form-icon">
                                <select
                                    ref={genderRef}
                                    className={`form-control m-0 border-radius`}
                                    onChange={(e) => setGender(e.target.value)}
                                    required>
                                    <option value="" selected>Select Gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                </select>
                                {errors.gender && <small>{errors.gender}<i className="fas fa-exclamation-circle input-error-icon"></i></small>}
                            </div>
                            <div className="col-md-6 py-3 pl-1 form-icon">
                                <input
                                    ref={emailRef}
                                    type="text" placeholder='Enter email'
                                    className={`form-control m-0 border-radius`}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                {errors.email && <small>{errors.email}<i className="fas fa-exclamation-circle input-error-icon"></i></small>}
                            </div>
                            <div className="col-md-6 py-3 pl-1 form-icon">
                                <input
                                    ref={passwordRef}
                                    type="password" id='password'
                                    placeholder='Enter password'
                                    className={`form-control m-0 border-radius`}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                {errors.password && <small>{errors.password}<i className="fas fa-exclamation-circle input-error-icon"></i></small>}
                            </div>
                            <div className="col-md-6 py-3 pl-1 form-icon">
                                <input
                                    type="password"
                                    placeholder='Confirm password' id='confirm_password'
                                    className={`form-control m-0 border-radius`}
                                    ref={confirmPasswordRef}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                                {errors.confirmPassword && <small>{errors.confirmPassword}<i className="fas fa-exclamation-circle input-error-icon"></i></small>}
                            </div>
                        </div>
                        <div className="checkbox">
                            <label>
                                <input
                                    type="checkbox"
                                    checked={termsAccepted}
                                    onChange={handleTermsChange}
                                />
                                <i className="check-box" />
                                Accept Terms &amp; Conditions ?
                            </label>
                        </div>
                        <div className="checkbox">
                            <label>
                                <input type="checkbox" defaultChecked="checked" />
                                <i className="check-box" />I am 18 years old or above
                            </label>
                        </div>
                        <Link to="/login" className="already-have">Already have an account?</Link>
                        <div className="submit-btns">
                            <button className="mtr-btn signup"  style={{ backgroundColor: '#033347'}} type='submit'>
                                <span>Share In</span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            {showTermsModal && (
                <TermsModal
                    onClose={() => setShowTermsModal(false)}
                    onAccept={() => {
                        setTermsAccepted(true);
                        setShowTermsModal(false);
                    }}
                />
            )}
        </Layout>
    );
}
