import MessageServices from '../../services/MessageServices';
import FriendService from '../../services/FriendService';
import socket from '../../services/socket';
import store from '../store';
import authServices from '../../services/auth.services';
import {encryptionService as encryptMessage,decryptionService as decryptMessage} from '../../services/descryptionService';
import { v4 as uuidv4 } from 'uuid';

export const FETCH_CONVERSATIONS = 'FETCH_CONVERSATIONS';
export const FETCH_MESSAGES = 'FETCH_MESSAGES';
export const START_NEW_CONVERSATION = 'START_NEW_CONVERSATION';
export const FETCH_FOLLOWERS = 'FETCH_FOLLOWERS';
export const NEW_MESSAGE_RECEIVED = 'NEW_MESSAGE_RECEIVED';

export const fetchConversations = () => async (dispatch) => {
    try {
        const response = await MessageServices.getConversations();
        dispatch({ type: FETCH_CONVERSATIONS, payload: response });
    } catch (error) {
        console.error('Error fetching conversations:', error);
    }
};

export const fetchMessages = (secretKey, conversationId, offset = 0, limit = 10) => async (dispatch) => {
    try {
        if (isNaN(offset) || isNaN(limit)) {
            throw new Error("Offset and limit must be valid numbers");
        }
        const response = await MessageServices.getMessages(conversationId, offset, limit);
        const decryptedMessages = await Promise.all(response.map(async message => {
            if (message.type === 'text' && message.content && message.authTag) {
                return {
                    ...message,
                    content: await decryptMessage(secretKey, message.content, message.authTag ),
                };
            }

            console.log('mesajlar : ', message)
            return message;
        }));
        dispatch({ type: FETCH_MESSAGES, payload: decryptedMessages });
    } catch (error) {
        console.error('Error fetching messages:', error);
    }
};

export const startNewConversation = (user) => (dispatch, getState) => {
    const state = getState();
    const currentUser = authServices.getCurrentUser();
    const existingConversation = state.chat.conversations.find(
        (conversation) => 
            (conversation.user1 === currentUser.id && conversation.user2 === user.id) ||
            (conversation.user1 === user.id && conversation.user2 === currentUser.id)
    );

    if (existingConversation) {
        dispatch({ type: START_NEW_CONVERSATION, payload: existingConversation });
    } else {
        const newConversation = {
            id: Date.now(),
            user1: currentUser.id, 
            user2: user.id, 
            username: `${user.firstName} ${user.lastName}`,
            messages: [] 
        };
        dispatch({ type: START_NEW_CONVERSATION, payload: newConversation });
    }
};

export const fetchFollowers = (userId) => async (dispatch) => {
    try {
        const response = await FriendService.getFollowings(userId);
        dispatch({ type: FETCH_FOLLOWERS, payload: response });
    } catch (error) {
        console.error('Error fetching followers:', error);
    }
};

export const sendMessage = (secretKey, message, conversationId, senderId, receiverId, files = []) => async (dispatch) => {
    try {
        const { content } = encryptMessage(message, secretKey);
        const data = {
            id: uuidv4(),
            content: content,
            conversationId,
            sender: senderId,
            receiver: receiverId,
        };

        const result = await MessageServices.sendMessage(senderId, receiverId, files, data);

        dispatch({ type: NEW_MESSAGE_RECEIVED, payload: result });
        
        socket.emit('send_message', result);
    } catch (error) {
        console.error('Error sending message:', error);
    }
};

socket.on('new_message', (data) => {
    const secretKey = null; // Secret key'i uygun şekilde alın
    data.message = decryptMessage({ content: data.message.content, authTag: data.message.authTag }, secretKey);
    store.dispatch({ type: NEW_MESSAGE_RECEIVED, payload: data });
});
