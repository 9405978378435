import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Modal, Button, Input, List, Spin, Image } from 'antd';
import _debounce from 'lodash.debounce';
import { useTranslation } from 'react-i18next';
import fetchPhotos from '../../services/PexelService';
import UserService from '../../services/user';
import axios from 'axios';

const PexelDrawer = ({ isVisible, setIsVisible, handleEditCover, coverLoading }) => {
    const { t } = useTranslation();
    const [search, setSearch] = useState('');
    const [photos, setPhotos] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const cancelToken = useRef(null);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [selectedPhoto, setSelectedPhoto] = useState(null);

    const fetchAndSetPhotos = useCallback(
        _debounce(async () => {
            if (!hasMore || loading) return;

            if (cancelToken.current) {
                cancelToken.current.cancel('Operation canceled due to new request.');
            }

            const source = axios.CancelToken.source();
            cancelToken.current = source;

            if (page === 1) {
                setPhotos([]);
            }

            setLoading(true);

            try {
                const newPhotos = await fetchPhotos(search, page, source);
                setPhotos(prevPhotos => [...prevPhotos, ...newPhotos]);
                setPage(prevPage => prevPage + 1);
                setHasMore(newPhotos.length > 0);
            } catch (error) {
                console.error('Error fetching photos:', error.message);
            } finally {
                setLoading(false);
            }
        }, 500),
        [search, page, hasMore, loading]
    );

    useEffect(() => {
        if (isVisible) {
            setPage(1);
            fetchAndSetPhotos();
        }
    }, [search, isVisible]);

    const handleEndReached = () => {
        fetchAndSetPhotos();
    };

    const reset = () => {
        setSearch('');
        setPhotos([]);
        setPage(1);
    };

    useEffect(() => {
        if (!isVisible) {
            reset();
        }
    }, [isVisible]);

    const handlePreview = (photo) => {
        setSelectedPhoto(photo);
        setPreviewVisible(true);
    };

    const handleSelectPhoto = async () => {
        if (selectedPhoto && !coverLoading) {
            await UserService.uploadCoverPicture(selectedPhoto.src.landscape);
            handleEditCover(selectedPhoto.src.landscape);
            setPreviewVisible(false);
            setIsVisible(false);
        }
        else if(!selectedPhoto && !coverLoading){
            await UserService.uploadCoverPicture(null);
            handleEditCover(null);
            setPreviewVisible(false);
            setIsVisible(false);
        }
    };

    return (
        <>
            <Modal
                visible={isVisible}
                onCancel={() => setIsVisible(false)}
                footer={null}
                title={t('Select a cover photo')}
                width={850}
            >
                <Input
                    placeholder={t('SEARCH')}
                    onChange={e => {
                        setSearch(e.target.value);
                        setPage(1);
                    }}
                    style={{ marginBottom: 20 }}
                    enterButton={null}
                />
                <Button
                    onClick={() => handleSelectPhoto()}
                    style={{ marginBottom: 20 }}
                    disabled={coverLoading}
                >
                    {t('Remove Cover Image')}
                </Button>
                <div className="photo-list-container" style={{ maxHeight: "500px", overflowY: "auto" }}>
                    <List
                        grid={{ gutter: 16, column: 3 }}
                        dataSource={photos}
                        renderItem={item => (
                            <List.Item>
                                <Image
                                    src={item.src.landscape}
                                    alt="cover"
                                    style={{ cursor: 'pointer' }}
                                    preview={false}
                                    onClick={() => handlePreview(item)}
                                />
                            </List.Item>
                        )}
                        loading={loading}
                        loadMore={
                            !loading && hasMore && (
                                <div style={{ textAlign: 'center', marginTop: 20 }}>
                                    <Button onClick={handleEndReached}>{t('Load more')}</Button>
                                </div>
                            )
                        }
                    />
                </div>
            </Modal>

            <Modal
                visible={previewVisible}
                onCancel={() => setPreviewVisible(false)}
                footer={null}
                title={t('Preview Photo')}
                width={800}
            >
                {selectedPhoto && (
                    <div style={{ textAlign: 'center' }}>
                        <Image src={selectedPhoto.src.landscape} alt="cover preview" />
                        <Button
                            type="primary"
                            onClick={handleSelectPhoto}
                            style={{ marginTop: 20 }}
                            disabled={coverLoading}
                        >
                            {t('Select Photo')}
                        </Button>
                    </div>
                )}
            </Modal>
        </>
    );
};

export default PexelDrawer;
