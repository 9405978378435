
export async function encryptionService(conversationSecretKey, textMessage) {
  try {
    return {
      textMessage,
        };
  } catch (e) {
    return null;
  }
}
export async function decryptionService( encryptedMessage) {
  try {

    return encryptedMessage
  } catch (e) {
    console.error('Decryption failed:', e);
    return null;
  }
}

