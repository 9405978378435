import React, { useState } from 'react'
import AppHeader from './AppHeader'
import img1 from '../../../assets/images/news1.jpg'
import fileStorage from '../../../config/fileStorage';


export default function AppLayout({ user, children }) {
    const [reelPreviewPath, setReelPreviewPath] = useState([]);
    return (
        <>
            <AppHeader user={user} />
            {/* topbar */}
            <div className="container">
                <section>
                    <div className="gap gray-bg">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="row" id="page-contents">
                                        <div className="col-lg-3" style={{ maxWidth: '21%' }}>
                                            <aside className="sidebar static" style={{ width: '91%', marginRight: '0px', paddingLeft: '3px' }}>
                                                <div className="widget" style={{ borderBottom: '1px solid #75757530' }}>
                                                    {/* <div className="row"><img src="../assets/images/unnamed.png"/><p className="widget-title">User</p></div>   */}
                                                    <div className="user" >
                                                        <img src={user.profilePic} />
                                                        <a href="/profile" ><p style={{ fontWeight: "bold" }}>{`${user.firstName} ${user.lastName}`}</p></a>
                                                    </div>
                                                </div>



                                                <div className="widget navmenu">
                                                    {/* <div className="row"><img src="../assets/images/menu-1899421-1606840.png"/><p className="widget-title">Menu</p></div>  */}
                                                    <div><ul className="naves">
                                                        <li>
                                                            <div style={{ marginRight: "12px", display: "inline" }}><i className="ti-clipboard" /></div>
                                                            <a href="/newsfeed" title="#">ShareFeed</a>
                                                        </li>
                                                        <li>
                                                            <div style={{ marginRight: "12px", display: "inline" }}><i className="ti-write" /></div>
                                                            <a href="/savedShares" title="#">SavedShares</a>
                                                        </li>
                                                        <li>
                                                            <div style={{ marginRight: "12px", display: "inline" }}><i className="ti-comments" /></div>
                                                            <a href="/messages" title="#">Messages</a>
                                                        </li>
                                                        <li>
                                                            <div style={{ marginRight: "12px", display: "inline" }}><i className="ti-user" /></div>
                                                            <a href="/friends" title="#">ShareFriends</a>
                                                        </li>
                                                        <li>
                                                            <div style={{ marginRight: "12px", display: "inline" }}>
                                                                <i className="ti-user" />
                                                                <p style={{ fontSize: "18px", color: "blue", marginLeft: "-8px", display: "inline" }}>+
                                                                </p>
                                                            </div>

                                                            <a href="/Addfriends" title="#">Add Friends</a>
                                                        </li>

                                                        <li>
                                                            <div style={{ marginRight: "5px", display: "inline" }}><i className="ti-user" /><i className="ti-user" style={{ marginLeft: "-19px" }} /></div>
                                                            <a href="/groups" title="#">ShareGroups</a>
                                                        </li>
                                                        <li>
                                                            <div style={{ marginRight: "12px", display: "inline" }}><i className="ti-link" /></div>

                                                            <a href="/shareFeed" title="#">SharePoint</a>
                                                        </li>
                                                        <li>
                                                            <div style={{ marginRight: "12px", display: "inline" }}><i className="ti-control-shuffle" /></div>

                                                            <a href="/swapFeed" title="#">SwapPoint</a>
                                                        </li>
                                                    </ul></div>
                                                </div>{/* Shortcuts */}

                                            </aside>
                                        </div>{/* sidebar */}
                                        {/* ------------------------------------------------------------------------- */}
                                        <div className="col-lg-6">
                                            {children}
                                        </div>

                                        {/* --------------------------------------------------------------------------------- */}
                                        {/* centerl meta */}
                                        <div className="col-lg-3">
                                            <aside className="sidebar static " style={{ paddingTop: '10px' }}>
                                                {/* <div className="widget friend-list stick-widget">
                        <div className="row"><img src="../assets/images/1865023.png"/><p className="widget-title">Ads</p></div>
                          <div className="ads"><a href="https://technology-signals.com/wp-content/uploads/2019/05/images.martechadvisor.comvoice_technology_5cecf0b8-3f280e5abac0da913f8aa0868cf970c6a429a128.jpg" data-lightbox="image-1" data-title="My caption"><img src="https://technology-signals.com/wp-content/uploads/2019/05/images.martechadvisor.comvoice_technology_5cecf0b8-3f280e5abac0da913f8aa0868cf970c6a429a128.jpg"></img></a>
                        </div>
                      </div> */}

                                                {/* <div className="widget friend-list stick-widget">
                        <div className="row" ><img src="../assets/images/Trends1.jpg"/><p className="widget-title">News</p></div>
                          <div className="news">
                            <a href="#" data-lightbox="image-1" data-title="My caption">
                              <img src="../assets/images/Trends1.jpg"></img>
                            </a>
                          </div>
                        </div> */}

                                                <div style={{
                                                    paddingBottom: '20px',
                                                    // borderBottom: '1px solid #75757530'
                                                }} >
                                                    <div className="sidebar-news">
                                                        <div class="media-date">What's trending</div>
                                                        <div style={{}}>
                                                            <ul>
                                                                <li>
                                                                    <div className="headline-cont">
                                                                        <p className="headline">
                                                                            Omicron variant of COVID-19: New travel guidelines to come into force from December 1
                                                                        </p>
                                                                        <img src={img1} />
                                                                    </div>
                                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                        <a href=" https://www.aljazeera.com/where/qatar/" target="_blank" className="source">Aljazeera Qatar News</a>
                                                                        <p className="date">12/1/2021</p>
                                                                    </div>

                                                                </li>

                                                                <li>
                                                                    <div className="headline-cont">
                                                                        <p className="headline" >Prime Minister Scott Morrison says big tech firms have responsibility to ensure their platforms are safe.</p>
                                                                    </div>
                                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                        <a href="https://www.theverge.com/tech" target="_blank" className="source">Technology</a>
                                                                        <p className="date">12/1/2021</p>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="headline-cont">
                                                                        <p className="headline">Comprehensive Guide to Qatar Business ....</p>
                                                                    </div>
                                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                        <a href="https://thepeninsulaqatar.com/category/Qatar-Business" target="_blank" className="source">Business</a>
                                                                        <p className="date">12/1/2021</p>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="headline-cont">
                                                                        <p className="headline">The #FIFArabCup Qatar 2021 kicks off today, coinciding with the inauguration of Al Bayt Stadium and Stadium 974, the latest stadiums to be ready for the FIFA World Cup 2022</p>
                                                                    </div>

                                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                        <a href="https://www.dohanews.co/category/sports/" target="_blank" className="source">Sports</a>
                                                                        <p className="date">12/1/2021</p>
                                                                    </div>
                                                                </li>
                                                                <li style={{ textAlign: "center", paddingTop: '10px' }}><a href="https://www.aljazeera.com/" style={{ fontSize: '12px', color: "#258eae" }} target="_blank">Show More</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>





                                                <div className="sidebar-news" style={{}}>
                                                    <div class="media-date">REELS</div>
                                                    <div style={{}}>
                                                        <ul>
                                                            <li>
                                                                <div className="headline-cont">


                                                                    {reelPreviewPath
                                                                        ?

                                                                        <React.Fragment>
                                                                            <video
                                                                                loop
                                                                                controls
                                                                                // autoPlay
                                                                                muted
                                                                                style={{ width: '100%', height: '195px', objectFit: 'fill' }}
                                                                                src={`${fileStorage.baseUrl}${reelPreviewPath.mediaPath}`}
                                                                                type="video/mp4"
                                                                                alt={`${fileStorage.baseUrl}${reelPreviewPath.mediaPath}`}
                                                                            // onClick={() => setIsopen()}



                                                                            />

                                                                        </React.Fragment>

                                                                        : <div>No Reels to show</div>
                                                                    }



                                                                </div>


                                                            </li>




                                                            <div className='add-reel'>
                                                                <a href="/reelFeed" title="#" style={{ color: 'white' }}> Explore Reels </a>

                                                            </div>

                                                        </ul>
                                                    </div>
                                                </div>



                                            </aside>
                                        </div>
                                        {/* sidebar */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
