import React, { useState, useEffect, useContext } from 'react';
import AuthService from '../../services/auth.services';
import GroupService from '../../services/GroupService';
import Grpicon from '../../assets/images/grpicon.png'
import fileStorage from '../../config/fileStorage';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAndSetUserDetails } from '../../store/actions/authActions';


function GroupsWidgetComponent() {

    const [loading, setLoading] = useState(true);


    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.userDetails?.data);

    useEffect(() => {
        const fetchDetails = async () => {
            try {
                const userId = AuthService.getCurrentUser().id;
                if (userId) {
                    await dispatch(fetchAndSetUserDetails(userId));
                }
            } catch (error) {
                console.error("Error fetching user details:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchDetails();
    }, [dispatch]);

    const [allGroups, setAllGroups] = useState([]);

    const getMyGroups = async () => {
        try {

            const [memberGroupsResponse, ownerGroupsResponse] = await Promise.all([
                GroupService.getMemberGroups(),
                GroupService.getGroupsOfOwner()
            ]);
            setAllGroups([...ownerGroupsResponse.data, ...memberGroupsResponse.data]);

        } catch (error) {
            console.error("Error fetching groups:", error);
        }
    }

    const handleSearchGroup = () => {
        console.log("Waaa")
    }


    useEffect(() => {
        const abortCtrl = new AbortController();
        const opts = { signal: abortCtrl.signal };
        getMyGroups()
        return () => abortCtrl.abort()
    }, [])


    return (

        <div className="widget friend-list stick-widget" >
            <div className="row">
                <p className="widget-title">Groups</p></div>
            {/* <div id="searchDir" /> */}
            <ul className="nearby-contct sidebar-grp">
                {allGroups && allGroups.length === 0 ? (
                    <div className="nearly-pepls" style={{ display: 'flex', background: 'white', padding: '10px', alignItems: 'center', marginTop: '7px' }}>

                        <div className="pepl-info" style={{ flexGrow: 1, marginTop: '4%', marginLeft: '10px', marginRight: '10px' }}>
                            <h4><a style={{ marginLeft: '4%'}}>You are not connected to any groups.</a></h4>
                            <a href="/groups" title="#" style={{ display: 'inline-block', marginTop: '10px', padding: '5px 10px', background: 'rgb(4, 79, 102)', color: 'white', border: 'none', borderRadius: '5px', textAlign: 'center', textDecoration: 'none' }}>
                                Join or create a group
                            </a>
                        </div>
                    </div>
                ) : (
                    allGroups.slice(0, 4).map(group => (
                        <li key={group.id} style={{ height: '90px' }}>
                            <div className="nearly-pepls" style={{ display: 'flex', background: 'white', padding: '10px', alignItems: 'center' }}>
                                <figure>
                                    <a href={`/groups/${group.id}`} title="#"> <img src={group.imageUrl ? fileStorage.baseUrl + group.imageUrl : Grpicon} alt="" style={{ height: '60px', width: '60px', marginTop: '20%', objectFit: 'cover' }} /></a>
                                </figure>
                                <div className="pepl-info" style={{ flexGrow: 1, marginTop: '4%', marginLeft: '10px' }}>
                                    <h4><a href={`/groups/${group.id}`} title="#">{`${group.name}`}</a></h4>
                                </div>
                            </div>
                        </li>
                    ))
                )}
            </ul>

        </div>
    );
}
export default GroupsWidgetComponent;
