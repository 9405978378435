import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { fetchAndSetUserDetails } from '../../store/actions/authActions';
import GroupService from '../../services/GroupService';
import AppHeader from "../app/layout/AppHeader";
import fileStorage from '../../config/fileStorage';
import AuthService from '../../services/auth.services';
import LeftBar from '../app/layout/LeftBar';
import '../../assets/css/groupComponents.css';
import UserService from '../../services/user';
import InviteFriendsModal from './InviteFriendsModal';
import CreatePosts from '../app/post/createpost/CreatePosts';
import Posts from '../app/post/post/Posts';

function ViewGroupComponent() {
	const { gid } = useParams();
	const groupId = gid;
	const navigate = useNavigate();

	const [group, setGroup] = useState([]);
	const [members, setMembers] = useState([]);
	const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
	const [groupChanged, setGroupChanged] = useState(false);
	const [groupAdmin, setGroupAdmin] = useState("");
	const [loading, setLoading] = useState(true);

	const dispatch = useDispatch();
	const userDetails = useSelector(state => state.auth.userDetails?.data);

	useEffect(() => {
		const fetchDetails = async () => {
			try {
				const userId = AuthService.getCurrentUser().id;
				if (userId) {
					await dispatch(fetchAndSetUserDetails(userId));
				}
			} catch (error) {
				console.error("Error fetching user details:", error);
			} finally {
				setLoading(false);
			}
		};
		fetchDetails();
	}, [dispatch]);

	useEffect(() => {
		if (userDetails && userDetails.id) {
			getGroupUsers();
		}
	}, [groupId, groupChanged, userDetails]);

	useEffect(() => {
		const getGroupDetails = async () => {
			try {
				const response = await GroupService.getGroupById(groupId);
				setGroup(response.data[0]);

				if (response.data[0].owner) {
					const adminResponse = await UserService.getUserById(response.data[0].owner);
					setGroupAdmin(adminResponse.data);
				}
			} catch (error) {
				console.error("Error fetching group details:", error);
			}
		};

		if (userDetails && userDetails.id) {
			getGroupDetails();
		}
	}, [groupId, groupChanged, userDetails]);

	const handleAdminClick = (event, adminId) => {
		event.preventDefault();
		const userId = userDetails.id;

		if (userId === adminId) {
			navigate('/profile');
		} else {
			navigate(`/profile/${adminId}`);
		}
	};

	const handleMemberClick = (event, memberId) => {
		event.preventDefault();
		const userId = userDetails.id;

		if (userId === memberId) {
			navigate('/profile');
		} else {
			navigate(`/profile/${memberId}`);
		}
	};

	const getGroupUsers = async () => {
		await GroupService.getGroupUsers(groupId).then(res => {
			setMembers(res);
		});
	};

	const handleLeaveGroup = async () => {
		try {
			await GroupService.leaveGroup(group.id);
			setGroupChanged(!groupChanged); 
		} catch (error) {
			console.error("Leave group failed:", error);
		}
	};



	const handleJoinGroup = async () => {
		try {
			
			await GroupService.joinGroup(group.id);
			
			setGroupChanged(!groupChanged); 
		} catch (error) {
			console.error("Join group failed:", error);
		}
	};

	if (loading) {
		return <div>Loading...</div>;
	}

	const handleEditGroup = () => {
		navigate('/editgroup', { state: { groupInfo: group } });
	};

	const checkIfInGroup = (members) => {
		if (members) {
			const found = members.some(el => el.id === userDetails.id);
			return found;
		}
		return false;
	};

	const isUserAdmin = userDetails.id === group.owner;

	return (
		<div>
			<AppHeader user={userDetails} />
			{/* topbar */}
			<div className="container">
				<div style={{ marginLeft: '10px', marginRight: '10px' }}>
					
					<section>
						<div className="gap gray-bg">
							<div className="container-fluid">
							
								<div className="row">
									<div className='col-lg-3'>
										<LeftBar user={userDetails} />
									</div>

									<div className="col-lg-6">
									<CreatePosts user={userDetails} groupId={gid}/>
									<Posts user_id={userDetails.id} group_id={gid} type='group'/>
									</div>
									<div className="col-lg-3">
										<aside className="sidebar static">
											<div className="widget friend-list stick-widget">
												<div className="row"><p className="widget-title">{group.name}</p></div>
												<img src={group.imageUrl} alt="Group Image" class="group-image" />
												<p>{group.description}</p>
											</div>
											<div className="widget">
												<div className="row">
													<p className="widget-title">Group Actions</p>
												</div>
												<div className="button-container">
													{isUserAdmin ? (
														<>
															<button className="action-button invite" onClick={() => setIsInviteModalOpen(true)}>Invite Friends</button>
															<button className="action-button delete" onClick={handleEditGroup}>Edit Group</button>
														</>
													) : (
														<>
															{checkIfInGroup(members) ? (
																<>
																	<button className="action-button leave" onClick={handleLeaveGroup}>Leave Group</button>
																	<button className="action-button invite" onClick={handleJoinGroup}>Invite Friends</button>
																</>
															) : (
																<button className="action-button join" onClick={handleJoinGroup}>Join Group</button>
															)}
														</>
													)}
												</div>
											</div>

											<div className="widget">
												<div className="row">
													<img src="../assets/images/b76706b9814f347e841ff15b89a5d170-instagram-discover-people-icon-by-vexels.png" alt="" />
													<p className="widget-title">Group Members</p>
												</div>

												{groupAdmin && (
													<ul className="followers admin-card">
														<li key={groupAdmin.id}>
															<div className="admin-label">Admin</div>
															<figure>
																<img src={fileStorage.baseUrl + groupAdmin.profilePic} alt="" />
															</figure>
															<div className="friend-meta">
																<h4>
																	<a href="#" onClick={(event) => handleAdminClick(event, groupAdmin.id)} title="">{`${groupAdmin.firstName} ${groupAdmin.lastName}`}</a>
																</h4>
															</div>
														</li>
													</ul>
												)}

												<ul className="followers">
													{members && members.length > 0 && members
														.filter(member => member.role !== "owner")
														.map((member) => (
															<li key={member.id}>
																<figure>
																	<img src={member.profilePic} alt="" />
																</figure>
																<div className="friend-meta">
																	<h4>
																		<a href="#" onClick={(event) => handleMemberClick(event, member.id)} title="">{`${member.firstName} ${member.lastName}`}</a>
																	</h4>
																</div>
															</li>
														))}
												</ul>
											</div>
										</aside>
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
			</div>
			<InviteFriendsModal 
				isOpen={isInviteModalOpen} 
				groupId={groupId} 
				onClose={() => setIsInviteModalOpen(false)}
				userId={userDetails.id} 
				groupMembers={members}
			/>
		</div>
	);
}

export default ViewGroupComponent;

