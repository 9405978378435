import StoriesService from '../../services/stories';

export const SET_STORIES = 'SET_USER';

export const fetchStories = () => async (dispatch) => {
  try {
    const stories = await StoriesService.getStories();
    console.log(stories)
    dispatch(setStories(stories));
  } catch (error) {
    console.error("fetchStories ERROR : ", error);
  }
};

export const setStories = (stories) => ({
  type: SET_STORIES,
  payload: stories,
});