import React, { useEffect, useState } from 'react'
import { formatDistanceToNow } from 'date-fns';
import LikeIcon from '../../../elements/LikeIcon';
import { FaEllipsisH, FaTrashAlt, FaFlag } from "react-icons/fa";
import CommentService from '../../../../services/comments';
import { useSelector, useDispatch } from 'react-redux';
import { fetchGetComments } from '../../../../store/actions/commentActions';



export default function CommentBox({ comment, postId }) {

    const dispatch = useDispatch()
    const [liked, setliked] = useState(comment.isUserLiked)
    const [numberOfReactions, setnumberOfReactions] = useState(comment.numberOfReactions)
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        return () => {
            dispatch(fetchGetComments(postId));
        }
    }, []);

    const toggleDropdown = () => setIsOpen(!isOpen);
    const handleLikeClick = async () => {
        setliked(!liked)
        setnumberOfReactions(numberOfReactions + (liked ? -1 : 1));
        await CommentService.likeUnlikeComment(comment.id, comment.userId)
    }

    return (
        <div className="comment-box">
            
            <div className="comment-header">
                <img src={comment.user.profilePic} alt="User" className="user-image" />
                <strong className='user-info2' >{comment.user.firstName} {comment.user.lastName}</strong>
                <LikeIcon className='like-icon2' liked={liked} onClick={handleLikeClick} />
            </div>

            <div className="comment-text">
                <p>{comment.content}</p>
            </div>

            <div className="comment-footer">
                <span>{formatDistanceToNow(new Date(comment.createdAt), { addSuffix: true })}</span>
                <span>{numberOfReactions} Likes</span>
                <span>{comment.numberOfReplies} Reply</span>
                <div className="dropdownCommentDetail">
                    <span style={{ cursor: 'pointer' }} onClick={toggleDropdown}>
                        <FaEllipsisH />
                    </span>
                    {isOpen && (
                        <div className="dropdown-container">
                            <div className="dropdown-item" onClick={() => console.log('Delete clicked')}>
                                <FaTrashAlt />
                                <span>Delete</span>
                            </div>
                            <div className="divider"></div>
                            <div className="dropdown-item" onClick={() => console.log('Report clicked')}>
                                <FaFlag />
                                <span  >Report</span>
                            </div>
                        </div>
                    )}
                </div>

            </div>

        </div>
    )
}
