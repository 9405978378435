import React from 'react';

const FixedSizeMedia = ({ src, isVideo }) => {
  const mediaStyle = {
    width: '360px',
    height: '640px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'black',
    borderRadius: '8px',
    overflow: 'hidden'
  };

  const imageStyle = {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain'
  };

  const videoStyle = {
    ...imageStyle,
    objectFit: 'cover'
  };

  const handleVideoClick = (event) => {
    if (event.target.paused) {
      event.target.play();
    } else {
      event.target.pause();
    }
  };

  return (
    <div style={mediaStyle}>

      

      {isVideo ? (
        <video
          src={src}
          style={videoStyle}
          loop
          autoPlay
          muted
          onClick={handleVideoClick}
        ></video>
      ) : (
        <img src={src} style={imageStyle} alt="Uploaded media" />
      )}
    </div>
  );
};

export default FixedSizeMedia;
