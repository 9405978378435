import React, { useState, useEffect, useRef } from 'react'
import constance from '../../../config/contance'
import { Form } from 'react-bootstrap';
import Popup from 'reactjs-popup';
import StoriesService from '../../../services/stories';
import { useSelector, useDispatch } from 'react-redux';
import { fetchStories } from '../../../store/actions/storyActions';
import StoriesComponent from './StoriesComponent';
import Modal from 'react-modal';
import '../../../assets/css/StoryComponent.css'
import FixedSizeMedia from './ResizableAndDraggable';
import CustomStoryTextInput from '../../elements/CustomStoryTextInput';
import { useNavigate } from 'react-router-dom';
import Loading from '../../elements/Loading';



Modal.setAppElement('#root');




export default function Story({type}) {

  const navigate = useNavigate()


  //redux
  const storiesGet = useSelector(state => state.story.stories)
  const dispatch = useDispatch()

  //state
  const [uploadErrorStory, setUploadErrorStory] = useState('');
  const [showstoriesImage, setShowstoriesImage] = useState(false);
  const [storiesFriends, setStoriesFriends] = useState([]);
  const [myStories, setMyStories] = useState({})
  const [storiesImage, setStoriesImage] = useState([]);
  const [filesStry, setFilesStry] = useState({});
  const [isVideo, setIsVideo] = useState()
  const [file, setFile] = useState()
  const [caption, setCaption] = useState('')
  const [loading, setLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false);

  const listRef = useRef(null);

  const scrollLeft = () => {
    if (listRef.current) {
      listRef.current.scrollBy({ left: -100, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (listRef.current) {
      listRef.current.scrollBy({ left: 100, behavior: 'smooth' });
    }
  };


  // combine stories
  useEffect(() => {
    setLoading(true)
    if (storiesGet && storiesGet.data) {
      let mystory = [];
      const { followingsStories, myStories } = storiesGet.data;
      if (myStories) {
        mystory = [myStories]
      }
      const FriendsStories = [...followingsStories];
      setMyStories(mystory)
      setStoriesFriends(FriendsStories)
      setLoading(false)
    } else {
      console.log('Stories not defined');
    }
  }, [storiesGet]);


  const handleClose = () => {
    setIsOpen(false);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };



  const handleRemoveImageStry = () => {
    setFilesStry({});
    setShowstoriesImage(false);
  };



  const handleFileStry = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file)
      setFilesStry(URL.createObjectURL(file));
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setStoriesImage(reader.result);
        }
      };
      reader.readAsDataURL(file);
      setShowstoriesImage(true);
      setIsVideo(file.type.startsWith('video'));
    }
  };
  function createThumbnail(videoUrl, timeStamp) {
    return new Promise((resolve, reject) => {
      console.log('createThumbnail başlatıldı, video URL:', videoUrl);
      const video = document.createElement('video');
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      const onLoadData = () => {
        console.log('Video tamamen yüklendi, zaman damgasına ayarlanıyor:', timeStamp);
        video.currentTime = timeStamp / 1000;
      };

      const onSeeked = () => {
        console.log('Video seeked olayı tetiklendi, resim çiziliyor.');
        if (video.videoWidth > 0 && video.videoHeight > 0) {
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
          const dataUrl = canvas.toDataURL('image/jpeg', 0.92);
          console.log('Oluşturulan Data URL:', dataUrl);
          video.removeEventListener('canplaythrough', onLoadData);
          video.removeEventListener('seeked', onSeeked);
          resolve(dataUrl);
        } else {
          video.removeEventListener('canplaythrough', onLoadData);
          video.removeEventListener('seeked', onSeeked);
          reject(new Error('Invalid video dimensions.'));
        }
      };

      video.addEventListener('canplaythrough', onLoadData);
      video.addEventListener('seeked', onSeeked);
      video.addEventListener('error', (e) => {
        console.error('Video yükleme hatası:', e);
        video.removeEventListener('canplaythrough', onLoadData);
        video.removeEventListener('seeked', onSeeked);
        reject(new Error('Video yüklenirken hata oluştu.'));
      });

      video.src = videoUrl;
      video.crossOrigin = "anonymous";
      video.load();
    });
  }

  const uploadStories = (event) => {
    event.preventDefault();
    setLoading(true);
    setUploadErrorStory('');

    if (!file) {
      console.log('File cannot be null');
      setUploadErrorStory('Please Add Image for Stories');
      setLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('data', JSON.stringify({
      caption: caption,
      mode: file.type.includes('video') ? 'video' : 'photo',
    }));
    formData.append("folder", constance.folderNames.STORIES);

    StoriesService.addStory(formData).then((res) => {
      if (file.type.includes('video')) {
        const videoUrl = URL.createObjectURL(file);
        console.log('Thumbnail request for video', videoUrl);
        createThumbnail(videoUrl, 10000)
          .then(dataUrl => {
            console.log('data url ', dataUrl)
            const thumbFormData = new FormData();
            thumbFormData.append('data', JSON.stringify({
              storyId: res.data.id,
            }));
            const byteString = atob(dataUrl.split(',')[1]);
            const mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0];
            const ab = new ArrayBuffer(byteString.length);
            const ia = new Uint8Array(ab);
            for (let i = 0; i < byteString.length; i++) {
              ia[i] = byteString.charCodeAt(i);
            }
            const blob = new Blob([ab], { type: mimeString });

            const file = new File([blob], `thumb-${new Date().valueOf()}.jpg`, {
              type: 'image/jpeg'
            });

            thumbFormData.append('file', file);
            console.log('Thumbnail request made');
            thumbFormData.append('folder', constance.folderNames.STORY_THUMBNAILS);
            dispatch(fetchStories())
            return StoriesService.uploadThumbnail(thumbFormData);
          })
          .then(() => {
            console.log('Thumbnail uploaded successfully');
            navigate('/newsfeed');
          })
          .catch(err => console.error('Thumbnail upload failed:', err));
      }
    }).catch(err => {
      console.error('Error uploading story:', err);
      setUploadErrorStory(err.message);
    }).finally(() => {

      setTimeout(() => {
        dispatch(fetchStories())
        handleClose();
        handleRemoveImageStry();
        setLoading(false);
      }, 1000);

    });
  };

  return (
    <div className='slide-wrapperstry' >
      <ul ref={listRef} className='slidestry'>
        <li className='slideitemstry'>

          <div className='strysggstion-card'>
            <div className='strysggstion-img'>
              <img src='/assets/images/vector-34@2x.png' alt='img' />
            </div>

            <div className='add-stry' onClick={handleOpen}> +</div>
            <Popup
              open={isOpen}
              closeOnDocumentClick
              onClose={handleClose}
              overlayStyle={{
                backdropFilter: 'blur(10px)',
                zIndex: 2000
              }}
              contentStyle={{
                borderRadius: '10px',
                padding: '20px',
                backgroundColor: '#FFF',
                width: 'auto',
                zIndex: 2001
              }} modal>
              {(close) => (
                <Form className='popwidth'>

                  <div className='headpop'>
                    <div style={{ padding: '10px' }}>
                      <span>
                        <a href='#!' style={{ padding: '10px 150px 10px 0' }} onClick={close}>
                          <i className='las la-times'></i>
                        </a>
                      </span>
                      <span style={{ color: '#000000', fontSize: '14px', fontWeight: 'bold' }}>
                        Lets Add Stories
                      </span>

                      {/* { checkIfUserAlreadyPostStory(storyauth.user) ?  */}
                      {showstoriesImage && (
                        <span style={{ float: 'right' }}>
                          <button
                            style={{
                              float: 'right',
                              borderRadius: '10px',
                              padding: '5px 20px',
                              height: '40px',

                            }}
                            type='submit'
                            onClick={uploadStories}
                          >
                            Upload
                          </button>
                        </span>
                      )}

                      {/* :null}  */}
                    </div>
                  </div>

                  <div style={{ margin: '0 11px 10px 11px' }}>
                    <span className='textPop '>
                      {showstoriesImage ? (

                        <>
                          <div style={{ textAlign: 'center' }}>
                            <button
                              onClick={() => setShowstoriesImage(false)}
                              style={{
                                right: '15px',
                                position: 'absolute',
                                borderRadius: '100%',
                                background: 'grey',
                                padding: '10px 10px',
                              }}
                            >
                              <i className="las la-times"></i>
                            </button>
                            <label className="fileContainer ">

                              <div className="storypic2 ">


                                <FixedSizeMedia src={storiesImage} isVideo={isVideo} />
                                <CustomStoryTextInput onChange={(e) => { setCaption(e.target.value) }} />
                              </div>
                              {loading && (
                                <Loading />
                              )}





                            </label>
                          </div>

                        </>
                      ) : (
                        <div style={{ textAlign: 'center' }}>
                          <label className='fileContainer'>
                            <div className='storypic' type='submit'>
                              <input
                                type='file'
                                name='swap_image'
                                accept='image/*, video/*'
                                onChange={handleFileStry}

                              ></input>
                              Add Story
                            </div>
                          </label>
                        </div>
                      )}
                    </span>
                    <div className='storyErr'>{uploadErrorStory ? `${uploadErrorStory}` : null}</div>
                  </div>
                  {/* </> 
                                                   
                                 )}  */}
                </Form>
              )}
            </Popup>


            <label className='fileContainer'>
              <input
                id='file-input'
                type='file'
                name='stories_image'
                accept='image/*'
                onChange={handleFileStry}
              ></input>
            </label>
            <div className='strysggstion-by'>
              <h5>Create Story</h5>
            </div>
            {/* <button  onClick={uploadStories}>Post</button> */}
          </div>

        </li>
        {myStories.length == 1 && myStories.map((storyu) => (
          <li className='slideitemstry'>
            <StoriesComponent stories={storyu} myStories={true} /> 
            
          </li>))}
        {storiesFriends && type ==="all" && storiesFriends.map((storyu) => (
          <li key={storyu.id} className='slideitemstry'>
            <StoriesComponent stories={storyu} myStories={false} />
            {loading && (
              <div style={{
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.8)'
              }}>
                <div className="loader"></div>
              </div>
            )}
          </li>
        ))}
      </ul>

      <div className='paddles'>
        <button onClick={scrollLeft} style={{ zIndex: '900' }} className='left-paddlestry paddle  '>
          <i className='las la-chevron-circle-left'></i>
        </button>
        <button onClick={scrollRight} style={{ zIndex: '900' }} className='right-paddlestry paddle'>
          <i className='las la-chevron-circle-right'></i>
        </button>
      </div>
    </div>
  )
}
