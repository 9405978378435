import PostService from '../../services/post';

export const SET_NEWS_FEED = 'SET_NEWS_FEED';

export const fetchNewsFeed = (offset, limit) => async (dispatch) => {
  try {
    const newsfeed = await PostService.getNewsFeed(offset, limit);
    console.log(newsfeed)
    dispatch(setNewsFeed(newsfeed));
  } catch (error) {
    console.error("fetchStories ERROR : ", error);
  }
};

export const setNewsFeed = (newsfeed) => ({
  type: SET_NEWS_FEED,
  payload: newsfeed,
});