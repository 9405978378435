import React, { useState } from 'react';
import user from '../../services/user';

const AddressModal = ({ setAddressModalOpen, setAddresses, addresses }) => {
    const [localAddress, setLocalAddress] = useState({
        firstname: '',
        lastname: '',
        country: '',
        city: '',
        state: '',
        zipcode: '',
        phone: '',
        fullAddress: ''
    });

    const [error, setError] = useState(""); 


    const handleChange = (e) => {
        const { id, value } = e.target;
        setLocalAddress(prevState => ({
            ...prevState,
            [id]: value
        }));
        setError("");  
    };

    const handleSubmit = async () => {
        if (!Object.values(localAddress).every(value => value.trim() !== '')) {
            setError("Please fill in all fields.");
            return;
        }
        // Send address information to the server
        try {
            const response = await user.addUserAddress(localAddress);
            if (response.status === 200) {
                console.log("Address successfully added:", response.data);

                // Add address information to local state
                const newAddress = { ...localAddress, id: response.data.id || addresses.length + 1 }; 
                setAddresses([...addresses, newAddress]);
                setAddressModalOpen(false);
                window.location.reload();

            } else {
                setError("An error occurred while adding the address.");
            }
        } catch (error) {
            console.error("Error adding address:", error);
            setError("An error occurred while adding the address.");
        }
    };

    return (
        <div className="modalContainer">
            <div className="titleCloseBtn">
                {/* Close button can be added */}
                </div>
            <div className="body d-flex flex-column align-items-start">
                <div className="title">
                    <p>Add Your Address!</p>
                </div>
                {error && <p style={{ color: 'red' }}>{error}</p>}
            </div>
            <div className="modalBody">
                <div className="inputGroup">
                    <div className="inputContainerHalf">
                        <input 
                            type="text" 
                            id="firstname"
                            value={localAddress.firstname}
                            onChange={handleChange} 
                            placeholder="Name" 
                        />
                    </div>
                    <div className="inputContainerHalf">
                        <input 
                            type="text" 
                            id="lastname"
                            value={localAddress.lastname}
                            onChange={handleChange} 
                            placeholder="Surmane" 
                        />
                    </div>
                </div>
                <div className="inputContainer">
                    <input 
                        type="text" 
                        id="country"
                        value={localAddress.country}
                        onChange={handleChange} 
                        placeholder="Country" 
                    />
                </div>
                <div className="inputGroup">
                    <div className="inputContainerHalf">
                        <input 
                            type="text" 
                            id="city"
                            value={localAddress.city}
                            onChange={handleChange} 
                            placeholder="City" 
                        />
                    </div>
                    <div className="inputContainerHalf">
                        <input 
                            type="text" 
                            id="state"
                            value={localAddress.state}
                            onChange={handleChange} 
                            placeholder="State" 
                        />
                    </div>
                </div>
                <div className="inputGroup">
                    <div className="inputContainerHalf">
                        <input 
                            type="text" 
                            id="zipcode"
                            value={localAddress.zipcode}
                            onChange={handleChange} 
                            placeholder="Zip Code" 
                        />
                    </div>
                    <div className="inputContainerHalf">
                        <input 
                            type="text" 
                            id="phone"
                            value={localAddress.phone}
                            onChange={handleChange} 
                            placeholder="Phone Number" 
                        />
                    </div>
                </div>
                <div className="inputContainer">
                    <input 
                        type="text" 
                        id="fullAddress"
                        value={localAddress.fullAddress}
                        onChange={handleChange} 
                        className="fullAddress" 
                        placeholder="Full Address" 
                    />
                </div>
            </div>
            <div className="footer">
                <button onClick={() => { setAddressModalOpen(false); }} id="cancelBtn">Cancel</button>
                <button onClick={handleSubmit}>Continue</button>
            </div>
        </div>
    );
};

export default AddressModal;
