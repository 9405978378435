import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Picker from 'emoji-picker-react';
import '../../../../assets/css/CommentsPopup.css';
import { FaTimes } from "react-icons/fa";
import { TfiArrowCircleRight } from "react-icons/tfi";
import CommentsService from '../../../../services/comments'
import CommentBox from './CommentBox';
import FriendService from '../../../../services/FriendService';
import { useSelector, useDispatch } from 'react-redux';
import { fetchGetComments } from '../../../../store/actions/commentActions';




function CommentsPopup({ open, onClose, userId, postId }) {
    
    const dispatch = useDispatch()
    const comments = useSelector(state => state.comment.comments)
    const [commentss, setCommentss] = useState(comments)
    const [comment, setComment] = useState('');
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [content, setcontent] = useState('')
    const [friendList, setfriendList] = useState([])


  


    const onEmojiClick = (emojiObject, event) => {
        setComment(prevComment => prevComment + emojiObject.emoji);
        setShowEmojiPicker(false);
    };


    useEffect(() => {
        dispatch(fetchGetComments(postId));
    }, [postId, dispatch]);

    const toggleEmojiPicker = (event) => {
        event.stopPropagation();
        setShowEmojiPicker(!showEmojiPicker);
    };

    const handleCommentSubmit = async (e) => {
        e.preventDefault()
        const comments = { content: comment };
        try {
            const res = await CommentsService.addComment(postId, userId, comments)
            console.log('handleCommentSubmit : ', res)
            setComment('')
            dispatch(fetchGetComments(postId));
        } catch (error) {
            console.log('handleCommentSubmit Error : ', error)
        }
    }


    useEffect(() => {
        if (open) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [open]);

    if (!open) return null;

    return ReactDOM.createPortal(
        <>
            <div className="dialog-backdrop2" onClick={onClose}>
                <div className="dialog-content2" onClick={e => e.stopPropagation()}>
                    <div className="dialog-header2">
                        <h2>Comments</h2>
                        <a onClick={onClose} ><FaTimes /></a>
                    </div>
                    <div className="dialog-body2">
                       
                        {comments.map((comment, index) => (
                            <CommentBox key={index} comment={comment} postId={postId}/>
                        ))}
                    </div>
                    <form onSubmit={handleCommentSubmit}>
                        <div className="dialog-footer2">

                            <button type='button' className="emoji-button" onClick={toggleEmojiPicker}>
                                😊
                            </button>

                            <div className="comment-section">
                                <input
                                    type="text"
                                    className="comment-input"
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                    placeholder="Add a comment..."
                                    required
                                />
                                <button type='submit' className="send-button" >
                                    <TfiArrowCircleRight className="send-icon" />
                                </button>
                            </div>

                        </div>
                    </form>
                </div>
                {showEmojiPicker && (
                    <div onClick={e => e.stopPropagation()} className="emoji-picker">
                        <div style={{ width: '10px', height: '200px', position: 'absolute', bottom: '20px' }}>
                            <Picker onEmojiClick={onEmojiClick} />
                        </div>
                    </div>
                )}
            </div>


        </>,
        document.body
    );
}

export default CommentsPopup;
