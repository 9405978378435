import ReactDOM from 'react-dom';
import React from 'react';
import { FaArrowRight, FaArrowLeft, FaTimes } from 'react-icons/fa';

const ImageModal = ({ media, selectedIndex, onClose }) => {
    const [index, setIndex] = React.useState(selectedIndex);

    const nextImage = () => {
        setIndex((current) => (current + 1) % media.length);
    };

    const prevImage = () => {
        setIndex((current) => (current - 1 + media.length) % media.length);
    };

    return ReactDOM.createPortal(
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            borderRadius: '10px',
            height: '100%',
            background: 'rgba(0,0,0,0.5)',
            zIndex: 1600,
            display: 'flex',
            backdropFilter: 'blur(10px)',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '10px'
        }}>
            <div style={{
                position: 'relative',
                width: '600px',
                height: '700px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: 'black',
                borderRadius: '10px'
            }}>
                <img src={media[index].mediaUrl} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />

                {media.length > 1 && (
                    <button onClick={prevImage} style={{ position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)', background: 'transparent', border: 'none', color: 'white', fontSize: '24px', cursor: 'pointer' }}>
                        <FaArrowLeft />
                    </button>)}

                {media.length > 1 && (
                    <button onClick={nextImage} style={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)', background: 'transparent', border: 'none', color: 'white', fontSize: '24px', cursor: 'pointer' }}>
                        <FaArrowRight />
                    </button>)}

                <button onClick={onClose} style={{ position: 'absolute', top: 0, right: 0, background: 'transparent', border: 'none', color: 'white', fontSize: '24px', cursor: 'pointer' }}>
                    <FaTimes />
                </button>
                
            </div>
        </div>,
        document.body
    );
};

export default ImageModal;
