import { authenticateAxios, authenticateMultipartAxios } from './authAxios';

class PostServices {
    getNewsFeed = async (page, limit) => {
        try {
            const res = authenticateAxios().get('posts/news_feed/',
                {
                    params:
                    {
                        page,
                        limit
                    }
                })
            console.log('getNewsFeed : ', res)
            return res
        } catch (error) {
            console.log('getNewsFeed Error : ', error)
        }
    }

    getNewsFeedPostCount = async () => {
        try {
            const res = authenticateAxios().get('posts/news_feed_post_count')
            return res
        } catch (error) {
            console.log('getNewsFeedPostCount Error :', error)
        }
    }

    getPostsByUserId = async (oid) => {
        try {
            const res = authenticateAxios().get(`posts/posts-by-id/${oid}`)
            return res
        } catch (error) {
            console.log('getUserPosts Error : ', error)
        }
    }

    likeUnlikePost = async (pid, tokenOwner) => {
        authenticateAxios().put(
            `posts/like-unlike/${pid}`,
            {},
            {
                params: { tokenOwner },
            },
        );
    }

    saveUnsavePost = async (pid) => {
        try {
            const res = authenticateAxios().post(`posts/save-unsave/${pid}`)
            return res
        } catch (error) {
            console.log('saveunsave error  : ', error)
            return error
        }

    }

    deletePost = async (pid) => {
        try {
            const res = authenticateAxios().put(`/posts/delete_post/${pid}`)
            return res
        } catch (error) {
            console.log('delete post err : ', error)
        }
    }

    getSwapPostCategories = () => authenticateAxios().get(`posts/get_swapPost_categories`);
    getHangShareCategories = () =>
        authenticateAxios().get(`posts/get_hangshare_categories`);

    getFilteredFeedPostCount = (type) => {
        try {
            const res = authenticateAxios().get(`posts/filtered_feed_post_count/${type}`);
            console.log('getFilteredFeedPostCount : ', res)
            return res
        } catch (error) {
            console.log('getFilteredFeedPostCount error : ', error)
        }
    }
    addHangSharePostItems = (cart, pid) =>
        authenticateAxios().post(`posts/add_hangshare_post_items/${pid}`, {items: cart});

    getHangShareCategoryItems = cid =>
        authenticateAxios().get(`posts/get_hangshare_items_menu/${cid}`);


    getPostsByPostType = (type, page, limit) => {
        try {
            const res = authenticateAxios().get('posts/posts-by-type/', { params: { type, page, limit } });
            console.log('getPostsByPostType : ', res)
            return res
        } catch (error) {
            console.log('getPostsByPostType error : ', error)
        }
    }

    
    createPost = async (formData, myItemsLength) =>{
        try {
            const response = await authenticateMultipartAxios().post(`posts/`, formData, {
                params: {
                  myItemsLength,
                },
              });
              return response
        } catch (error) {
            console.log('createPost Error :', error)
        }
    }

    getRedeemedItems = async () => {
        try {
            const res = authenticateAxios().get('posts/get_hangshare_redeemed_items')
            return res
        } catch (error) {
            console.log('getRedeemedItems Error :', error)
        }
    }

    redeemHangShareItem = async (itemId, code) =>{
        try {
            const response = await authenticateMultipartAxios().post(`posts/redeem_hangshare_item/${itemId}`, code);
            return response
          } catch (error) {
            console.log("redeemHangShareItem Error: ", error)
          }
      }
       


}

export default new PostServices;