import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Picker from 'emoji-picker-react';
import { FaPaperPlane, FaSmile } from 'react-icons/fa';
import { fetchMessages, sendMessage } from '../../store/actions/chatActions';
import Message from './Message';

function ChatWindow({ conversation, secretKey }) {
  const [newMessage, setNewMessage] = useState('');
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const dispatch = useDispatch();
  const messages = useSelector(state => state.chat.messages);

  useEffect(() => {
    if (conversation && secretKey) {
      dispatch(fetchMessages(secretKey, conversation.id));
    }
  }, [dispatch, conversation, secretKey]);

  const handleSendMessage = () => {
    if (newMessage.trim() === '') return;
    
    dispatch(sendMessage(secretKey, newMessage, conversation.id, conversation.user1, conversation.user2));
    setNewMessage('');
  };

  const handleEmojiClick = (event, emojiObject) => {
    setNewMessage(prevMessage => prevMessage + emojiObject.emoji);
    setShowEmojiPicker(false);
  };

  const toggleEmojiPicker = (event) => {
    event.stopPropagation();
    setShowEmojiPicker(!showEmojiPicker);
  };

  return (
    <div className="chat-window">
      <div className="chat-header">
        {conversation ? `Chat with ${conversation.user2}` : 'Select a conversation'}
      </div>
      <div className="chat-messages">
        {messages.map((message, index) => (
          <Message
            key={index}
            message={message}
            isSent={message.sender === conversation?.user1}
          />
        ))}
      </div>
      <div className="chat-footer">
        <button className="icon-button emoji-button" onClick={toggleEmojiPicker}>
          <FaSmile />
        </button>
        <input
          type="text"
          placeholder="Type your message..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
        />
        <button className="send-button" onClick={handleSendMessage} disabled={newMessage.trim() === ''}>
          <FaPaperPlane />
        </button>
        {showEmojiPicker && (
          <div className="emoji-picker">
            <Picker onEmojiClick={handleEmojiClick} />
          </div>
        )}
      </div>
    </div>
  );
}

export default ChatWindow;
