import React, { useEffect, useState } from 'react'
import LightboxComponent from './LightBoxComponent';
import { fetchStories } from '../../../store/actions/storyActions';
import { useDispatch } from 'react-redux';


export default function StoriesComponent({ stories, myStories }) {
  const dispatch = useDispatch()
  const [showLightbox, setShowLightbox] = useState(false);
  const [viewersVisibles, setViewersVisibles] = useState(false);
  

  useEffect(() => {
    if (myStories === true) {
      setViewersVisibles(stories.view)
    }
  }, [showLightbox])



  const handleStoryClick = () => {
    setShowLightbox(true);
  };

  const handleCloseLightbox = () => {
    setShowLightbox(false);
    dispatch(fetchStories())
  };

  return (
    <>
      <div className="strysggstion-card">
        <div className="strysggstion-Profimg" style={{ borderColor: '#033347', backgroundColor: 'grey' }}>
          <img src={stories.profilePic !== null ? stories.profilePic : ' /assets/images/vector-34@2x.png'} alt="" />
        </div>

        <div
          className="strysggstion-Profimg1 text-light text-center font-weight-bold d-flex align-items-center justify-content-center"
          style={{
            marginLeft: "4rem",
            marginTop: "0.7rem",
            borderRadius: "none !important",
            background: "#03b2cb",
            borderRadius: "0.1rem",
            boxShadow: " 0 3px 6px rgb(84 84 84 / 41%)",
          }}
        >
       
          <span></span>
        </div>
      
        <a href="#" onClick={handleStoryClick}>

        
          <div className="strysggstion-imgStry" id="stry-number-hover">
            <a href="#!">
              {stories.stories_List[0].image ? (
                <img src={stories.stories_List[0].image} alt='story' />
              ) : stories.stories_List[0].video ? (
                <img src={stories.stories_List[0].thumbnail} alt='story thumbnail' />
              ) : null}
            </a>
            <div className="strysggstion-imgStry-overlay">

            </div>
            <div className="strysggstion-imgStry-number d-flex align-items-end" style={{ textAlign:'left'}}>
           
              <span className='mb-4 text-light p-2' style={{ fontSize: '0.9rem', whiteSpace: 'normal', overflowWrap: 'break-word' }}>{stories.firstName} {stories.lastName}</span>

            </div>
          </div>
          
        </a>

        {showLightbox && (
          <LightboxComponent stories={stories} user={stories} viewersVisible={viewersVisibles} myStories={myStories} onClose={handleCloseLightbox} />
        )}

      </div>

    </>
  );
}
