import { createBrowserRouter } from "react-router-dom";
import PrivateRoute from "../components/PrivateRoute";
import App from "../components/App";
import SecuritySettingsComponent from "../components/SecuritySettingsComponent";
import Login from "../components/dashboard/Login";
import Register from "../components/dashboard/Register";
import EmailOTPVerification from "../components/dashboard/EmailOTPVerification";
import NewsFeed from "../components/app/newsfeed/NewsFeed";
import About from "../components/dashboard/About";
import PrivacyPolicy from "../components/privacyPolicy/PrivacyPolicy";
import EditProfileComponent from "../components/profile/EditProfile";
import ProfileComponent from "../components/profile/profile";
import FriendsComponent from "../components/profile/FriendsComponent";
import ActivityComponent from "../components/app/Activity/ActivitiyComponent";
import GroupComponent from "../components/group/GroupComponent";
import CreateGroupComponentMain from "../components/group/CreateGroupComponentMain";
import ViewGroupComponent from "../components/group/ViewGroupComponent";
import EditGroup from "../components/group/EditGroup";
import HangSharePosts from "../components/post/HangSharePosts";
import SavedPosts from "../components/post/SavedPosts";
import SwapPosts from "../components/post/SwapPost";
import ViewFriendsComponent from "../components/profile/ViewFriendsComponent";
import MessageComponent from "../components/Message/messageComponent";
import DeleteProfile from "../components/deleteprofile/DeleteProfile";
import VerifyDeleteProfile from "../components/deleteprofile/VerifyDeleteProfile";
import RedeemItemComponent from "../components/redeemItem/redeemItemComponent";
import HelpAndSupportComponent from "../components/HelpAndSupport/HelpAndSupportComponent";
import Reels from "../components/app/reels/ReelsFeed";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/email-verification",
    element: <EmailOTPVerification />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/privacyPolicy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/newsfeed",
    element: (
      <PrivateRoute>
        <NewsFeed />
      </PrivateRoute>
    ),
  },
  {
    path: "/hangFeed",
    element: (
      <PrivateRoute>
        <SwapPosts />
      </PrivateRoute>
    ),
  },
  {
    path: "/swapFeed",
    element: (
      <PrivateRoute>
        <SwapPosts />
      </PrivateRoute>
    ),
  },
  {
    path: "/profile",
    element: (
      <PrivateRoute>
        <ProfileComponent />
      </PrivateRoute>
    ),
  },
  {
    path: "/profile/:id",
    element: (
      <PrivateRoute>
        <ViewFriendsComponent />
      </PrivateRoute>
    ),
  },
  {
    path: "/editprofile",
    element: (
      <PrivateRoute>
        <EditProfileComponent />
      </PrivateRoute>
    ),
  },
  {
    path: "/friends",
    element: (
      <PrivateRoute>
        <FriendsComponent />
      </PrivateRoute>
    ),
  },
  {
    path: "/groups",
    element: (
      <PrivateRoute>
        <GroupComponent />
      </PrivateRoute>
    ),
  },
  {
    path: "/group/create",
    element: (
      <PrivateRoute>
        <CreateGroupComponentMain />
      </PrivateRoute>
    ),
  },
  {
    path: "/groups/:gid",
    element: (
      <PrivateRoute>
        <ViewGroupComponent />
      </PrivateRoute>
    ),
  },
  {
    path: "/settings",
    element: (
      <PrivateRoute>
        <SecuritySettingsComponent />
      </PrivateRoute>
    ),
  },
  {
    path: "/activity",
    element: (
      <PrivateRoute>
        <ActivityComponent />
      </PrivateRoute>
    ),
  },
  {
    path: "/messages",
    element: (
      <PrivateRoute>
        <MessageComponent />
      </PrivateRoute>
    ),
  },
  {
    path: "/editgroup",
    element: (
      <PrivateRoute>
        <EditGroup />
      </PrivateRoute>
    ),
  },
  {
    path: "/hangPosts",
    element: (
      <PrivateRoute>
        <HangSharePosts />
      </PrivateRoute>
    ),
  },
  {
    path: "/redeemedItem",
    element: (
      <PrivateRoute>
        <RedeemItemComponent />
      </PrivateRoute>
    ),
  },
  {
    path: "/helpAndSupport",
    element: (
      <PrivateRoute>
        <HelpAndSupportComponent />
      </PrivateRoute>
    ),
  },
  {
    path: "/swapPosts",
    element: (
      <PrivateRoute>
        <SwapPosts />
      </PrivateRoute>
    ),
  },
  {
    path: "/savedShares",
    element: (
      <PrivateRoute>
        <SavedPosts />
      </PrivateRoute>
    ),
  },
  {
    path: "/reels",
    element: (
      <PrivateRoute>
        <Reels />
      </PrivateRoute>
    ),
  },
  {
    path: "/request/delete",
    element: <DeleteProfile />,
  },
  {
    path: "/verify/delete-account/:token/:otp",
    element: <VerifyDeleteProfile />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
]);

export default router;
