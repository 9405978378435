import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchAndSetUserDetails } from '../../../store/actions/authActions'
import AuthServices from '../../../services/auth.services'
import Story from '../story/Story'
import { fetchStories } from '../../../store/actions/storyActions'
import Posts from '../post/post/Posts'
import LayoutComponent from '../../LayoutComponent'
import CreatePosts from '../post/createpost/CreatePosts'




export default function NewsFeed() {

  //redux
  const userDetails = useSelector(state => state.auth.userDetails);
  const dispatch = useDispatch()

  const [postsKey, setPostsKey] = useState(Date.now());
  
  useEffect(() => {
    dispatch(fetchAndSetUserDetails(AuthServices.getCurrentUser().id))
    dispatch(fetchStories())
   
  }, [dispatch])


  const refreshPosts = () => {
    console.log('refresh çalıştı')
    setPostsKey(Date.now()); 
  };
  return (
    <>
      {userDetails && (

        <LayoutComponent user={userDetails.data && userDetails.data}>
          <Story type="all" />
          <CreatePosts user={userDetails.data && userDetails.data} refresh={refreshPosts}/>
          <Posts key={postsKey} type='newsfeed'  />
        </LayoutComponent>
      )}
    </>
  )
}
