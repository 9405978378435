import { authenticateAxios } from './authAxios';

class ActivitiesServices {


    getActivities = async ( ) => {
        try {
            const result = await authenticateAxios().get('/activities/');
            return result;

        } catch (error) {
            console.error("getActivities Error: ", error)
        }
    }

    readActivities = async ( ) => {
        try {
            const result = await authenticateAxios().put('/activities/read');
            return result;

        } catch (error) {
            console.error("readActivities Error: ", error)
        }
    }
    


}


export default new ActivitiesServices();