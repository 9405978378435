import axios from 'axios';
import AuthService from './auth.services';
import settings from "./Settings";

const USER_API_BASE_URL = `${settings.apiUrl}/api/v1`;

const authenticateAxios = () => {
    const authAxios = axios.create({
        baseURL: AuthService.getCurrentUser() ? USER_API_BASE_URL : `${settings.apiUrl}/api/v1/`,
        headers: AuthService.getCurrentUser() ? {
            'Authorization': `Bearer ${AuthService.getCurrentUser().rawJwt}`,
            'Access-Control-Allow-Origin': "*",
            'accept': 'application/json',
        } : {}
    });

    authAxios.interceptors.response.use(response => {
        return response;
    }, error => {
        if (error.response && error.response.status === 403) {
            AuthService.logout();
            window.location = '/login';
        }
        return Promise.reject(error);
    });

    return authAxios;
};


const authenticateMultipartAxios = () => {
    const authAxios = axios.create({
        baseURL: AuthService.getCurrentUser() ? USER_API_BASE_URL : `${settings.apiUrl}/api/v1/`,
        headers: AuthService.getCurrentUser() ? {
            'Authorization': `Bearer ${AuthService.getCurrentUser().rawJwt}`,
            'Access-Control-Allow-Origin': "*",
            'accept': 'application/json',
         } : {}
    });

    authAxios.interceptors.response.use(response => {
        return response;
    }, error => {
        if (error.response && error.response.status === 403) {
            AuthService.logout();
            window.location = '/login';
        }
        return Promise.reject(error);
    });

    return authAxios;
};

export { authenticateAxios, authenticateMultipartAxios };