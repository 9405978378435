import { SET_STORIES } from "../actions/storyActions";


const initialState = {
    stories: null,
  };
  
  const storyReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_STORIES:
        return {
            ...state,
            stories: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default storyReducer;