import { SET_NEWS_FEED } from "../actions/postActions";


const initialState = {
    newsfeed: null
};

const postReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_NEWS_FEED:
            return {
                ...state,
                newsfeed: action.payload,
            };
        default:
            return state;
    }
};

export default postReducer;