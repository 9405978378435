
import { authenticateAxios, authenticateMultipartAxios } from './authAxios';




class UserService {

    getCurrentUser = async () => {
        try {
            const result = await authenticateAxios().get(`users/get_current_user`);
            console.log('user bilgileri : ',result)
            return result;
        } catch (error) {
            console.log("getCurrentUser Error : ", error)
        }
    }

    getUserById = async (uid) => {
        try {
            const result = await authenticateAxios().get(`users/get_user_by_id/${uid}`);
            return result;
        } catch (error) {
            console.log('getUserById Error : ', error)
        }
    }

    getUserAddress = async () => {
        try {
            const res = await authenticateAxios().get('users/address/get_my_address')


            return res

        } catch (error) {
            console.log('getUserAddress Error : ', error)
        }
    }


    getUserPost = async (oid) => {
        try{
            const res = await authenticateAxios().get(`posts/posts-by-id/${oid}`)
            return res.data;
        } catch (error){
            console.log('getUserPost Error: ', error)
        }

    }
    




    addUserAddress = async (data) => {
        try {

            const res = await authenticateAxios().post('users/address/add_new_address', data)
            return res
        } catch (error) {
            console.log('addUserAddress Error: ', error)
        }
    }

    deleteUserAddress = async (addressId) => {
        try {
            const res = await authenticateAxios().delete(`users/address/delete_address/${addressId}`);
            return res;
        } catch (error) {
            console.error('deleteUserAddress Error:', error);
        }
    }

    getUsers = async () => {
        try {
            const result = await authenticateAxios().get('/users/');
            return result.data;
            
        } catch (error) {
            console.error('Error getting users:', error);
            return error;
        }
    }

    

    editProfile = async (formData) => {
        try {
            const result = await authenticateMultipartAxios().post('/users/edit_profile', formData);
            return result.data;
        } catch (error) {
            console.error('Error editing profile:', error);
            return error;
        }
    }

    getUserByEmail = async () => {
        try {
            const result = await authenticateAxios().get('/users/get_current_user');
            return result.data;
        } catch (error) {
            console.error('Error fetching user by email:', error);
            return error;
        }
    }


    follow = async (email, followed_id) => {
        try {
            const result = await authenticateAxios().post(`/${email}/follows/${followed_id}`);
            return result.data;
        } catch (error) {
            console.error('Error following user:', error);
            return error;
        }
    }

    unfollow = async (email, followed_id) => {
        try {
            const result = await authenticateAxios().delete(`/${email}/unfollow/${followed_id}`);
            return result.data;
        } catch (error) {
            console.error('Error unfollowing user:', error);
            return error;
        }
    }

    uploadProfilePicture = async (formData) => {
        try {
            const result = await authenticateMultipartAxios().post('/users/upload_profile_picture', formData);
            return result.data;
        } catch (error) {
            console.error('Error uploading profile picture:', error);
            return error;
        }
    }
    changeUserToken = async (fcmToken) => {
        try {
            const result = await authenticateAxios().put('/users/update_user_token', {fcmToken});
            return result.data;
        } catch (error) {
            console.error('Error updating FCM token:', error);
            throw new Error('Failed to update FCM token due to an error');
        }
    };

    uploadCoverPicture = async (url) => {
        try {
            const result = await authenticateMultipartAxios().post('/users/edit_cover_picture', {url});
            return result.data;
        } catch (error) {
            console.error('Error uploading cover picture:', error);
            return error;
        }
    }

    likePost = async (pid) => {
        try {
            const result = await authenticateAxios().put(`/posts/like-unlike/${pid}`, { emoji: "like" });
            return result.data;
        } catch (error) {
            console.error('Error liking post:', error);
            return error;
        }
    }


    savePost = async (uid, pid) => {
        try {
            const result = await authenticateAxios().put(`/posts/${uid}/save-unsave/${pid}`);
            return result.data;
        } catch (error) {
            console.error('Error saving post:', error);
            return error;
        }
    }

    likeSwap = async (uid, sid) => {
        try {
            const result = await authenticateAxios().put(`/swaps/${uid}/like-unlike/${sid}`, { emoji: "like" });
            return result.data;
        } catch (error) {
            console.error('Error liking swap:', error);
            return error;
        }
    }

    searchUsers = async (keyword) => {
        try {
            const result = await authenticateAxios().get(`/users/search_users`, {
                params: { keyword }
            });
            return result.data;
        } catch (error) {
            console.error("Error searchUsers", error)
        }
    }

    getEncryptionKeys = async () => {
        try {
            const result = await authenticateAxios().get('users/get_encryption_keys');
            console.log("SERVİS KEYY", result)
            return result.data;
        } catch (error) {
            console.error("Error getEncryptioonKeys", error)
        }
    }

    updateEncryptionKeys = async (publicKey, privateKey) => {
        console.log("updateEncryptionKeys çalıştı")
        try {
            const result = await authenticateAxios().put('users/set_encryption_keys', {publicKey, privateKey});
            return result;
        } catch (error) {
            console.error("Error updateEncryptionKeys", error);
        }
    }

    

}

  
export default new UserService();

