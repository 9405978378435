import '../../assets/css/TermModal.css';
import React from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css'; 
import privacy from  '../../agreement/clarification_v1.pdf';

const SecurityTermsModal = ({ onAccept }) => {
    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Privacy Policy</h2>
                <div className="terms-content" style={{ overflowY: 'scroll', height: '600px' }}>
                    <Worker workerUrl={`https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js`}>
                        <Viewer fileUrl={privacy} />
                    </Worker>
                </div>
                <div className="modal-actions">
                    <button onClick={onAccept}>Accept</button>
                </div>
            </div>
        </div>
    );
};

export default SecurityTermsModal;
