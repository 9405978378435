import React, { useState, useEffect } from 'react';
import UserService from '../../services/user';
import AddressModal from './AddressModal';

const AddressCard = () => {
    const [AddressModalOpen, setAddressModalOpen] = useState(false);
    const [addresses, setAddresses] = useState([]);

    useEffect(() => {
        const loadAddresses = async () => {
            const response = await UserService.getUserAddress();
            if (response && response.data) {
                setAddresses(response.data);
            }
        };
        loadAddresses();
    }, []);

    const handleDeleteAddress = async (addressId) => {
        try {
            const response = await UserService.deleteUserAddress(addressId);
            if (response) {
                const updatedAddresses = addresses.filter(address => address.id !== addressId);
                setAddresses(updatedAddresses);
                console.log('Address successfully deleted:', response.data.message);
            }
        } catch (error) {
            console.error('Error deleting address:', error);
        }
    };
    
    const AddressCardItem = ({ address, index, onDelete }) => (
        <div className="address-card">
            <div className="card-header">
                <h3>Address {index + 1}</h3>
                <button className="delete-button" onClick={onDelete}><i className="fa fa-trash"></i></button>
            </div>
            <p><strong>Name:</strong> {address.firstName} {address.lastName}</p>
            <p><strong>Address:</strong> {address.fullAddress}</p>
            <p><strong>City & State:</strong> {address.city}, {address.state}</p>
            <p><strong>Zip Code:</strong> {address.zipcode}</p>
            <p><strong>Phone:</strong> {address.phone}</p>
        </div>
    );
    return (
        <div className="right-edit-profile-content">
            <div className="right-edit-personal-information-top">
                <h1>My Address</h1>
            </div>
            <div className="right-edit-profile-details padding">
                {addresses.map((address, index) => (
                    <AddressCardItem
                        key={address.id}
                        address={address}
                        index={index}
                        onDelete={() => handleDeleteAddress(address.id)}
                    />
                ))}
            </div>
            <div className="button-container">
                <button
                    className="add-address-button"
                    onClick={() => setAddressModalOpen(true)}>
                    Add Address
                </button>
            </div>
            {AddressModalOpen && (
                <AddressModal
                setAddressModalOpen={setAddressModalOpen}
                setAddresses={setAddresses}
                addresses={addresses}
            />
            )}
        </div>
    );
};


export default AddressCard;
