import React, {useEffect, useState} from 'react'
import Layout from './Layout'
import { useNavigate, useLocation  } from 'react-router-dom'
import AuthServices from '../../services/auth.services'



export default function EmailOTPVerification() {

    const navigate = useNavigate()


    const location = useLocation();
    const email = location.state?.email;

    useEffect(()=>{
        if(!email){
            navigate('/login')
        }
    },[])

    //state
    const [OTP, setOTP ] = useState('')
    const [error, setError] = useState('')

    const emailOTPsubmit = async (event) => {
        event.preventDefault()
        console.log('otp, email : ',OTP,email)
        await AuthServices.verifyEmailConfirmOTP(OTP, email).then(res => {
          console.log(res.data)
          navigate("/login");
        },
          error => {
            const resMessage = (error.response && error.response.data && error.response.data.message)
              || error.message || error.toString();
              setError("verification code is incorrect")
          });
      }


    return (
        <Layout>
           <div className="row justify-content-center">


<div className="card col-4 reg justify-content-center align-items-center">
                <h2 className="card-title text-center" style={{ fonstSize: '30px', fontWeight:'bold' }}>Verify your e-mail</h2>
                <form onSubmit={emailOTPsubmit} >
                    <div className="row">
                        <div className="col-md-6 py-3 pl-1 form-icon txt_field">
                            <input
                                type="text"
                                value={OTP}
                                className={`form-control m-0 ${error && "invalid"} border-radius`}
                                placeholder='Enter the code sent to the e-mail address'
                                onChange={(e) => {setOTP(e.target.value)}}
                            />

                        </div>
                    </div>
                    <div className="submit-btns" >
                        <button className="mtr-btn signup" style={{ backgroundColor: '#033347'}}  type='submit' >
                            <span>Submit</span>
                        </button>
                    </div>
                    
                </form>
            </div>
            </div>
         
        </Layout>
    )
}
