import React from 'react'
import HeaderMenu from './HeaderMenu';
import logo from '../../assets/images/logo/kare.png'
import leftImage from '../../assets/images/loginimage.png'
import playStore from '../../assets/images/qr/play.jpg'
import appStore from '../../assets/images/qr/app.jpg'
import shareupxlogo from '../../assets/images/shareupx_logo.jpg'
import AnimatedImageAbout from './AnimatedImageAbout';

export default function About() {
  return (
    <div>
      <div className="theme-layout">
        <div className="container-about pdng0">
          <div className="topbarLand transparent">
            <div className="logo">
              <a title href="/"> <img src={shareupxlogo} style={{ width: '300px' }} alt="" /> </a>
            </div>

          </div>
          <div className="row shareup" >

            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <AnimatedImageAbout />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <div className="container">
                <h2 className="heading" style={{ color: 'black', textAlign: 'left' }}>About us</h2>
                <div className="about-story">
                  <p className="shareup-text " style={{ color: 'black' }}>ShareUpX has started in Türkiye with the aim of being the most private and secure social media in the world. Our founder Mr. Firat Yagmur came up with a wonderful and unique idea of sharing platform where people can connect from heart to heart. Moreover, it is secure and protects your privacy with the most trending technology. We provide you most private and secure social media platform with end-to-end encryted messaging. Your data is our concern. You Share, we share and everyone shares. Let’s share without fear and hesitation. You are our concern, not our target.</p>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>


    </div>
  );
}
