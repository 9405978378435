import React from 'react';
import '../../assets/css/about.css' 
import image1 from '../../assets/aboutanimated/1.png'
import image3 from '../../assets/aboutanimated/3.png'
import image4 from '../../assets/aboutanimated/4.png'
import image5 from '../../assets/aboutanimated/5.png'
import image6 from '../../assets/aboutanimated/6.png'
import image7 from '../../assets/aboutanimated/7.png'
import image8 from '../../assets/aboutanimated/8.png'
import image9 from '../../assets/aboutanimated/9.png'
import image10 from '../../assets/aboutanimated/10.png'
import image11 from '../../assets/aboutanimated/11.png'
import image12 from '../../assets/aboutanimated/12.png'
import image13 from '../../assets/aboutanimated/13.png'

function AnimatedImageAbout() {
  const images = [
    image1,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
    image11,
    image12,
    image13,
  ];

  return (
    <div className="about-img">
      {images.map((src, index) => (
        <img key={index} style={{animationDelay: `${-index * 5}s`}} src={src} alt={`image${index + 1}`} />
      ))}
    </div>
  );
}

export default AnimatedImageAbout;