import { authenticateAxios, authenticateMultipartAxios } from "./authAxios";

class ChatServices {

    async getConversations() {
        try {
            const result = await authenticateAxios().get(`chat/get_conversations`);
            return result.data;
        } catch (error) {
            console.error(error);
        }
    }

    async getMessagesCount(cid) {
        try {
            const result = await authenticateAxios().get(`chat/${cid}/get_messages_count`);
            return result.data;
        } catch (error) {
            console.error(error);
        }
    }

    async getMessages(cid, offset = 0, limit = 10) {
        
        try {
            const result = await authenticateAxios().get(`chat/${cid}/get_messages`, {
                params: {
                    offset: offset,
                    limit: limit
                }
            });
            return result.data;
        } catch (error) {
            console.error(error);
        }
    }
    

    

    async getUnreadConversationsCount() {
        try {
            const result = await authenticateAxios().get(`chat/get_unread_conversations_count`);
            return result.data;
        } catch (error) {
            console.error(error);
        }
    }

    async sendMessage(sid, rid, files, data) {
        try {
            const formData = new FormData();
            files.forEach(file => formData.append("files", file));
            formData.append("data", JSON.stringify({
                ...data
            }));
    
            const result = await authenticateMultipartAxios().post(
                `chat/${sid}/send_message/${rid}?tokenOwner=${sid}`, 
                formData, 
                {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                }
            );
            return result.data;
        } catch (error) {
            console.error(error);
        }
    }
    

    async createConversation(rid, data) {
        try {
            const result = await authenticateAxios().post(
                `chat/create_conversation/${rid}`, 
                data
            );
            return result.data;
        } catch (error) {
            console.error(error);
        }
    }

    async swapAgree(pid, contactId) {
        try {
            const result = await authenticateAxios().post(
                `chat/${pid}/swap_agree/${contactId}`
            );
            return result.data;
        } catch (error) {
            console.error(error);
        }
    }

    async updateConversationStatus(cid, type, rid, data) {
        try {
            const result = await authenticateAxios().put(
                `chat/${cid}/update_conversation_status/${type}/${rid}`, 
                data
            );
            return result.data;
        } catch (error) {
            console.error(error);
        }
    }

    async readMessages(cid, contactId) {
        try {
            const result = await authenticateAxios().put(
                `chat/${cid}/read_messages/${contactId}`
            );
            return result.data;
        } catch (error) {
            console.error(error);
        }
    }

    async deleteConversation(cid) {
        try {
            const result = await authenticateAxios().delete(`chat/${cid}/delete_conversation`);
            return result.data;
        } catch (error) {
            console.error(error);
        }
    }

    async swapDisagree(pid, contactId) {
        try {
            const result = await authenticateAxios().delete(`chat/${pid}/swap_disagree/${contactId}`);
            return result.data;
        } catch (error) {
            console.error(error);
        }
    }
}

export default new ChatServices();
