import '../../../../assets/css/SwapImageUploader.css'
import { FaFileImage } from "react-icons/fa";
import React, { useEffect, useRef, useState } from 'react';
import { FaTimes } from "react-icons/fa";
import { FaUpload } from "react-icons/fa";
import { BsFillTrash3Fill } from "react-icons/bs";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
function ImageUploader({ swapLoad, kontrol, myItemsSet, requestedSet }) {
    const [activeTab, setActiveTab] = useState(1);
    const [myItems, setMyItems] = useState([]);
    const [requested, setRequested] = useState([]);
    const [currentSlideTab1, setCurrentSlideTab1] = useState(0);
    const [currentSlideTab2, setCurrentSlideTab2] = useState(0);
    const fileInputRef = useRef(null)
    const fileInputRef2 = useRef(null)



    useEffect(() => {
        if (requested.length === 0 || myItems.length === 0) {
            kontrol(false)
        } else if (requested.length !== 0 && myItems.length !== 0) {
            kontrol(true)
        }
        myItemsSet(fileInputRef.current.files)
        requestedSet(fileInputRef2.current.files)

    
    })

    const handleImageUpload = (event, tab) => {
        const files = Array.from(event.target.files);  
        const newImageSrcs = files.map(file => URL.createObjectURL(file));

        if (tab === 1) {
            setMyItems(prev => [...prev, ...newImageSrcs]);
        } else {
            setRequested(prev => [...prev, ...newImageSrcs]);
        }
    };

    const changeSlide = (direction, tab) => {
        if (tab === 1) {
            if (myItems.length > 1) { 
                const newSlide = (currentSlideTab1 + direction + myItems.length) % myItems.length;
                setCurrentSlideTab1(newSlide);
            }
        } else {
            if (requested.length > 1) {  
                const newSlide = (currentSlideTab2 + direction + requested.length) % requested.length;
                setCurrentSlideTab2(newSlide);
            }
        }
    };
    const handleIconClick = (ref) => {
        if (ref.current) {
            ref.current.click();
        } else {
            console.log("Ref is not attached to any element.");
        }
    };
    const removeImage = (index, tab) => {
        if (tab == 2) {
            const filteredImages = requested.filter((_, i) => i !== index);
            setRequested(filteredImages);
            if (index === requested.length - 1) {
                setCurrentSlideTab2(0);
            }
        } else {
            const filteredImages = myItems.filter((_, i) => i !== index);
            setMyItems(filteredImages);
            if (index === myItems.length - 1) {
                setCurrentSlideTab1(0);
            }
        }

    };


    return (
        <div className="image-uploader">
            <input ref={fileInputRef} type="file" style={{ display: 'none' }} onChange={(e) => handleImageUpload(e, 1)} multiple />
            <input style={{ display: 'none' }} ref={fileInputRef2} type="file" onChange={(e) => handleImageUpload(e, 2)} multiple />
            <div className="tabs">
                <button type='button' className={`tab-button ${activeTab === 1 ? 'active' : ''}`} onClick={() => setActiveTab(1)}>Owned</button>
                <button type='button' className={`tab-button ${activeTab === 2 ? 'active' : ''}`} onClick={() => setActiveTab(2)}>Requested</button>
            </div>
            <div className="content">

                {activeTab === 1 ? (
                    <div className="uploader">

                        <div className="sliderSwap">

                            {myItems.length > 0 ? (
                                <>
                                    {myItems.length > 1 && (
                                        <button type='button' className="slide-button" onClick={() => changeSlide(-1, 1)}>
                                            <FaAngleLeft style={{ width: '20px', height: '20px' }} />  </button>
                                    )}

                                    <div className="swapImageContainer">

                                        <>

                                            <img className='imgSwap' src={myItems[currentSlideTab1]} alt="Slide" />


                                        </>


                                    </div>
                                    {myItems.length > 1 && (
                                    <button type='button' className="slide-button" onClick={() => changeSlide(1, 1)}><FaAngleRight style={{ width: '20px', height: '20px' }} /></button>
                                )} </>
                            ) : (
                                <div className="no-image-content">



                                    <button type='button' onClick={e => handleIconClick(fileInputRef)} style={{ width: '100px', height: '100px', borderRadius: '10px', background: 'none' }}>
                                        <FaUpload style={{ width: '50px', height: '50px', color: '#033347' }} />
                                    </button>
                                    <p style={{ fontWeight: 'bold', color: '#033347' }} >Upload</p>
                                </div>
                            )}

                        </div>
                        {myItems.length > 0 && (
                            <>
                                <button type='button' style={{ width: '50px', height: '30px', borderRadius: '10px' }} onClick={e => handleIconClick(fileInputRef)} >
                                    <FaUpload style={{ width: '20px', height: '20px', color: 'white' }} /></button>

                                <button type='button' style={{ width: '50px', height: '30px', borderRadius: '10px', backgroundColor: 'red', color: 'white' }}
                                    onClick={() => removeImage(currentSlideTab1, 1)} >
                                    <BsFillTrash3Fill style={{ width: '20px', height: '20px', color: 'white' }} /></button>
                            </>

                        )}
                    </div>
                ) : (
                    <div className="uploader">

                        <div className="sliderSwap">
                            {requested.length > 0 ? (
                                <>
                                 {requested.length > 1 && (<button type='button' className="slide-button" onClick={() => changeSlide(-1, 2)}>
                                    <FaAngleLeft style={{ width: '20px', height: '20px' }} />  </button>
                               )}
                                          <div className="swapImageContainer">

                                        <>
                                            <img className='imgSwap' src={requested[currentSlideTab2]} alt="Slide" />

                                        </>


                                    </div>
                                    {requested.length > 1 && (
                                    <button type='button' className="slide-button" onClick={() => changeSlide(1, 2)}><FaAngleRight style={{ width: '20px', height: '20px' }} />  </button>
                                )}   </>
                            ) : (
                                <div className="no-image-content">

                                    <button type='button' onClick={e => handleIconClick(fileInputRef2)} style={{ width: '100px', height: '100px', borderRadius: '10px', background: 'none' }}>
                                        <FaUpload style={{ width: '50px', height: '50px', color: '#033347' }} />

                                    </button>
                                    <p style={{ fontWeight: 'bold', color: '#033347' }} >Upload</p>

                                </div>
                            )}
                        </div>
                        {requested.length > 0 && (
                            <>
                                <button type='button' style={{ width: '50px', height: '30px', borderRadius: '10px' }} onClick={e => handleIconClick(fileInputRef2)} >
                                    <FaUpload style={{ width: '20px', height: '20px', color: 'white' }} /></button>

                                <button type='button' style={{ width: '50px', height: '30px', borderRadius: '10px', backgroundColor: 'red', color: 'white' }}
                                    onClick={() => removeImage(currentSlideTab2, 2)} >
                                    <BsFillTrash3Fill style={{ width: '20px', height: '20px', color: 'white' }} /></button>

                            </>


                        )}
                    </div>
                )}
            </div>

        </div>
    );
}

export default ImageUploader;
