import React from 'react';
import '../../assets/css/RedeemedItemCard.css';

export default function RedeemedItemCard({ item }) {
  const redeemedDate = new Date(item.createdAt);
  const formattedDate = `${redeemedDate.toLocaleDateString('tr-TR', { day: '2-digit', month: '2-digit', year: 'numeric' })}, ${redeemedDate.toLocaleTimeString('tr-TR', { hour: '2-digit', minute: '2-digit' })}`;

  return (
    <div className="redeemed-item-card">
      <div className="redeemed-item-image-container">
        <img src={item.imageUrl} alt={item.name} className="redeemed-item-image" />
      </div>
      <div className="redeemed-item-details-container">
        <h3 className="redeemed-item-name">{item.name}</h3>
        <p className="redeemed-item-quantity">Code: {item.code}</p>
        <div className="redeemed-item-price-container">
          <span className="redeemed-item-price-text">Redeemed On: {formattedDate}</span>
        </div>
      </div>
    </div>
  );
}