import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAndSetUserDetails } from '../../store/actions/authActions';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import AppHeader from "../app/layout/AppHeader";
import '../../assets/css/groupComponents.css';
import GroupService from '../../services/GroupService';
import AuthService from '../../services/auth.services';
import fileStorage from '../../config/fileStorage';
import UserService from '../../services/user';
import ConfirmModal from '../profile/ConfirmModal';
import './editgroup.css';
import grpicon from '../../assets/images/grpicon.png';
import contance from '../../config/contance';

export default function EditGroup() {
    const [view, setView] = useState('PhotoAndPrivacy');
    const [members, setMembers] = useState([]);
    const [isAdmin, setGroupAdmin] = useState(false);
    const [user, setUser] = useState({});
    const [loading, setLoading] = useState(true);
    const [modalContent, setModalContent] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => { },
        onClose: () => setModalContent(prev => ({ ...prev, isOpen: false }))
    });
    const [groupDetails, setGroupDetails] = useState({
        name: '',
        description: '',
        privacySetting: false,
        imageUrl: null,
        imageFile: null
    });
    const location = useLocation();
    const groupInfo = location.state?.groupInfo;
    const dispatch = useDispatch();
    const userDetails = useSelector(state => state.auth.userDetails);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const userId = AuthService.getCurrentUser().id;
                if (userId) {
                    await dispatch(fetchAndSetUserDetails(userId));
                    setUser(userDetails);
                }
            } catch (error) {
                console.error("Error fetching user details:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchUserDetails();
    }, [dispatch]);

    useEffect(() => {
        const getGroupDetails = async () => {
            try {
                if (groupInfo.owner) {
                    const adminResponse = await UserService.getUserById(groupInfo.owner);
                    setGroupAdmin(adminResponse.data);
                }
            } catch (error) {
                console.error("Error fetching group details:", error);
            }
        };
        if (userDetails && userDetails.id) {
            getGroupDetails();
        }
    }, [userDetails, groupInfo.owner]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setGroupDetails(prevDetails => ({
            ...prevDetails,
            [name]: value
        }));
    };

    const performDelete = async () => {
        setLoading(true);
        try {
            await GroupService.deleteGroup(groupInfo.id);
            navigate('/groups');
        } catch (error) {
            console.error("Grup silme hatası:", error);
        } finally {
            setLoading(false);
            setModalContent(prev => ({ ...prev, isOpen: false }));
        }
    };

    const handleTogglePrivacy = () => {
        setModalContent({
            isOpen: true,
            title: 'Change Privacy Setting',
            message: `Are you sure you want to make your group ${groupDetails.privacySetting ? 'public' : 'private'}?`,
            onConfirm: confirmPrivacyChange,
            onClose: () => setModalContent(prev => ({ ...prev, isOpen: false }))
        });
    };

    const handleDelete = () => {
        setModalContent({
            isOpen: true,
            title: 'Delete Group',
            message: 'Are you sure you want to delete this group?',
            onConfirm: performDelete,
            onClose: () => setModalContent(prev => ({ ...prev, isOpen: false }))
        });
    };

    const handleSaveChanges = () => {
        setModalContent({
            isOpen: true,
            title: 'Save Changes',
            message: 'Are you sure you want to save these changes?',
            onConfirm: performSaveChanges,
            onClose: () => setModalContent(prev => ({ ...prev, isOpen: false }))
        });
    };

    const performSaveChanges = async () => {
        setLoading(true);
        try {
            const data = {
                name: groupDetails.name,
                description: groupDetails.description,
                privacySetting: !groupDetails.privacySetting
            };
            await GroupService.editGroup(groupInfo.id, data);
        } catch (error) {
            console.error("Error updating group details:", error);
        } finally {
            setLoading(false);
        }
        setModalContent(false);
    };

    const confirmPrivacyChange = () => {
        setModalContent(prev => ({ ...prev, isOpen: false }));
        setGroupDetails(prevDetails => ({
            ...prevDetails,
            privacySetting: !prevDetails.privacySetting
        }));
        performSaveChanges();
    };

    useEffect(() => {
        if (groupDetails.imageFile) {
            const formData = new FormData();
            formData.append("file", groupDetails.imageFile);
            formData.append("folder", JSON.stringify({ folder: contance.folderNames.GROUP_COVER_PICTURES }));
            try {
                GroupService.addGroupImage(groupInfo.id, formData);
            } catch (error) {
                console.error("Error uploading group image:", error);
            }
        }
    }, [groupDetails.imageFile, groupInfo.id]);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setGroupDetails(prevDetails => ({
                ...prevDetails,
                imageFile: file,
                imageUrl: URL.createObjectURL(file)
            }));
        }
    };

    const fetchGroupDetails = async () => {
        setLoading(true);
        try {
            const response = await GroupService.getGroupById(groupInfo.id);
            if (response) {
                setGroupDetails({
                    name: response.data[0].name,
                    description: response.data[0].description,
                    privacySetting: response.data[0].privacySetting,
                    imageUrl: response.data[0].imageUrl || grpicon
                });
            }
        } catch (error) {
            console.error("Error fetching group details:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (groupInfo && groupInfo.id) {
            fetchGroupDetails();
        }
    }, [groupInfo.id]);

    const renderView = () => {
        if (view === 'PhotoAndPrivacy') {
            return (
                <div className="editgroup-right-section">
                    <h2 className="editgroup-heading">Photo And Privacy</h2>
                    <div className="editgroup-privacy-toggle">
                        <p>Private Group</p>
                        <label className="editgroup-switch">
                            <input type="checkbox" checked={groupDetails.privacySetting} onClick={handleTogglePrivacy} readOnly />
                            <span className="editgroup-slider round"></span>
                        </label>
                    </div>
                    <h1>Change Your Group Photo</h1>
                    <div className="editgroup-image-container" onClick={() => document.getElementById('file-input').click()}>
                        <img src={groupDetails.imageUrl} alt="Group Preview" id="preview-profile" />
                    </div>
                    <input
                        id="file-input"
                        type="file"
                        name="profile_image"
                        accept="image/*"
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                    />
                </div>
            );
        } else if (view === "GroupNameAndDescription") {
            return (
                <div className="editgroup-right-section">
                    <h2 className="editgroup-heading">Edit Group Details</h2>
                    <div className="form-group">
                        <label htmlFor="group-name">Group Name</label>
                        <textarea name="name" value={groupDetails.name} onChange={handleInputChange} placeholder="Enter group name"></textarea>
                    </div>
                    <div className="form-group">
                        <label htmlFor="group-description">Description</label>
                        <textarea name="description" value={groupDetails.description} onChange={handleInputChange} placeholder="Enter group description"></textarea>
                    </div>
                    <button className="editgroup-save-button" onClick={handleSaveChanges}>Save Changes</button>
                </div>
            );
        } else if (view === "InviteAndDelete") {
            return (
                <div className="editgroup-right-section">
                    <h2 className="editgroup-heading">Invite or Delete Group</h2>
                    <div className="form-group">
                        <ul className="editgroup-members-list">
                            {members && members.length > 0 && members.filter(member => member.id !== isAdmin.id).map((member) => (
                                <li key={member.id} className="editgroup-member-item">
                                    <figure>
                                        <img src={fileStorage.baseUrl + member.profilePic} alt="" />
                                    </figure>
                                    <div className="editgroup-member-meta">
                                        <h4>{`${member.firstName} ${member.lastName}`}</h4>
                                    </div>
                                    <div>
                                        <button className="editgroup-action-button" onClick={() => console.log("Invite member")}>Invite</button>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="form-group">
                        <button className="editgroup-delete-button" onClick={handleDelete}>Delete Group</button>
                    </div>
                </div>
            );
        } else if (view === "BlockAndUnblock") {
            return (
                <div className="editgroup-right-section">
                    <h2 className="editgroup-heading">Block and Unblock Users</h2>
                    <ul className="editgroup-members-list">
                        <p>Block Users</p>
                        {members && members.length > 0 && members.filter(member => member.id !== isAdmin.id).map((member) => (
                            <li key={member.id} className="editgroup-member-item">
                                <figure>
                                    <img src={fileStorage.baseUrl + member.profilePic} alt="" />
                                </figure>
                                <div className="editgroup-member-meta">
                                    <h4>{`${member.firstName} ${member.lastName}`}</h4>
                                </div>
                                <button className="editgroup-action-button" onClick={() => console.log("Block user")}>Block</button>
                            </li>
                        ))}
                    </ul>
                    <ul className="editgroup-members-list">
                        <p>Unblock Users</p>
                        {members && members.length > 0 && members.filter(member => member.id !== isAdmin.id).map((member) => (
                            <li key={member.id} className="editgroup-member-item">
                                <figure>
                                    <img src={fileStorage.baseUrl + member.profilePic} alt="" />
                                </figure>
                                <div className="editgroup-member-meta">
                                    <h4>{`${member.firstName} ${member.lastName}`}</h4>
                                </div>
                                <button className="editgroup-action-button unblock" onClick={() => console.log("Unblock user")}>Unblock</button>
                            </li>
                        ))}
                    </ul>
                </div>
            );
        }
        return (<div className="editgroup-right-section"></div>);
    };

    return (
        <>
            <AppHeader />
            <div className="editgroup-container">
                <div className="editgroup-left-section">
                    <h1>Edit Group</h1>
                    <div className={`editgroup-list-item ${view === "PhotoAndPrivacy" ? 'active' : ''}`} onClick={() => setView('PhotoAndPrivacy')}><p>Photo And Privacy</p></div>
                    <div className={`editgroup-list-item ${view === "GroupNameAndDescription" ? 'active' : ''}`} onClick={() => setView('GroupNameAndDescription')}><p>Group Name and Description</p></div>
                    <div className={`editgroup-list-item ${view === "InviteAndDelete" ? 'active' : ''}`} onClick={() => setView('InviteAndDelete')}><p>Invite and Delete</p></div>
                    <div className={`editgroup-list-item ${view === "BlockAndUnblock" ? 'active' : ''}`} onClick={() => setView('BlockAndUnblock')}><p>Block and Unblock</p></div>
                </div>
                {renderView()}
            </div>
            <ConfirmModal
                isOpen={modalContent.isOpen}
                title={modalContent.title}
                message={modalContent.message}
                onClose={modalContent.onClose}
                onConfirm={modalContent.onConfirm}
            />
        </>
    );
}
