import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import '../../../assets/css/LightBoxComponent.css';
import { formatDistanceToNow, set } from 'date-fns';
import StoriesService from '../../../services/stories';
import { fetchStories } from '../../../store/actions/storyActions';
import { useDispatch } from 'react-redux';
import LikeIcon from '../../elements/LikeIcon';





const LightboxComponent = ({ stories, user, onClose, myStories }) => {


    const dispatch = useDispatch()
    const [currentIndex, setCurrentIndex] = useState(0);
    const [progress, setProgress] = useState(0);
    const [liked, setLiked] = useState(false);
    const [isPlaying, setIsPlaying] = useState(true);
    const [viewersVisibles, setViewersVisibles] = useState(false);
    const [viewersVisible, setViewersVisible] = useState(false);
    const [timer, setTimer] = useState(null);
    const [viewedIndexes, setViewedIndexes] = useState([]);
    const [showDetail, setShowDetail] = useState(false)
    const [paused, setPaused] = useState(false);

    const currentStory = stories.stories_List[currentIndex];


    useEffect(() => {
        clearInterval(timer);

        if (stories.stories_List[currentIndex].video) {
            const videoElement = document.querySelector('.video');
            videoElement.onloadedmetadata = () => {
                videoElement.play();
                const interval = setInterval(() => {

                    setProgress((videoElement.currentTime / videoElement.duration) * 100);

                    if (videoElement.ended) {
                        clearInterval(interval);
                        handleAutoPlayNext();
                    }
                }, 100);
                setTimer(interval);
            };
        } else {
            const interval = setInterval(() => {
                if (paused) {
                    setProgress((oldProgress) => {
                        const newProgress = oldProgress + (100 / 30);
                        if (newProgress >= 100) {
                            clearInterval(interval);
                            handleAutoPlayNext();
                        }
                        return newProgress;
                    });
                }
            }, 100);
            setTimer(interval);
        }
    }, [currentIndex, stories.stories_List, paused]);

    useEffect(() => {
        setPaused(true)
    }, [])

    useEffect(() => {
        return () => clearInterval(timer);
    }, [timer]);

    const handleAutoPlayNext = () => {
        if (currentIndex < stories.stories_List.length - 1) {
            setProgress(0)
            setCurrentIndex(currentIndex + 1);
        } else {
            onClose();
        }
    };



    const handleAreaClick = (e) => {
        const bounds = e.target.getBoundingClientRect();
        const x = e.clientX - bounds.left;
        const width = bounds.width;
        if (x < width * 0.3) { // left side
            handleNavigation(-1);
        } else if (x > width * 0.7) { // right side
            handleNavigation(1);
        } else { // middle side
            if (stories.stories_List[currentIndex].video) {
                togglePlayPause();
            } else {
                togglePauseImage()
            }
        }
    };

    const togglePauseImage = () => {
        setPaused(!paused);
    }

    const handleNavigation = (direction) => {
        const newIndex = currentIndex + direction;


        if (newIndex >= 0 && newIndex < stories.stories_List.length) {
            clearInterval(timer);
            setProgress(0);
            setTimeout(() => {
            }, 1000);
            setShowDetail(false)
            setPaused(true)
            setCurrentIndex(newIndex);
            setIsPlaying(true);

        }
    };
    const togglePlayPause = () => {

        setIsPlaying(!isPlaying);
        const videoElement = document.querySelector('.video');
        if (videoElement) {
            isPlaying ? videoElement.pause() : videoElement.play();
        }
    };

    const handleViewerToggle = () => {
        setViewersVisibles(currentStory.views.user);
        setViewersVisible(!viewersVisible)
        console.log('viewer toggle update ', viewersVisible, viewersVisibles)
    };

    useEffect(() => {
        setLiked(currentStory.isLiked === 1 ? true : false)
    }, [currentIndex])

    const handleLikeClick = () => {
        setLiked(!liked)
        StoriesService.likeUnlikeStory(currentStory.id, user.id)
    };

    useEffect(() => {
        setViewersVisibles(currentStory.views)
        console.log(currentStory.views)
    }, [currentStory])

    useEffect(() => {
        togglePlayPause()
    }, [showDetail])

    const deleteStories = async (id) => {
        await StoriesService.deleteStory(id)
        dispatch(fetchStories())
        setShowDetail(false)

    }


    useEffect(() => {

        const isViewed = viewedIndexes.includes(currentIndex);

        if (!isViewed && !myStories) {
            const currentStory = stories.stories_List[currentIndex];

            if (!currentStory.isViewed) {
                console.log('view çalıştı');
                StoriesService.viewStory(currentStory.id);

                setViewedIndexes(prevIndexes => [...prevIndexes, currentIndex]);
            }
        }
    }, [currentIndex, viewedIndexes, myStories]);





    const tabs = stories.stories_List.map((_, index) => {
        const fillWidth = index < currentIndex ? 100 : (index === currentIndex ? progress : 0);
        return (
            <div key={index}
                className={`tab ${index === currentIndex ? 'active' : ''}`}
                style={{
                    width: `${100 / stories.stories_List.length}%`,
                    backgroundColor: 'grey',
                    borderRadius: '100px'
                }}>
                <div className="progress-bar-fill" style={{ width: `${fillWidth}%` }}></div>
            </div>
        );
    });

    const modalContent = (
        <div className="lightbox" onClick={(e) => e.stopPropagation()}>
            <div className="lightbox-overlay" onClick={onClose}></div>
            <div className="lightbox-content" onClick={handleAreaClick}>
                <button className="close-button" onClick={(event) => {
                    event.stopPropagation();
                    onClose();
                }}>
                    <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="close-icon">
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg>
                </button>

                {myStories && (
                    <div className='details-content' >
                        <button className="close-button mr-5" onClick={(event) => { event.stopPropagation(); setShowDetail(!showDetail) }} style={{ marginBottom: '10px' }}>
                            <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="detail-icon">
                                <circle cx="12" cy="12" r="1"></circle>
                                <circle cx="12" cy="5" r="1"></circle>
                                <circle cx="12" cy="19" r="1"></circle>
                            </svg>
                        </button>
                        {showDetail && (
                            <div className="details-container">
                                <button onClick={(event) => { event.stopPropagation(); deleteStories(currentStory.id) }} style={{ border: 'none', background: 'none', color: 'white', fontSize: '16px', cursor: 'pointer' }}>
                                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{ marginRight: '5px' }}>
                                        <polyline points="3 6 5 6 21 6"></polyline>
                                        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                        <line x1="10" y1="11" x2="10" y2="17"></line>
                                        <line x1="14" y1="11" x2="14" y2="17"></line>
                                    </svg>
                                    Delete
                                </button>
                            </div>
                        )}
                    </div>)
                }

                <div className="tabs-container">{tabs}</div>
                <div className="user-info" style={{ marginTop: '10px' }}>
                    <img src={user.profilePic !== null ? user.profilePic : ' /assets/images/vector-34@2x.png'} alt={`${user.firstName} ${user.lastName}`} className="user-profile-pic" />
                    <div className="user-details">
                        <div className="user-name">{user.firstName} {user.lastName}</div>
                        <div className="story-time">{formatDistanceToNow(new Date(currentStory.createdAt), { addSuffix: true })}</div>
                    </div>
                </div>
                <div className="content-display" >
                    {currentStory.video ? (
                        <video className="video" src={currentStory.video} autoPlay controls={false}></video>
                    ) : (
                        <img className="img" src={currentStory.image} alt="Story content" />
                    )}
                </div>
                <div className="like-and-viewers">
                    {
                        !myStories &&
                        (
                            <>
                            {console.log('likeddd: ',myStories)}
                            <LikeIcon liked={liked} className='like-icon' onClick={handleLikeClick} />
                            </>
                            
                        )
                    }
                    {
                        myStories &&
                        (
                            <svg className="view-icon"
                                onClick={(e) => {
                                    setViewersVisible(!viewersVisible)
                                    e.stopPropagation();
                                }}
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round">
                                <path d="M1,12 C1,12 5,4 12,4 C19,4 23,12 23,12 C23,12 19,20 12,20 C5,20 1,12 1,12 Z"></path>
                                <circle cx="12" cy="12" r="3"></circle>
                            </svg>
                        )
                    }
                    {
                        !myStories &&
                        (
                            <svg className='share-icon'
                                width="30px"
                                height="30px"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                stroke="#000000"
                                transform="matrix(1, 0, 0, 1, 0, 0)">
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                    <path d="M15.4306 7.70172C7.55045 7.99826 3.43929 15.232 2.17021 19.3956C2.07701 19.7014 2.31139 20 2.63107 20C2.82491 20 3.0008 19.8828 3.08334 19.7074C6.04179 13.4211 12.7066 12.3152 15.514 12.5639C15.7583 12.5856 15.9333 12.7956 15.9333 13.0409V15.1247C15.9333 15.5667 16.4648 15.7913 16.7818 15.4833L20.6976 11.6784C20.8723 11.5087 20.8993 11.2378 20.7615 11.037L16.8456 5.32965C16.5677 4.92457 15.9333 5.12126 15.9333 5.61253V7.19231C15.9333 7.46845 15.7065 7.69133 15.4306 7.70172Z" fill="#ffffff" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round"></path>
                                </g>
                            </svg>
                        )
                    }
                    {
                        currentStory.caption &&
                        (
                            <p className='caption-text' style={{ color: 'white', fontWeight: 'bold' }} >
                                {currentStory.caption}
                            </p>
                        )
                    }
                </div>
                {viewersVisibles && viewersVisible && (
                    <div className="viewers-panel  ">
                        <div className="viewers-head "  >
                            <p>viewers : {viewersVisibles.length}</p>
                            <hr className="hr" />
                        </div>
                        
                        <div className="viewers-list" >
                            {viewersVisibles.map(viewer => (
                                <div key={viewer.id} className="viewer">
                                    <img src={viewer.user.profilePic ? viewer.user.profilePic : ' /assets/images/vector-34@2x.png'} alt={`${viewer.user.firstName} ${viewer.user.lastName}`} className="viewer-profile-pic" />
                                    <span className="viewer-name">{viewer.user.firstName} {viewer.user.lastName}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
    return ReactDOM.createPortal(modalContent, document.body);
};

export default LightboxComponent;

