import React, { useState, useEffect, useContext } from 'react';
import { Redirect, useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAndSetUserDetails } from '../../store/actions/authActions';
import AuthServices from '../../services/auth.services';
import GroupService from '../../services/GroupService';
import Layout from '../LayoutComponent';
import { testScript } from '../js/script';
import fileStorage from '../../config/fileStorage';
import Grpicon from '../../assets/images/grpicon.png'

function GroupComponent() {
	const userDetails = useSelector(state => state.auth.userDetails?.data);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		dispatch(fetchAndSetUserDetails(AuthServices.getCurrentUser().id));
	}, [dispatch]);

	const [ownedGroups, setOwnedGroups] = useState([]);
	const [memberGroups, setMemberGroups] = useState([]);

	const [searchedGroups, setSearchedGroups] = useState([]);

	const [myGroups, setMyGroups] = useState([]);
	const [searchedMyGroups, setSearchedMyGroups] = useState([]);

	const [showComp, setShowComp] = useState("allgroups");
	const [showCompMiddle, setShowCompMiddle] = useState("manageGroups");


	const getMyGroups = async () => {
		try {
			const [memberGroupsResponse, ownerGroupsResponse] = await Promise.all([
				GroupService.getMemberGroups(), 
				GroupService.getGroupsOfOwner() 
			]);

			setOwnedGroups(ownerGroupsResponse.data);
			setMemberGroups(memberGroupsResponse.data);
		} catch (error) {
			console.error("Error fetching groups:", error);
		}
	}



	const handleSearchAllGroup = async (event) => {
		const keyword = event.target.value;
		try {
			const response = await GroupService.searchGroups(keyword);
			setSearchedGroups(response.data); 
		} catch (error) {
			console.error("Error searching groups:", error);
		}
	}

	const handleShowComp = () => {
		if (showComp === "allgroups") {
			return showAllGroupsComponent()
		}
		else if (showComp === "mygroups") {
			return showMyGroupsComponent()
		}
	}

	const handleShowCompMiddle = () => {
		if (showCompMiddle === "manageGroups") {
			return showMyManageGroups()
		}
		else if (showCompMiddle === "inmygroups") {
			return showInMyGroups()
		}
	}


	function truncateText(text, maxLength) {
		if (text.length > maxLength) {
			return text.substring(0, maxLength) + '...';
		}
		return text;
	}
	useEffect(() => {
		if (userDetails && userDetails.id) { 
			getMyGroups();
		}
	}, [showComp, userDetails]);

	useEffect(() => {
		testScript()
	}, [])

	const resetSearchAndGroups = () => {
		const searchInputs = document.querySelectorAll('.friend-search');
		searchInputs.forEach(input => {
			input.value = "";
		});
	
		setSearchedMyGroups(memberGroups);
		setSearchedMyGroups(ownedGroups); 
	};

	const showMyManageGroups = () => {
		return (
			<div className="tab-pane active fade show">
				<ul className="nearby-contct" style={{ marginTop: '15px' }}>
					{ownedGroups.map((group) => (
						<li key={group.id} className="friends-card groupalign">
							<a href={`/groups/${group.id}`}>
							<div className="group-li-item">
									<div className="item12">
										<img src={group.imageUrl ? group.imageUrl : Grpicon} alt="" className={group.imageUrl ? "img" : "no-img"} />
									</div>
									<div className="item23">
										<p className="grpnametag" style={{ height: '20px', fontWeight: '600', marginBottom: '10%' }}>
											<a href={`/group/id/${group.id}`} title="#">{group.name}</a>
										</p>
											<p >{truncateText(group.description, 30)}</p>
									</div>
									<div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
										<div className="button" style={{ color: "#000000", background: '#EAEAEA', fontSize: '12px', width: '90%', padding: '5px', fontWeight: '600' }}>Preview</div>

									</div>
								</div>
							</a>
						</li>
					))}
				</ul>
				<div className="lodmore">
					<button className="btn-view btn-load-more" onClick={resetSearchAndGroups}>Reset Search</button>
				</div>
			</div>
		);
	};


	const showInMyGroups = () => {
		return (
			<div className="tab-content">
				<div className="tab-pane active fade show" id="">
					<ul className="nearby-contct" style={{ marginTop: '15px' }}>
						{memberGroups.map((group) => (
							<li key={group.id} className="friends-card groupalign">
								<a href={`/groups/${group.id}`}>
								<div className="group-li-item">
									<div className="item12">
										<img src={group.imageUrl ? group.imageUrl : Grpicon} alt="" className={group.imageUrl ? "img" : "no-img"} />
									</div>
									<div className="item23">
										<p className="grpnametag" style={{ height: '20px', fontWeight: '600', marginBottom: '10%' }}>
											<a href={`/groups/${group.id}`} title="#">{group.name}</a>
										</p>
											<p >{truncateText(group.description, 30)}</p>
									</div>
									<div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
										<div className="button" style={{ color: "#000000", background: '#EAEAEA', fontSize: '12px', width: '90%', padding: '5px', fontWeight: '600' }}>Preview</div>

									</div>
								</div>
								</a>
							</li>
						))}
					</ul>
					<div className="lodmore">
						<button className="btn-view btn-load-more" onClick={resetSearchAndGroups}>Reset Search</button>
					</div>
				</div>
			</div>
		);
	};

	const showAllGroupsComponent = () => {
		return (
			<div className="tab-content">
				<div className="friends-search-container grp-search">
					<input
						className="friend-search"
						type="text"
						id="header-search"
						placeholder="Search Groups"
						name="s"
						onChange={handleSearchAllGroup}
						style={{ width: '100%', marginLeft: '0' }}
					/>
				</div>
				<div className="tab-pane active fade show" id="">
					<ul className="nearby-contct" style={{ marginTop: '15px' }}>
						{searchedGroups.map((group) => (
							<li key={group.id} className="friends-card groupalign">
								<a href={`/groups/${group.id}`}>
									<div className="group-li-item">
										<div className="item12">
											<a href={`/groups/${group.id}`} title="#">
												<img src={group.imageUrl ? fileStorage.baseUrl + group.imageUrl : Grpicon} alt="" className={group.imageUrl ? "img" : "no-img"} />
											</a>
										</div>
										<div className="item23">
											<p className="grpnametag" style={{ height: '20px', fontWeight: '600' }}>
												<a href={`/groups/${group.id}`} title="#">{group.name}</a>
											</p>
											<p style={{ marginTop: '10%', marginBottom: '5%' }}>{truncateText(group.description, 20)}</p>
											<div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
													<div className="button" style={{ color: "#000000", background: '#EAEAEA', fontSize: '12px', width: '90%', padding: '5px', fontWeight: '600' }}>Preview</div>
												
											</div>
										</div>
									</div>
								</a>
							</li>
						))}
					</ul>
					<div className="lodmore">
						<button className="btn-view btn-load-more" onClick={resetSearchAndGroups}>Reset Search</button>
					</div>
				</div>
			</div>
		);
	};

	const showMyGroupsComponent = () => {
		return (
			<div className="tab-content">
				<div class="friends-search-container grp-search" >
					<ul class="nav nav-pills swap-page-nav " role="tablist">
						<li class="nav-item" style={{ justifyContent: 'flex-start' }}>
							<div className="all">
								<span style={{ cursor: 'pointer' }} onClick={() => setShowCompMiddle("manageGroups")}>
									<span style={{ padding: '5px' }}>
										<i class="las la-users" style={{ fontSize: '20px' }}></i>
									</span>
									Manage Groups
								</span>
							</div>
						</li>
						<li class="nav-item" style={{ justifyContent: 'flex-end' }}>
							<div className="new">
								<span style={{ cursor: 'pointer' }} onClick={() => setShowCompMiddle("inmygroups")}>
									<span style={{ padding: '5px' }}>
										<i class="las la-user-friends" style={{ fontSize: '20px' }}></i>
									</span>
									My Groups
								</span>
							</div>
						</li>
					</ul>
					{handleShowCompMiddle()}
				</div>

			</div>
		)
	}

	return (
		<Layout user={userDetails}>
				<div className="central-meta swap-pg-cont">
					<div className="frnds">
						<div>
							<p className="Friends-Title">Groups</p>
							<i style={{ float: "right", fontSize: 20 }} class="fas fa-ellipsis-v"></i>
						</div>
					</div>
					<div class="navContent">
						<ul class="nav nav-pills swap-page-nav " role="tablist">
							<li class="nav-item" style={{ justifyContent: 'flex-start' }}>
								<div className="all">
									<span style={{ cursor: 'pointer' }} onClick={() => setShowComp("allgroups")}>
										<span style={{ padding: '5px' }}>
											<i class="las la-users" style={{ fontSize: '20px' }}></i>
											{/* <span>{`${following.length}`}</span> */}
										</span>
										All Groups
									</span>
								</div>
							</li>
							<li class="nav-item" style={{ justifyContent: 'center' }}>
								<div className="my">
									<span style={{ cursor: 'pointer' }} onClick={() => setShowComp("mygroups")}>
										<span style={{ padding: '5px' }}>
											<i class="las la-user-friends" style={{ fontSize: '20px' }}></i>
											{/* <span>{`${following.length}`}</span> */}
										</span>
										My Groups
									</span>
								</div>
							</li>
							<li class="nav-item" style={{ justifyContent: 'flex-end' }}>
								<div className="new">
									<span style={{ cursor: 'pointer' }} onClick={() => navigate('/group/create')}>
										<span style={{ padding: '5px' }}>
											<i class="las la-user-friends" style={{ fontSize: '20px' }}></i>
											{/* <span>{`${following.length}`}</span> */}
										</span>
										Create group
									</span>
								</div>
							</li>
						</ul>

					</div>

					{handleShowComp()}
				</div>
		</Layout>
	);
}
export default GroupComponent;