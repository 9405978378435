import React from 'react'

export default function Loading() {
    return (
        <div>
            <div style={{
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                display: 'flex',
                justifyContent: 'center',
            }}>
                <div className="loader"></div>
            </div>
        </div>
    )
}
