import React from 'react';
import ReactDOM from 'react-dom';
import '../../assets/css/confirmModalStyles.css';

const ConfirmModal = ({ isOpen, title, message, onClose, onConfirm, isAlert = false }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="modalOverlay-confirm">
      <div className={`modalContent-confirm ${isAlert ? 'alertMode' : ''}`}> 
        <h2>{title}</h2>
        <p>{message}</p>
        {isAlert ? (
          <button className="button-confirm" onClick={onClose}>OK</button> // Tek buton (OK)
        ) : (
          <>
            <button className="button-confirm" onClick={onConfirm}>Yes</button>
            <button className="cancelButton-confirm" onClick={onClose}>No</button>
          </>
        )}
      </div>
    </div>,
    document.body
  );
};

export default ConfirmModal;
