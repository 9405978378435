import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import '../../assets/css/inviteModalStyles.css';
import FriendService from '../../services/FriendService';
import GroupService from '../../services/GroupService';

const InviteFriendsModal = ({ isOpen, groupId, onClose, userId, groupMembers }) => {
    const [userList, setUserList] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        const fetchFollowersAndFollowings = async () => {
            try {
                const [followers, followings] = await Promise.all([
                    FriendService.getFollowers(userId),
                    FriendService.getFollowings(userId)
                ]);
                const combinedUsers = [...followers, ...followings];
                const uniqueUsers = Array.from(new Set(combinedUsers.map(user => user.id)))
                    .map(id => combinedUsers.find(user => user.id === id));

                const filteredUsers = uniqueUsers.filter(user => !groupMembers.some(member => member.id === user.id));


                setUserList(filteredUsers);
            } catch (error) {
                console.error('Error fetching followers and followings:', error);
            }
        };

        if (isOpen) {
            fetchFollowersAndFollowings();
        }
    }, [isOpen, userId]);

    if (!isOpen) return null;

    const handleInvite = async (user) => {
        try {
            const response = await GroupService.inviteToJoin(groupId, user.id);

            if (response.status === 204) {
                console.log('User already invited or a member');
                setUserList(userList.map(u => u.id === user.id ? { ...u, isInvited: true } : u));
            } else {
                setUserList(userList.map(u => u.id === user.id ? { ...u, isInvited: true } : u));
            }
        } catch (error) {
            console.error('Error inviting user:', error);
        }
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredUsers = userList.filter(user =>
        `${user.firstName} ${user.lastName}`.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return ReactDOM.createPortal(
        <div className="modal-overlay-invite">
            <div className="modal-content-invite">
                <h2>Invite Friends</h2>
                <div className="friends-search-container-invite">
                    <input
                        className="friend-search-invite"
                        type="text"
                        placeholder="Search Users"
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                </div>
                <div className="scroll-container-invite">
                    <ul className="nearby-contact-invite" style={{ marginTop: '15px' }}>
                        {filteredUsers.length > 0 ? (
                            filteredUsers.slice(0, 10).map(user => (
                                <li key={user.id} className="friends-card-invite">
                                    <div className="card-content-invite">
                                        <div className="profile-pic-invite">
                                            <a href={`/profile/${user.id}`} title={`${user.id}`}>
                                                <img style={{ objectFit: 'cover' }} src={user.profilePic || '../../assets/images/unnamed.png'} alt="" />
                                            </a>
                                        </div>
                                        <div className="user-info-invite">
                                            <p className="name-tag-invite">
                                                <a href={`/profile/${user.id}`} title={`${user.id}`}>{`${user.firstName} ${user.lastName}`}</a>
                                            </p>
                                        </div>
                                        <div className="invite-button-invite">
                                            <button
                                                className='invite-toggle-button'
                                                onClick={() => handleInvite(user)}
                                            >
                                                {user.isInvited
                                                    ? 'Invited'
                                                    : 'Invite'}
                                            </button>
                                        </div>
                                    </div>
                                </li>
                            ))
                        ) : (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', minHeight: '100px' }}>
                                <h2>No Users Found</h2>
                            </div>
                        )}
                    </ul>
                </div>
                <button className="close-button-invite" onClick={onClose}>Close</button>
            </div>
        </div>,
        document.body
    );
};

export default InviteFriendsModal;
