import React from 'react';
import ReactDOM from 'react-dom';
import '../../assets/css/confirmModalStyles.css';

const ConfirmModal = ({ isOpen, title, message, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="modalOverlay-confirm">
      <div className="modalContent-confirm">
        <h2>{title}</h2>
        <p>{message}</p>
        <button className="button-confirm" onClick={onConfirm}>Yes</button>
        <button className="cancelButton-confirm" onClick={onClose}>No</button>
      </div>
    </div>,
    document.body
  );
};

export default ConfirmModal;
