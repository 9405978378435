import React from "react";

const Separator = () => {
  return <div className="h-[1px] w-full bg-gray-300 my-4"></div>;
};

const PrivacyPolicy = () => {
  return (
    <div>
      <p className="p-4 text-center text-black font-bold text-2xl">
        CLARIFICATION TEXT ON THE PROTECTION OF PERSONAL DATA IN ACCORDANCE WITH
        THE LAW ON THE PROTECTION OF PERSONAL DATA NO. 6698 AND THE GENERAL DATA
        PROTECTION REGULATION OF THE EUROPEAN UNION
      </p>
      <Separator />
      <p className="p-2 text-gray-800 text-xl">
        In accordance with Article 10 titled "Disclosure Obligation of the Data
        Controller" of the Personal Data Protection Law No. 6698 ("Law"), which
        aims to protect the fundamental rights and freedoms of individuals,
        especially the privacy of private life, in the processing of personal
        data, the Communiqué on the Procedures and Principles to be Followed in
        the Fulfillment of the Disclosure Obligation and the European Union
        General Data Protection Regulation No. 2016/679 ("GDPR"), DDS Teknoloji
        A.Ş. As the Data Controller, we aim to inform you about your personal
        data processed by the Data Controller and given below. DDS Technology
        Inc. It takes all technical and administrative measures to ensure the
        appropriate level of security in order to prevent unlawful processing,
        access and preservation of your personal data.
      </p>
      <div className="p-2">
        <p className="p-2 text-gray-800 text-xl font-bold">
          1. PROCESSED PERSONAL DATA
        </p>
        <p className="p-2 text-gray-900 text-xl space-y-4">
          <span className="block">
            Personal data refers to any information relating to an identified or
            identifiable person. Your processed personal data and the categories
            of data are as follows:
          </span>
          <span className="block">
            <span className="font-bold">Identity Information: </span> Name,
            surname, date of birth and gender information
          </span>
          <span className="block">
            <span className="font-bold">Contact Information: </span> Phone
            number, contact address and e-mail address
          </span>
          <span className="block">
            <span className="font-bold">Location Information: </span> Location
            information
          </span>
          <span className="block">
            <span className="font-bold">Marketing Information: </span> Campaign
            information
          </span>
          <span className="block">
            <span className="font-bold">Usage Information: </span>{" "}
            Transactional, in-app activity and cookie information
          </span>
          <span className="block">
            <span className="font-bold">
              Transaction Security Information:{" "}
            </span>{" "}
            Username, password, IP information and access records
          </span>
          <span className="block">
            <span className="font-bold">Device Information: </span> Device brand
            model, device operating system, operating system version, and screen
            resolution information
          </span>
          <span className="block">
            <span className="font-bold">Crypto wallet number: </span> The crypto
            wallet number that the user connects with Shareup to create NFTs.
          </span>
        </p>
      </div>
      <Separator />
      <div className="p-2">
        <p className="p-2 text-gray-800 text-xl font-bold">
          2. PURPOSES OF PROCESSING PERSONAL DATA
        </p>
        <p className="p-2 text-gray-900 text-xl space-y-4">
          <span className="block">
            Your personal data; It may be processed for the following purposes
            in accordance with the processing conditions and secondary
            regulations specified in Articles 5 and 6 of the Law and the matters
            specified in Article 5 titled "Principles regarding the processing
            of personal data" and Article 6 titled "Lawfulness of the processing
            activity" of the GDPR:
          </span>
          <span className="block">• Provision of products and services,</span>
          <span className="block">
            • Realization of your requests, finalization of your complaints,
            informing,
          </span>
          <span className="block">
            • Conducting surveys/analysis/statistical studies focused on
            measuring customer satisfaction, researching customer trends and
            increasing service quality,
          </span>
          <span className="block">
            • Offering
            prizes/sweepstakes/contests/gifts/thanks/celebrations/reminders
            focused on ensuring customer satisfaction,
          </span>
          <span className="block">
            • Marketing activities such as advertising, targeted advertising,
            campaigns, offer presentation for commercial purposes,
            diversification of products and services, performance
            measurement/rating operations and communication activities,{" "}
          </span>
          <span className="block">
            • Detection and prevention of all kinds of malicious uses (such as
            virus attacks and cyber attacks),
          </span>
          <span className="block">
            • Ensuring contractual requirements and financial reconciliation
            regarding the products and services offered with our business
            partners or other third parties,{" "}
          </span>
          <span className="block">
            • Sharing information/documents requested by regulatory and
            supervisory institutions, official authorities, fulfilling the legal
            obligations in the relevant legislation, audit activities, legal
            follow-up and execution of legal processes
          </span>
          <span className="block">• Measurement of service quality,</span>
          <span className="block">
            • Performing user statistics and anonymous user segmentation,{" "}
          </span>
          <span className="block">
            • Conducting analysis studies for product and business development,{" "}
          </span>
        </p>
      </div>
      <Separator />
      <div className="p-2">
        <p className="p-2 text-gray-800 text-xl font-bold">
          3. TRANSFER OF PERSONAL DATA
        </p>
        <p className="p-2 text-gray-900 text-xl space-y-4">
          <span className="block">
            Your personal data may be transferred to the following 3rd parties
            in accordance with the provisions of the Law regarding the transfer
            of personal data domestically and internationally within the scope
            of the following legislation and purposes:
          </span>
          <span className="block">
            • If you give your explicit consent, your subscription, location and
            usage data will be sent to Data Kritik Teknoloji Tic.Lmt.Şti. and
            Deeptech Mühendsilik Tic.Lmt.Şti. and all other group companies on
            the www.shareupx.com.tr website.{" "}
          </span>
          <span className="block">
            • It can be shared with the companies that we have authorized,
            operating on behalf of and on behalf of our Company, and our
            representatives.
          </span>
          <span className="block">
            • In order to meet the demands of the regulatory supervisory
            authority, it can be shared with the regulatory and supervisory
            institutions, the competent authorities that will determine your
            location in case of an emergency call, and the public institutions
            or organizations that are expressly authorized to request your
            personal data in the laws to which they are subject.
          </span>
          <span className="block">
            • It can be shared with business partnerships, supplier and
            contractor companies, payment and e-money institutions, banks,
            credit risk and financial institutions, and mandatory persons,
            institutions and organizations related to legal follow-up processes.
          </span>
          <span className="block">
            • Your personal data required for the execution of legal
            transactions can be shared with law firms, courts, consumer
            arbitration committees.
          </span>
          <span className="block">
            • In addition , DDS Technology Inc. We can share your posts with our
            other social media accounts or media organizations with which we
            have a sponsorship relationship.
          </span>
        </p>
      </div>
      <Separator />
      <div className="p-2">
        <p className="p-2 text-gray-800 text-xl font-bold">
          4. METHODS OF COLLECTING PERSONAL DATA AND LEGAL REASONS
        </p>
        <p className="p-2 text-gray-900 text-xl space-y-4">
          <span className="block">
            Your personal data; During the establishment of our legal
            relationship with the relevant persons and during the continuation
            of the said relationship, it can be obtained by automatic methods
            through our digital channels consisting of our website and mobile
            application and processed within the legal reasons stated below.
          </span>
          <span className="block">• Having your explicit consent,</span>
          <span className="block">
            • It has been made public by the person concerned,
          </span>
          <span className="block">• It is clearly stipulated in the laws,</span>
          <div className="px-6 space-y-2">
            <span className="block">
              - It is clearly stipulated in the laws,
            </span>
            <span className="block">
              -Law No. 5651 on the Regulation of Publications Made on the
              Internet and Combating Crimes Committed Through These Publications{" "}
            </span>
            <span className="block">
              -Law No. 5846 on Intellectual and Artistic Works
            </span>
            <span className="block">
              -Turkish Code of Obligations No. 6098 and Turkish Commercial Code
              No. 6102
            </span>
            <span className="block">
              -Regulation No. 29232 on Commercial Advertising and Unfair
              Commercial Practices{" "}
            </span>
          </div>
          <span className="block">
            • Provided that it is directly related to the establishment or
            performance of a contract, it is necessary to process the personal
            data of the parties to the contract, to be able to provide the
            requested products and services and to fulfill the requirements of
            the contracts you have concluded,
          </span>
          <span className="block">
            • It is mandatory for the fulfillment of the legal obligation,
          </span>
          <span className="block">
            • Data processing is mandatory for the establishment, exercise or
            protection of a right,{" "}
          </span>
          <span className="block">
            • Provided that it does not harm the fundamental rights and freedoms
            of the data subject, data processing is mandatory for the legitimate
            interests of the data controller.
          </span>
          <span className="block">
            • In case of actual impossibility, you are unable to disclose your
            consent or data processing is mandatory for the protection of the
            life or bodily integrity of the person or someone else whose consent
            is not legally valid.
          </span>
        </p>
      </div>
      <Separator />
      <div className="p-2">
        <p className="p-2 text-gray-800 text-xl font-bold">
          5. YOUR RIGHTS REGARDING THE PROTECTION OF YOUR PERSONAL DATA
        </p>
        <p className="p-2 text-gray-900 text-xl space-y-4">
          <span className="block">
            Pursuant to Article 11 of the Law titled "Rights of the person
            concerned", which regulates the rights of the person concerned:
          </span>
          <span className="block">
            • To learn whether your personal data is processed or not,{" "}
          </span>
          <span className="block">
            • If your personal data has been processed, obtaining information
            about it,{" "}
          </span>
          <span className="block">
            • To learn the purpose of processing your personal data and whether
            they are used in accordance with their purpose,
          </span>
          <span className="block">
            • To know the third parties to whom your personal data is
            transferred domestically or abroad,
          </span>
          <span className="block">
            • Requesting correction of personal data if it is incomplete or
            incorrectly processed,
          </span>
          <span className="block">
            • Requesting the deletion or destruction of your personal data
            within the framework of the conditions stipulated in Article 7 of
            the Law,
          </span>
          <span className="block">
            • Requesting notification of the transactions made in accordance
            with your above-mentioned rights of correction, deletion and
            destruction to third parties to whom your personal data has been
            transferred,
          </span>
          <span className="block">
            • Objecting to this result in the event that a result arises against
            you by analyzing your processed personal data exclusively with
            automated systems,
          </span>
          <span className="block">
            • If you suffer damage due to the unlawful processing of your
            personal data, you have the right to demand compensation for your
            damage.
          </span>
        </p>
      </div>
      <Separator />
      <div className="p-2 mt-4">
        <p className="p-2 text-gray-800 text-2xl">
          If you wish to exercise your rights listed above, you may submit your
          application within this scope in accordance with the conditions
          specified in the Communiqué on the Procedures and Principles of
          Application to the Data Controller ("Communiqué") in order to
          determine whether the application belongs to you and thus protect your
          rights:
          <br />
          <br />
          <br />
          You can send it to our addresses listed below by mail (identity
          confirmation will be required) or through a notary public, or you can
          send your verified e-mail address registered in our systems to our
          e-mail/KEP addresses specified below by using a registered e mail
          (KEP) address, secure electronic signature or mobile signature.
          Applications to be made within this scope will be accepted following
          the identity verification to be made by us, and your requests will be
          finalized as soon as possible and within 30 days at the latest,
          depending on the nature of the request
        </p>
        <p className="p-2 text-gray-900 text-xl space-y-4 mt-8">
          <span className="block">
            Your rights under Article 15 of the GDPR entitled "Rights of the
            data subject" are as follows:
          </span>
          <div className="px-6">
            <span className="block">• Right of access</span>
            <span className="block">• Right to rectification</span>
            <span className="block">
              • Right to erasure ("Right to be forgotten"){" "}
            </span>
            <span className="block">• Right to restriction of processing </span>
            <span className="block">• Right to data portability </span>
            <span className="block">• Right to object </span>
          </div>
        </p>
      </div>
      <Separator />
      <div className="p-2">
        <p className="p-2 text-gray-800 text-xl font-bold">
          6. APPLICATION CHANNELS and ADDRESSES
        </p>
        <p className="p-2 text-gray-900 text-xl space-y-4">
          <span className="block font-bold">DDS Technology Inc.</span>
          <span className="block font-bold">
            Direct Application and Notary/Postal Channel: TEKNOPARKİSTE
            İskenderun/Hatay
          </span>
          <span className="block">
            <span className="font-bold">Email:</span> bilgi@ddsteknoloji.com
          </span>
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
