import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAndSetUserDetails } from '../../../../store/actions/authActions';
import AuthServices from '../../../../services/auth.services';
import post from '../../../../services/post';
import shortid from 'shortid';
import ConfirmModal from '../../../profile/ConfirmModal';




export default function HangShare({ hangshare }) {



  const [activeIndex, setActiveIndex] = useState(0);
  const [modalState, setModalState] = useState({
    isOpen: false,
    title: '',
    message: '',
    onConfirm: null, // Onay fonksiyonu (isteğe bağlı)
    isAlert: false, // Uyarı modu mu (isteğe bağlı)
  });


  const userDetails = useSelector(state => state.auth.userDetails?.data);
  const dispatch = useDispatch();

  const handleDotClick = (index) => {
    setActiveIndex(index);
  };
  const handleRedeemClick = async (index) => {
    await dispatch(fetchAndSetUserDetails(AuthServices.getCurrentUser().id));
    try {
      if (index.quantity <= 0) {
        
        setModalState({
          isOpen: true,
          title: "Insufficient product",
          message: "Not enough Products",
          isAlert: true, 
        });
      }
      else if (userDetails && index.price <= userDetails.shareupCoins) {
        setModalState({
          isOpen: true,
          title: "Buy Product",
          message: "Are you sure you want to buy this product?",
          onConfirm: () => {
            const code = shortid.generate();
            let isLastItemInPost = false; // // if only one item is left, set post to inactive because it will be out of stock after this redemption

            post.redeemHangShareItem(index.postItemId, {
              code: code,
              price: index.price,
              postId: post.id,
              status: isLastItemInPost ? 'inactive' : null,
            })
              .catch(err => {
                console.log("Redeem Item Error", err)
              });
              setModalState(prev => ({ ...prev, isOpen: false })); 

          },
        });


      } else {        
        setModalState({
          isOpen: true,
          title: "Insufficient Balance",
          message: "You do not have enough Share Up Coins",
          isAlert: true, 
        });


      }
    } catch (error) {
      console.log("handleRedeemClick error", error);
    }
  };

  const activeShare = hangshare[activeIndex]


  return (
    <div>

      <div className='postImage ' style={{ minHeight: '250px', height: 'auto', justifyContent: 'center', alignItems: 'center' }}>
        <img src={activeShare ? activeShare.providerImageUrl : ''} className='image-fuild mt-5' style={{ maxHeight: '80px', overflow: 'hidden', marginLeft: '41%' }} height={80} width={100} alt="" />
        <div style={{
          boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '10px',
          marginTop: '20px',
          backgroundColor: '#fff',
          borderRadius: '8px'
        }}>

          <div style={{ marginRight: '20px' }}>
            <img src={activeShare ? activeShare.providerItemImageUrl : ''} alt="" style={{ maxHeight: '80px', overflow: 'hidden' }} height={80} width={100} className='image-fuild' />
          </div>

          <div style={{ textAlign: 'left' }}>
            <div style={{ fontWeight: 'bold', marginBottom: '11px' }}>{activeShare ? activeShare.providerItemName : ''}</div>
            <div>{activeShare ? `Quantity: ${activeShare.quantity}` : ''}</div>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }} >
              <img src="/assets/images/icon2.png" width={20} height={20} />
              <p style={{ marginLeft: '5px', color: 'black ' }} > {activeShare ? activeShare.price : ''} Shareup Coins </p>
            </div>
          </div>
          <div style={{ marginLeft: 'auto' }}>
            <button
              onClick={() => handleRedeemClick(activeShare)}
              style={{
                width: '120px',
                height: '40px',
                borderRadius: '15px',
                border: 'none',
                color: 'white',
                fontWeight: 'bold',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#044f66'
              }} >Redeem Item</button>
          </div>
        </div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
        {hangshare.map((item, index) => (
          <button
            key={index}
            onClick={() => handleDotClick(index)}
            style={{
              width: '15px',
              height: '15px',
              borderRadius: '50%',
              backgroundColor: activeIndex === index ? '#044f66' : 'gray',
              margin: '0 5px',
              border: 'none',
              cursor: 'pointer',
              marginBottom: '10px'
            }}
          />
        ))}
      </div>
      <ConfirmModal
        isOpen={modalState.isOpen}
        title={modalState.title}
        message={modalState.message}
        onConfirm={modalState.onConfirm}
        onClose={() => setModalState(prev => ({ ...prev, isOpen: false }))}
        isAlert={modalState.isAlert}
      />
    </div>
  );
}
