import React, { useState } from 'react'
import AddPost from './AddPost';
import CreateSwapPost from './CreateSwapPost';
import CreateHangSharePost from './CreateHangSharePost';
import CreateShareUpPost from './CreateShareUpPost';




export default function NewPost({ user, state, dispatch , refresh }) {

  return (
    <div>
      <div className='central-meta newsfeed'>
        <div className='new-postbox'>
          <figure>
            <img
              src={
                user.profilePic ? user.profilePic : '/assets/images/vector-34@2x.png'
              }
              alt=''
              style={{ backgroundColor: '#033347' }}
            />
          </figure>
          <div className='newpst-input'>
           
              <AddPost user={user} state={state} dispatch={dispatch} refresh={refresh} />
          </div>
          <div className='attachments'>
            <ul>
              <li><CreateHangSharePost user={user} state={state} dispatch={dispatch} refresh={refresh} /></li>
              <li><CreateShareUpPost user={user} state={state} dispatch={dispatch} refresh={refresh} /></li>
              <li><CreateSwapPost user={user} state={state} dispatch={dispatch} refresh={refresh} /></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
