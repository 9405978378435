import React, { useState } from 'react';

const SocialModal = ({ setSocialModelOpen, updateProfile, facebook, setFacebook, instagram, setInstagram, twitter, setTwitter, linkedin, setLinkedin, pinterest, setPinterest }) => {
    const baseUrls = {
        facebook: "https://facebook.com/",
        instagram: "https://instagram.com/",
        twitter: "https://x.com/",
        linkedin: "https://linkedin.com/in/",
        pinterest: "https://pinterest.com/"
    };
    const [localFacebook, setLocalFacebook] = useState(baseUrls.facebook);
    const [localInstagram, setLocalInstagram] = useState(baseUrls.instagram);
    const [localTwitter, setLocalTwitter] = useState(baseUrls.twitter);
    const [localLinkedin, setLocalLinkedin] = useState(baseUrls.linkedin);
    const [localPinterest, setLocalPinterest] = useState(baseUrls.pinterest);

    const handleContinue = async () => {

        setFacebook(localFacebook);
        setInstagram(localInstagram);
        setTwitter(localTwitter);
        setLinkedin(localLinkedin);
        setPinterest(localPinterest);

        console.log("adsadsad", localFacebook);
        console.log("instagrammm", facebook)

        updateProfile();
};

    const handleInput = (setFunction, base, event) => {
        const { value } = event.target;
        if (!value.startsWith(base)) {
            setFunction(base + value.substring(base.length));
        } else {
            setFunction(value);
        }
    };


    return (
        <div className="modalContainer">
        <div className="input-container">
            <input
                className="social-media-input"
                value={facebook}
                onChange={(e) => handleInput(setFacebook, baseUrls.facebook, e)}
                placeholder="Facebook URL"
            />
            <input
                className="social-media-input"
                value={instagram}
                onChange={(e) => handleInput(setInstagram, baseUrls.instagram, e)}
                placeholder="Instagram URL"
            />
            <input
                className="social-media-input"
                value={twitter}
                onChange={(e) => handleInput(setTwitter, baseUrls.twitter, e)}
                placeholder="Twitter URL"
            />
            <input
                className="social-media-input"
                value={linkedin}
                onChange={(e) => handleInput( setLinkedin, baseUrls.linkedin, e)}
                placeholder="LinkedIn URL"
            />
            <input
                className="social-media-input"
                value={pinterest}
                onChange={(e) => handleInput(setPinterest, baseUrls.pinterest, e)}
                placeholder="Pinterest URL"
            />
        </div>
            <div className="footer">
                <button
                    onClick={() => setSocialModelOpen(false)}
                    id="cancelBtn"
                >
                    Cancel
                </button>
                <button onClick={handleContinue}>Continue</button>
            </div>
        </div>
    );
};

export default SocialModal;
