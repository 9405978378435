import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import '../../assets/css/followModalStyles.css';
import FriendService from '../../services/FriendService';

const FollowModal = ({ isOpen, title, users, onClose }) => {
    const [userList, setUserList] = useState(users);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        setUserList(users);
    }, [users]);

    if (!isOpen) return null;

    const handleFollowToggle = async (user) => {
        try {
            if (user.isFollowing) {
                await FriendService.unfollow(user.id);
                setUserList(userList.map(u => u.id === user.id ? { ...u, isFollowing: false } : u));
            } else {
                await FriendService.sendFollow(user.id);
                setUserList(userList.map(u => u.id === user.id ? { ...u, isFollowing: true } : u));
            }
        } catch (error) {
            console.error('Error following/unfollowing user:', error);
        }
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredUsers = userList.filter(user =>
        `${user.firstName} ${user.lastName}`.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return ReactDOM.createPortal(
        <div className="modal-overlay-follow">
            <div className="modal-content-follow">
                <h2>{title.charAt(0).toUpperCase() + title.slice(1)}</h2>
                <div className="friends-search-container-follow">
                    <input
                        className="friend-search-follow"
                        type="text"
                        placeholder="Search Users"
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                </div>
                <div className="scroll-container-follow">
                    <ul className="nearby-contact-follow" style={{ marginTop: '15px' }}>
                        {filteredUsers.length > 0 ? (
                            filteredUsers.slice(0, 10).map(user => (
                                <li key={user.id} className="friends-card-follow">
                                    <div className="card-content-follow">
                                        <div className="profile-pic-follow">
                                            <a href={`/profile/${user.id}`} title={`${user.id}`}>
                                                <img style={{ objectFit: 'cover' }} src={user.profilePic || '../../assets/images/unnamed.png'} alt="" />
                                            </a>
                                        </div>
                                        <div className="user-info-follow">
                                            <p className="name-tag-follow">
                                                <a href={`/profile/${user.id}`} title={`${user.id}`}>{`${user.firstName} ${user.lastName}`}</a>
                                            </p>
                                        </div>
                                        <div className="follow-button-follow">
                                            <button
                                                className='follow-toggle-button'
                                                onClick={() => handleFollowToggle(user)}
                                            >
                                                {user.isFollowing 
                                                    ? 'Unfollow' 
                                                    : user.isFollower 
                                                    ? 'Follow Back' 
                                                    : 'Follow'}
                                            </button>
                                        </div>
                                    </div>
                                </li>
                            ))
                        ) : (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', minHeight: '100px' }}>
                                <h2>No Users Found</h2>
                            </div>
                        )}
                    </ul>
                </div>
                <button className="close-button-follow" onClick={onClose}>Close</button>
            </div>
        </div>,
        document.body
    );
};

export default FollowModal;
