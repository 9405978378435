import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Popup from 'reactjs-popup';
export default function PopAudience({ state, dispatch }) {
  const [isOpen, setIsOpen] = useState(false);
  const [privacy, setPrivacy] = useState('public')

  const togglePopup = () => {

    setIsOpen(!isOpen);
  };
  function handleChange(e) {
    dispatch({ type: 'set_privacy', payload: e.target.value })
  }

  return (
    <Popup
      trigger={
        <span style={{ fontSize: '11px', padding: '4px', cursor: 'pointer', backgroundColor: '#0333471a', borderRadius: '5px' }}>
          {state.privacy}

          <img src="assets/images/Vector.svg"
            style={{ paddingLeft: '4px', verticalAlign: 'middle' }} />
        </span>
      }
      modal
      nested
    >
      {(close) => (
        <Form style={{ paddingRight: '11px', paddinLeft: '11px', paddingBottom: '0px' }}
          className='popwidth' onSubmit={close}>
          <div className='headpop ' style={{ padding: '0px' }}>
            <div className='row' style={{ paddingBottom: '10px', paddingtop: '10px' }}>
              <div style={{ width: '5%', paddingBottom: '10px' }}>
                <a href='#!' style={{ padding: '10px 80px 10px 0' }} onClick={togglePopup}>
                  <i class='las la-times' style={{ fontSize: '20px', background: '#C4C4C4', borderRadius: '50%' }} onClick={close}></i>
                </a>
              </div>

              <div
                style={{ color: '#000000', fontSize: '21px', fontWeight: 'bold', width: '95%', textAlign: 'center' }}

              >
                {' '}
                <span>Select Audience</span>
              </div>

            </div>

            <div className="headaudience">
              {' '}
              <span style={{ fontWeight: 'bold' }}
              >Who can see your post?</span>
              <p style={{ fontSize: '13px', paddingTop: '2px' }}>
                <p style={{ color: '#525050', fontweight: '400 !important' }}>
                  your post will apear in newsfeed, on your profile and search results</p>
              </p>
            </div>
            <div>

              <fieldset>
                <div className="form-card">
                  <ul className="nearby-contct">

                    <yi >
                      <div className="grid-containeraudience">
                        <div class="item11">

                          <img src="assets/images/publicicon.svg" style={{ width: '49%' }} />
                          {/* <img src={fileStorage.baseUrl +profilePicturePath} alt="" /> */}
                          {/* <span className="status f-online" /> */}
                        </div>
                        <div class="item22">

                          <p style={{ fontSize: '17px', fontWeight: 'bold', color: 'black' }}>
                            Public
                          </p>
                          <p style={{ fontSize: '11px', paddingTop: '1px' }}>
                            <p style={{ color: '#525050' }}>
                              anyone on or off facebook</p>
                          </p>


                        </div>

                        <input type="radio" Value="Public" name="privacy" onChange={(e) => handleChange(e)} style={{ height: '60%', width: '100%' }} />

                        {/* <a href="#!" className="button" style={{ color: "#000000", background: '#EAEAEA', fontSize: '12px' }} href="#!" onClick={("")} ></a> */}

                      </div>
                    </yi>

                    <yi>
                      <div className="grid-containeraudience">
                        <div class="item11">

                          <img src="assets/images/friendsicon.svg" style={{ width: '46%' }} />
                          {/* <img src={fileStorage.baseUrl +profilePicturePath} alt="" /> */}
                          {/* <span className="status f-online" /> */}
                        </div>
                        <div class="item22">

                          <p style={{ fontSize: '17px', fontWeight: 'bold', color: 'black' }}>
                            Friends
                          </p>

                          <p style={{ fontSize: '11px', fontweight: '300', paddingTop: '1px', color: '#525050' }}>
                            your shareup friends
                          </p>


                        </div>

                        <input type="radio" Value="Followers" name="privacy" onChange={(e) => handleChange(e)} style={{ height: '60%', width: '100%' }} />

                        {/* <a href="#!" className="button" style={{ color: "#000000", background: '#EAEAEA', fontSize: '12px' }} href="#!" onClick={("")} ></a> */}

                      </div>
                    </yi>

                    <yi >
                      <div className="grid-containeraudience">
                        <div class="item11">

                          <img src="assets/images/friendexcepticon.svg" style={{ width: '46%' }} />
                          {/* <img src={fileStorage.baseUrl +profilePicturePath} alt="" /> */}
                          {/* <span className="status f-online" /> */}
                        </div>
                        <div class="item22">

                          <p style={{ fontSize: '17px', fontWeight: 'bold', color: 'black' }}>
                            Friends except
                          </p>
                          <p style={{ fontSize: '11px', fontweight: '300', paddingTop: '1px', color: '#525050' }}>
                            don't show some friends
                          </p>

                        </div>

                        <input type="radio" Value="Followers except" name="privacy" onChange={(e) => handleChange(e)} style={{ height: '60%', width: '100%' }} />

                        {/* <a href="#!" className="button" style={{ color: "#000000", background: '#EAEAEA', fontSize: '12px' }} href="#!" onClick={("")} ></a> */}

                      </div>
                    </yi>

                    <yi  >
                      <div className="grid-containeraudience">
                        <div class="item11">

                          <img src="assets/images/groupicon.svg" style={{ width: '46%' }} />
                          {/* <img src={fileStorage.baseUrl +profilePicturePath} alt="" /> */}
                          {/* <span className="status f-online" /> */}
                        </div>
                        <div class="item22">

                          <p style={{ fontSize: '17px', fontWeight: 'bold', color: 'black' }}>
                            Group
                          </p>
                          <p style={{ fontSize: '11px', fontweight: '300', paddingTop: '1px', color: '#525050' }}>
                            select to show for group
                          </p>
                        </div>

                        <input type="radio" Value="Specific followers" name="privacy" onChange={(e) => handleChange(e)} style={{ height: '60%', width: '100%' }} />

                        {/* <a href="#!" className="button" style={{ color: "#000000", background: '#EAEAEA', fontSize: '12px' }} href="#!" onClick={("")} ></a> */}

                      </div>
                    </yi>

                    <yi  >
                      <div className="grid-containeraudience">
                        <div class="item11">

                          <img src="assets/images/onlymeicon.svg" style={{ width: '39%' }} />
                          {/* <img src={fileStorage.baseUrl +profilePicturePath} alt="" /> */}
                          {/* <span className="status f-online" /> */}
                        </div>
                        <div class="item22">
                          <p style={{ fontSize: '17px', fontWeight: 'bold', color: 'black' }}>
                            Only Me
                          </p>
                          <p style={{ fontSize: '11px', fontweight: '300', paddingTop: '1px', color: '#525050' }}>
                            private to all shareup users
                          </p>
                        </div>

                        <input type="radio" Value="Only Me" name="privacy" style={{ height: '60%', width: '100%' }} />

                        {/* <a href="#!" className="button" style={{ color: "#000000", background: '#EAEAEA', fontSize: '12px' }} href="#!" onClick={("")} ></a> */}

                      </div>
                    </yi>


                  </ul>
                </div>
              </fieldset>



            </div>
          </div>

        </Form>

      )}

    </Popup>


  )
}
       
