import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import {startNewConversation } from '../../store/actions/chatActions';

import {setUserDetails } from '../../store/actions/authActions';
import AuthServices from '../../services/auth.services';
import AppHeader from "../app/layout/AppHeader";
import "../../assets/css/profile.css";
import UserService from '../../services/user';
import Story from '../app/story/Story';
import lockimg from '../../assets/images/lock_11081444.png';
import userChat from '../../assets/images/user_chat.png';
import Posts from '../app/post/post/Posts';
import CreatePosts from '../app/post/createpost/CreatePosts'
import FriendService from '../../services/FriendService';
import defaultCoverImg from '../../assets/images/default-cover-picture.jpg'


export default function ViewFriendsComponent( ) {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();


    const [profileRender, setProfileRender] = useState(null);
    const [showProfilePicture, setShowProfilePicture] = useState(false);
    const [posts, setPosts] = useState([]);
    const [show, setShow] = useState("timeline");
    const [userDetails, setuserDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isFollowing, setIsFollowing] = useState(false);
    const [isRequestSent, setIsRequestSent] = useState(false);

    const getUserDetails = async () => {
        try {
            const user = await UserService.getUserById(id);
            setuserDetails(user.data);
        } catch (error) {
            console.error('getUserDetails failed:', error);
        }
        setLoading(false);
    };

    useEffect(() => {
        const checkFollowingStatus = async () => {
            try {
                const followings = await FriendService.getFollowings(AuthServices.getCurrentUser().id);
                const sentRequests = await FriendService.getSentFollowRequests();
                setIsFollowing(followings.some(f => f.id === id));
                setIsRequestSent(sentRequests.some(request => request.id === id));
            } catch (error) {
                console.error('Error checking follow status:', error);
            }
        };

        checkFollowingStatus();
    }, [id]);

    

    const handleFollowToggle = async () => {
        try {
            if (isFollowing) {
                await FriendService.unfollow(id);
                setIsFollowing(false);
            } else if (isRequestSent) {
                await FriendService.unfollow(id); // İstek geri çekme
                setIsRequestSent(false);
            } else if (userDetails.isPrivate) {
                await FriendService.sendFollow(id);
                setIsRequestSent(true);
            } else {
                await FriendService.sendFollow(id);
                setIsFollowing(true);
            }
        } catch (error) {
            console.error('Error toggling follow:', error);
        }
    };

    useEffect(() => {
        getUserDetails();
    }, []);

    const loadSocialConnections = async () => {
        try {
            const postsList = await UserService.getUserPost(userDetails.id);
            setPosts(postsList);
        } catch (error) {
            console.error('Social connections loading failed:', error);
        }
    };

    useEffect(() => {
        if (userDetails && userDetails.data) {
            const { data } = userDetails;
            setUserDetails(data);
        }
        loadSocialConnections();
        console.log('userDetails after effect: ', userDetails);
    }, [userDetails]);

    if (loading) {
        return <div>Loading...</div>;
    }

    const isSocialLinkActive = (link, base) => {
        return link && link !== base && link.length > base.length;
    };

    const handleShow = () => {
        if (show === "timeline") {
            return (
                <div>
                    <div className="container">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="col-lg-10">
                                        <div className="row" style={{ marginLeft: "20%" }}>
                                            <CreatePosts user={userDetails} />
                                            <Posts type='user' user_id={userDetails.id} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        if (show === "about") {
            return (
                <div>
                    <div className="container">
                        <div className="container-fluid">
                            <div className="col-lg-12">
                                <div className="col-lg-10">
                                    <div className="row" style={{ marginLeft: "20%" }}>
                                        <div>
                                            <div className='central-meta newsfeed'>
                                                <div style={{ display: 'flex', marginTop: '4px' }}>
                                                    <span style={{ marginLeft: '12%', fontSize: '20px', marginTop: '5%', marginBottom: '5%' }}>
                                                        When you share photos and videos, they'll appear in you profile
                                                    </span>
                                                </div>
                                                <span style={{ marginLeft: '33%', fontSize: '18px', marginTop: '5%', color: "darkblue" }}>
                                                    Share your first photo and video
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        if (show === "friends") {
            return (
                <div>
                    <div className="container">
                        <div className="container-fluid">
                            <div className="col-lg-12">
                                <div className="col-lg-10">
                                    <div className="row" style={{ marginLeft: "20%" }}>
                                        <div>
                                            <div className='central-meta newsfeed'>
                                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '4%' }}>
                                                    <img src={userChat} alt="User chat icon" style={{ width: '150px', height: 'auto', marginBottom: '5%' }} />
                                                    <span style={{ fontSize: '18px', marginTop: '5%' }}>
                                                        When people tag you in photo or videos, they will appear here
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    };

    const handleMessageClick = () => {
        // dispatch(startNewConversation(userDetails));
        // navigate(`/messages`);
        // MESAJ AKTİFLEŞTİRME YERİ
    };

    return (
        <div>
            {userDetails && (
                <>
                    <AppHeader />
                    <div className="row merged">
                        <section style={{ marginBottom: '50px' }}>
                            <div className="feature-photo">
                                <div className="cover-photo pdng1">
                                    <hr className="new1" />
                                    {userDetails.coverPic ? (
                                        <div className="coverimg-container">
                                            <img
                                                src={userDetails.coverPic}
                                                className="coverimg"
                                                alt="Cover"
                                            />
                                        </div>
                                    ) : (
                                        <div className="coverimg-container">
                                            <img
                                                src={defaultCoverImg}
                                                className="coverimg"
                                                alt="Default Cover"
                                            />
                                            
                                        </div>
                                    )}
                                </div>
                                <div className="row pdng1 person-details">
                                    <div className="col-lg-1"></div>
                                    <div className="col-lg-2 profsts"></div>
                                    <div className="col-lg-2 profsts"></div>
                                    <div className="col-lg-2"></div>
                                </div>
                                <div className="row pdng1 person-details">
                                    <div className="col-lg-1"></div>
                                    <div className="col-lg-2 profsts"></div>
                                    <div className="col-lg-2 profsts"></div>
                                </div>
                                <div className="container pdng1">
                                    <div className="row">
                                        <div className="col-lg-3">
                                            <div className="user-avatar">
                                                {showProfilePicture ? (
                                                    <img id="preview" src={profileRender} />
                                                ) : userDetails.profilePic ? (
                                                    <img
                                                        style={{ zIndex: 1 }}
                                                        className="border-gradient1"
                                                        src={userDetails.profilePic}
                                                    ></img>
                                                ) : (
                                                    <p>Edit Display Photo</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="profile-header">
                                                <h5>{userDetails.firstName ? `${userDetails.firstName} ${userDetails.lastName}` : ''}</h5>
                                                {userDetails.isPrivate ? (
                                                    <img src={lockimg} alt="" style={{ width: '5%', height: 'auto', marginTop: '20px', opacity: '50%' }} />
                                                ) : ""}
                                                {isFollowing ? (
                                                    <>
                                                        <button className="follow-button" onClick={handleMessageClick}>
                                                            Message
                                                        </button>
                                                        <button className="follow-button" onClick={handleFollowToggle}>
                                                            Unfollow
                                                        </button>
                                                    </>
                                                ) : isRequestSent ? (
                                                    <button className="follow-button" onClick={handleFollowToggle}>
                                                        Cancel Request
                                                    </button>
                                                ) : (
                                                    <button className="follow-button" onClick={handleFollowToggle}>
                                                        Follow
                                                    </button>
                                                )}
                                            </div>

                                            <div className="profile-stats-container">
                                                <div className="profile-stats">
                                                    <div className="profsts">
                                                        <ul>
                                                            <li><span className="center-text-span">{posts.length}</span><span>Posts</span></li>
                                                            <li><span className="center-text-span">{userDetails.followers}</span><span>Followers</span></li>
                                                            <li><span className="center-text-span">{userDetails.following}</span><span>Following</span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <span className="myCity">
                                            {userDetails.country && userDetails.city
                                                ? `${userDetails.country}/${userDetails.city}`
                                                : userDetails.country || userDetails.city}
                                        </span>
                                        <span className="about-me">{userDetails.aboutme}</span>
                                        <span className="about-me">{userDetails.hobbies}</span>
                                    </div>
                                    <div className="stories">
                                        <Story />
                                    </div>
                                    <div className="col-lg-1"></div>
                                    <div className="col-lg-6">
                                        <div className="col-lg-6">
                                            <div className="profsts">
                                                <div className='profstss'>
                                                    <ul>
                                                        <li>
                                                            <span>
                                                                <i
                                                                    style={{ cursor: 'pointer' }}
                                                                    className={`fa-brands fa-facebook-f fa-lg ${isSocialLinkActive(userDetails.facebook, 'https://facebook.com/') ? 'active-social' : ''}`}
                                                                    onClick={() => isSocialLinkActive(userDetails.facebook, 'https://facebook.com/') && window.open(userDetails.facebook, '_blank')}
                                                                ></i>
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span>
                                                                <i
                                                                    style={{ cursor: 'pointer' }}
                                                                    className={`fa-brands fa-instagram fa-lg ${isSocialLinkActive(userDetails.instagram, 'https://instagram.com/') ? 'active-social' : ''}`}
                                                                    onClick={() => isSocialLinkActive(userDetails.instagram, 'https://instagram.com/') && window.open(userDetails.instagram, '_blank')}
                                                                ></i>
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span>
                                                                <i
                                                                    style={{ cursor: 'pointer' }}
                                                                    className={`fa-brands fa-twitter fa-lg ${isSocialLinkActive(userDetails.twitter, 'https://twitter.com/') ? 'active-social' : ''}`}
                                                                    onClick={() => isSocialLinkActive(userDetails.twitter, 'https://twitter.com/') && window.open(userDetails.twitter, '_blank')}
                                                                ></i>
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span>
                                                                <i
                                                                    style={{ cursor: 'pointer' }}
                                                                    className={`fa-brands fa-linkedin-in fa-lg ${isSocialLinkActive(userDetails.linkedin, 'https://linkedin.com/in/') ? 'active-social' : ''}`}
                                                                    onClick={() => isSocialLinkActive(userDetails.linkedin, 'https://linkedin.com/in/') && window.open(userDetails.linkedin, '_blank')}
                                                                ></i>
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span>
                                                                <i
                                                                    style={{ cursor: 'pointer' }}
                                                                    className={`fa-brands fa-pinterest fa-lg ${isSocialLinkActive(userDetails.pinterest, 'https://pinterest.com/') ? 'active-social' : ''}`}
                                                                    onClick={() => isSocialLinkActive(userDetails.pinterest, 'https://pinterest.com/') && window.open(userDetails.pinterest, '_blank')}
                                                                ></i>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="timeline-info">
                                        <div className="row">
                                            <div className="col">
                                                <a className={show === "timeline" ? "active button" : "button"} onClick={() => setShow("timeline")}>
                                                    <i className="las la-rss white-icon"></i>
                                                </a>
                                            </div>
                                            <div className="col brdrmid">
                                                <a className={show === "about" ? "active button" : "button"} onClick={() => setShow("about")}>
                                                    <i className="las la-icons white-icon"></i>
                                                </a>
                                            </div>
                                            <div className="col">
                                                <a className={show === "friends" ? "active button" : "button"} onClick={() => setShow("friends")}>
                                                    <i className="las la-user-friends white-icon"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {handleShow()}
                        </section>
                    </div>
                </>
            )}
        </div>
    );
}
