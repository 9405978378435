import { ec as EC } from 'elliptic';
import userService from './user';

const ec = new EC('secp256k1');

class encryptionService {
  generateKeys = async (loggedInUserId) => {
    try {
      const myKeyChain = ec.genKeyPair();

      // ----- Public Key -----
      const myPublicKey = myKeyChain.getPublic('hex');

      // ----- Private Key -----
      const myPrivateKey = myKeyChain.getPrivate('hex');

      await userService.updateEncryptionKeys(myPublicKey, myPrivateKey);
      await this.storeKeys(myPublicKey, myPrivateKey, loggedInUserId);
    } catch (e) {
      console.log("ERROR generateKeys", e);
    }
  };

  getKeys = async () => {
    try {
      const res = await userService.getEncryptionKeys();
      return res.data;
    } catch (e) {
      console.log(e);
    }
  };

  storeKeys = async (publicKey, privateKey, userId) => {
    try {
      localStorage.setItem(
        'encryptionData',
        JSON.stringify({
          publicKey,
          privateKey,
          userId,
        })
      );
    } catch (e) {
      console.log(e);
    }
  };

  checkEncryptionData = async (loggedInUserId) => {
    try {
      let encryptionData = localStorage.getItem('encryptionData');
      if (!encryptionData) {
        const keys = await this.getKeys();
        if (keys && keys.publicKey && keys.privateKey) {
          await this.storeKeys(keys.publicKey, keys.privateKey, loggedInUserId);
          encryptionData = localStorage.getItem('encryptionData');
        }
      }
      return JSON.parse(encryptionData);
    } catch (e) {
      console.error("Error in checkEncryptionData:", e);
      return null;
    }
  };

  checkStoredKeys = async (loggedInUserId) => {
    try {
      const encryptionData = await this.checkEncryptionData();
      if (encryptionData && encryptionData.userId === loggedInUserId) {
        return; 
      } else {
        const keys = await this.getKeys();
        if (!keys || !keys.publicKey || !keys.privateKey) {
          await this.generateKeys(loggedInUserId); 
          return;
        }
        await this.storeKeys(keys.publicKey, keys.privateKey, loggedInUserId); 
        return;
      }
    } catch (e) {
      console.error("Error in checkStoredKeys:", e);
    }
  };

  generateSecretKeys = async (otherSidePublicKey) => {
    try {
      console.log("KONTROLL")
      const encryptionData = await this.checkEncryptionData();
      
      const myKeyChain = ec.keyFromPrivate(encryptionData.privateKey, 'hex');

      console.log("secret key üretme fonk çalıştı ve key: ")
      const secretKey = myKeyChain.derive(ec.keyFromPublic(otherSidePublicKey, 'hex').getPublic());
      return secretKey.toString(16);
    } catch (e) {
      console.log(e);
    }
  };

  getPublicKey = async () => {
    try {
      const encryptionData = await this.checkEncryptionData();
      return encryptionData.publicKey;
    } catch (e) {
      console.log(e);
    }
  };


  
}

export default new encryptionService();
