import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAndSetUserDetails } from '../store/actions/authActions';
import AppHeader from './app/layout/AppHeader';
import AddressCard from './profile/AddressCard';
import AuthService from '../services/auth.services';
import '../assets/css/SecurityComponent.css';
import SecurityTermsModal from './SecurtySettings/SecurtyTermsModal';

export default function SecuritySettingsComponent() {
    const [loading, setLoading] = useState(true);
    const [showTermsModal, setShowTermsModal] = useState(false); // TermsModal durum değişkeni
    const [show, setShow] = useState('general');
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.userDetails?.data);

    useEffect(() => {
        const fetchDetails = async () => {
            try {
                const userId = AuthService.getCurrentUser().id;
                if (userId) {
                    await dispatch(fetchAndSetUserDetails(userId));
                }
            } catch (error) {
                console.error("Error fetching user details:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchDetails();
    }, [dispatch]);

    if (loading) {
        return <div>Loading...</div>;
    }

    const changeView = () => {
        if (show === 'general') {
            return (
                <div className="right-settings settngPadding">
                    <div className="right-settings-content">
                        <div className="right-settings-bio-top">
                            <p className="clrHead">General Settings</p>
                        </div>
                    </div>
                </div>
            );
        } else if (show === "security") {
            return (
                <div className="right-settings settngPadding">
                    <div className="right-settings-content">
                        <div className="right-settings-bio-top">
                            <div>

                            <p className="clrHead">Security Settings</p>
                            </div>
                            <div>
                            <button
                                className="privacy-policy-button"
                                onClick={() => setShowTermsModal(true)}
                            >
                                Click here to view the Privacy Policy
                            </button>
                            <p className="privacy-policy-text">
                                By using our services, you agree to our Privacy Policy.
                            </p>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else if (show === "address") {
            return (
                <div className="right-settings settngPadding">
                    <AddressCard />
                </div>
            );
        } else if (show === "language") {
            return (
                <div className="right-settings settngPadding">
                    <div className="right-settings-content">
                        <div className="right-settings-bio-top">
                            <p className="clrHead">Language Settings</p>
                        </div>
                        <p>Change Your Language</p>
                        <div className="language-selection">
                            <label htmlFor="language-select">Choose a language:</label>
                            <select id="language-select" name="languages">
                                <option value="en">English (EN)</option>
                                <option value="tr">Türkçe (TR)</option>
                                <option value="ar">العربية (AR)</option>
                            </select>
                        </div>
                    </div>
                </div>
            );
        }
    };

    return (
        <>
        <AppHeader user={user} />
            <div className="central-settings">
                <div className="left-settings settngPadding">
                    <div>
                        <h1>Settings</h1>
                        <div className={`settings-list ${show === 'general' && 'active'}`} onClick={() => setShow('general')}>
                            <img src="assets/images/gnrlstng.svg" alt="General" />
                            <p>General</p>
                        </div>
                        <div className={`settings-list ${show === 'security' && 'active'}`} onClick={() => setShow('security')}>
                            <img src="assets/images/scrtyStng.svg" alt="Security and Privacy"/>
                            <p>Security and Privacy</p>
                        </div>
                        <div className={`settings-list brdrbtmEdtprf ${show === 'address' && 'active'}`} onClick={() => setShow('address')}>
                            <img src="assets/images/locstng.svg" alt="My Address"/>
                            <p>My Address</p>
                        </div>
                        <div className={`settings-list ${show === 'language' && 'active'}`} onClick={() => setShow('language')}>
                            <img src="assets/images/langstng.svg" alt="Language Settings"/>
                            <p>Language Settings</p>
                        </div>
                    </div>
                </div>
                {changeView()}
                {showTermsModal && (
                    <SecurityTermsModal
                        onAccept={() => {
                            setShowTermsModal(false);
                        }}
                    />
                )}
            </div>
        </>
    );
}
