import {
    FETCH_CONVERSATIONS,
    FETCH_MESSAGES,
    START_NEW_CONVERSATION,
    FETCH_FOLLOWERS,
    NEW_MESSAGE_RECEIVED
} from '../actions/chatActions';

const initialState = {
    conversations: [],
    messages: [],
    followers: [],
    selectedConversation: null,
};

const chatReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CONVERSATIONS:
            return {
                ...state,
                conversations: action.payload,
            };
        case FETCH_MESSAGES:
            return {
                ...state,
                messages: action.payload,
            };
        case START_NEW_CONVERSATION:
            return {
                ...state,
                conversations: state.conversations.some(conv => conv.id === action.payload.id)
                    ? state.conversations
                    : [...state.conversations, {
                        ...action.payload,
                        messages: action.payload.messages || [] 
                    }],
                selectedConversation: action.payload,
            };
        case FETCH_FOLLOWERS:
            return {
                ...state,
                followers: action.payload,
            };
        case NEW_MESSAGE_RECEIVED:
            return {
                ...state,
                conversations: state.conversations.map(conv => {
                    if (conv.id === action.payload.conversationId) {
                        return {
                            ...conv,
                            messages: [...(conv.messages || []), action.payload.message]
                        };
                    }
                    return conv;
                }),
                selectedConversation: state.selectedConversation?.id === action.payload.conversationId
                    ? {
                        ...state.selectedConversation,
                        messages: [...(state.selectedConversation.messages || []), action.payload.message]
                    }
                    : state.selectedConversation
            };
        default:
            return state;
    }
};

export default chatReducer;
