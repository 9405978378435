import React from 'react';

const Message = ({ message, isSent }) => {
  const renderContent = () => {
    console.log('mesage :', message, isSent)
    if (message.type && message.type === 'text') {
      return <p>{message.content}</p>;
    }
    if (message.type && message.type === 'image') {
      return message.media.map((mediaItem, index) => (
        <img key={index} src={mediaItem.mediaUrl} alt="Sent media" />
      ));
    }
    // Diğer türler için farklı işleme yapabilirsiniz
    return null;
  };

  return (
    <div className={`chat-message ${isSent ? 'sent' : 'received'}`}>
      {renderContent()}
    </div>
  );
};

export default Message;
