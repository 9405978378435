const settings = {
    dev: {
      apiUrl: "http://localhost:8080",
  
    },
    staging: {
      apiUrl: "http://localhost:8080",
  
    },
    prod: {
      apiUrl: "https://backend.shareupx.com",
      //apiUrl: "http://localhost:8080",
    },
  };
  
  const getCurrentSettings = () => {
    return settings.prod;
  };
  
  export default getCurrentSettings();
  