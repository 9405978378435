import React, { useState, useRef, useEffect } from 'react';
import fileStorage from '../../../config/fileStorage';
import AuthServices from '../../../services/auth.services';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAndSetUserDetails } from '../../../store/actions/authActions';
import UserService from '../../../services/user';
import "../../../assets/css/appHeader.css";
import Activities from "../../../services/ActivityService";
import MessageServices from '../../../services/MessageServices';
import shareupxlogo from '../../../assets/images/shareupx_logo.jpg'
export default function AppHeader() {
    const [searchTerm, setSearchTerm] = useState('');
    const [showUserSettings, setShowUserSettings] = useState(false);
    const [showNotifications, setShowNotifications] = useState(false);
    const [showMessages, setShowMessages] = useState(false);
    const settingsRef = useRef(null);
    const notificationsRef = useRef(null);
    const messagesRef = useRef(null);
    const [loading, setLoading] = useState(true);
    const [searchResults, setSearchResults] = useState([]);
    const dispatch = useDispatch();
    const [activities, setActivities] = useState([]);
    const [messages, setMessages] = useState([]);

    const searchRef = useRef(null);

    const user = useSelector(state => state.auth.userDetails?.data);

    useEffect(() => {
        const fetchDetails = async () => {
            try {
                const userId = AuthServices.getCurrentUser().id;
                if (userId) {
                    await dispatch(fetchAndSetUserDetails(userId));
                }
            } catch (error) {
                console.error("Error fetching user details:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchDetails();
        getActivities();
        getMessages();
    }, [dispatch]);

    const getActivities = async () => {
        try {
            const activities = await Activities.getActivities();
            setActivities(activities.data);
        } catch (error) {
            console.error("getActivities err:", error);
        }
    };

    const getMessages = async () => {
        try {
            const messages = await MessageServices.getConversations();
            setMessages(messages);
        } catch (error) {
            console.error("getMessages err:", error);
        }
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (settingsRef.current && !settingsRef.current.contains(event.target)) {
                setShowUserSettings(false);
            }
            if (notificationsRef.current && !notificationsRef.current.contains(event.target)) {
                setShowNotifications(false);
            }
            if (messagesRef.current && !messagesRef.current.contains(event.target)) {
                setShowMessages(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [settingsRef, notificationsRef, messagesRef]);

    const handleLogout = () => {
        AuthServices.logout();
    };

    const handleSearch = async (event) => {
        setSearchTerm(event.target.value);
        if (event.target.value.trim() !== '') {
            const results = await UserService.searchUsers(event.target.value);
            setSearchResults(results);
        } else {
            setSearchResults([]);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    const unreadNotificationsCount = activities.filter(activity => activity.isRead === 0).length;


    return (
        <div className="topbar stick">
            <div className="container">
                <div className="main-area">
                    <ul className="main-menu">
                        <li><a className="top-home" href="/newsfeed" title="Home"> <i className="las la-home" aria-hidden="true" /><span>Feed</span></a></li>
                        <li><a className="top-friends" href="/friends" title="Friends"><i className="las la-user-plus" aria-hidden="true" /><span>Friends</span></a></li>
                        <li><a className="top-groups" href="/groups" title="GROUPS"><i className="la la-users" aria-hidden="true" /><span>Groups</span></a></li>
                        <li><a className="top-sharepoint" href="/hangFeed" title="SharePoint"><i className="la la-share-alt" aria-hidden="true" /><span>Hang</span></a></li>
                        <li>
                            <a className="top-swappoint" href="/swapFeed" title="SwapPoint">
                                <i className="las la-sync-alt" aria-hidden="true" />
                                <span>Swap</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="logo-inside">
                    <a title="notif" href="/newsfeed"><img src={shareupxlogo} alt="" style={{ marginTop: '10px' }} /></a>
                </div>
                <div className="top-area">
                    <ul className="setting-area">
                        <li>
                            <input
                                type="text"
                                className="searchTerm"
                                placeholder="Search Shareup Members..."
                                name="search"
                                value={searchTerm}
                                onChange={handleSearch}
                                ref={searchRef}
                            />
                            {searchResults && searchResults.length > 0 && (
                                <div className="search-results-container">
                                    <ul className="search-results-list">
                                        {searchResults.map((result) => (
                                            <li key={result.id} className="search-result-item">
                                                <a href={`/profile/${result.id}`}>
                                                    <img
                                                        src={result.profilePic ? fileStorage.baseUrl + result.profilePic : '../../assets/images/unnamed.png'}
                                                        alt=""
                                                    />
                                                    <span>{result.firstName} {result.lastName}</span>
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </li>
                        <li ref={notificationsRef} >
                            <div className="noti" style={{ marginTop: "15%" }}>
                                <a href="/activity" title="Notification" data-ripple>
                                    <i className="ti-bell" /><span>{unreadNotificationsCount}</span>
                                </a>
                                <div className={`dropdowns ${showNotifications ? 'active' : ''}`}>
                                    <span>4 New Notifications</span>
                                    <ul className="drops-menu">
                                        <li>
                                            <a href="/notifications" title="notif">
                                                <img src="../assets/images/resources/thumb-1.jpg" alt="" />
                                                <div className="mesg-meta">
                                                    <h6>sarah Loren</h6>
                                                    <span>Hi, how r u dear ...?</span>
                                                    <i>2 min ago</i>
                                                </div>
                                            </a>
                                            <span className="tag green">New</span>
                                        </li>
                                        <li>
                                            <a href="/notifications" title="notif">
                                                <img src="../assets/images/resources/thumb-2.jpg" alt="" />
                                                <div className="mesg-meta">
                                                    <h6>Jhon doe</h6>
                                                    <span>Hi, how r u dear ...?</span>
                                                    <i>2 min ago</i>
                                                </div>
                                            </a>
                                            <span className="tag red">Reply</span>
                                        </li>
                                        <li>
                                            <a href="/notifications" title="notif">
                                                <img src="../assets/images/resources/thumb-3.jpg" alt="" />
                                                <div className="mesg-meta">
                                                    <h6>Andrew</h6>
                                                    <span>Hi, how r u dear ...?</span>
                                                    <i>2 min ago</i>
                                                </div>
                                            </a>
                                            <span className="tag blue">Unseen</span>
                                        </li>
                                        <li>
                                            <a href="/notifications" title="notif">
                                                <img src="../assets/images/resources/thumb-4.jpg" alt="" />
                                                <div className="mesg-meta">
                                                    <h6>Tom cruse</h6>
                                                    <span>Hi, how r u dear ...?</span>
                                                    <i>2 min ago</i>
                                                </div>
                                            </a>
                                            <span className="tag">New</span>
                                        </li>
                                        <li>
                                            <a href="/notifications" title="notif">
                                                <img src="../assets/images/resources/thumb-5.jpg" alt="" />
                                                <div className="mesg-meta">
                                                    <h6>Amy</h6>
                                                    <span>Hi, how r u dear ...?</span>
                                                    <i>2 min ago</i>
                                                </div>
                                            </a>
                                            <span className="tag">New</span>
                                        </li>
                                    </ul>
                                    <a href="/notifications" title="notif" className="more-mesg">view more</a>
                                </div>
                            </div>
                        </li>
                        <li ref={messagesRef}>
                            <div className="mssg" style={{ marginTop: "18%" }} >
                                
                                <a title="Download our mobile app to use end-to-end encrypted messaging." className='message-appheader '  data-ripple data-tooltip="">
                                    <i className="ti-comment" />
                                    <span>{messages.length}</span>
                                </a>
                                {/* href="/messages" */}

                                {/* Mesajlar aktifleştirme yeri */}

                                <div className={`dropdownsmsg ${showMessages ? 'active' : ''}`}>
                                    <span>5 New Messages</span>
                                    {/* <ul className="drops-menu">
                                        <li>
                                            <a href="/notifications" title="notif">
                                                <img src="../assets/images/resources/thumb-1.jpg" alt="" />
                                                <div className="mesg-meta">
                                                    <h6>sarah Loren</h6>
                                                    <span>Hi, how r u dear ...?</span>
                                                    <i>2 min ago</i>
                                                </div>
                                            </a>
                                            <span className="tag green">New</span>
                                        </li>
                                        <li>
                                            <a href="/notifications" title="notif">
                                                <img src="../assets/images/resources/thumb-2.jpg" alt="" />
                                                <div className="mesg-meta">
                                                    <h6>Jhon doe</h6>
                                                    <span>Hi, how r u dear ...?</span>
                                                    <i>2 min ago</i>
                                                </div>
                                            </a>
                                            <span className="tag red">Reply</span>
                                        </li>
                                        <li>
                                            <a href="/notifications" title="notif">
                                                <img src="../assets/images/resources/thumb-3.jpg" alt="" />
                                                <div className="mesg-meta">
                                                    <h6>Andrew</h6>
                                                    <span>Hi, how r u dear ...?</span>
                                                    <i>2 min ago</i>
                                                </div>
                                            </a>
                                            <span className="tag blue">Unseen</span>
                                        </li>
                                        <li>
                                            <a href="/notifications" title="notif">
                                                <img src="../assets/images/resources/thumb-4.jpg" alt="" />
                                                <div className="mesg-meta">
                                                    <h6>Tom cruse</h6>
                                                    <span>Hi, how r u dear ...?</span>
                                                    <i>2 min ago</i>
                                                </div>
                                            </a>
                                            <span className="tag">New</span>
                                        </li>
                                        <li>
                                            <a href="/notifications" title="#">
                                                <img src="../assets/images/resources/thumb-5.jpg" alt="" />
                                                <div className="mesg-meta">
                                                    <h6>Amy</h6>
                                                    <span>Hi, how r u dear ...?</span>
                                                    <i>2 min ago</i>
                                                </div>
                                            </a>
                                            <span className="tag">New</span>
                                        </li>
                                    </ul> */}
                                    <a href="messages.html" title="notif" className="more-mesg">view more</a>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div className="user-img">
                        <img onClick={() => setShowUserSettings(!showUserSettings)} src={user.profilePic ? fileStorage.baseUrl + user.profilePic : "/assets/images/vector-34@2x.png"} style={{ backgroundColor: '#033347', marginBottom: '15%', maxWidth: '51.5px', maxHeight: '51.5px', width: '51.5px', height: '51.5px' }} alt="" />
                        <span className="status f-online" style={{ marginBottom: '10%' }} />
                        {showUserSettings && (
                            <div ref={settingsRef} className="user-setting active">
                                <a href="/profile"><i className="ti-user" /> view profile</a>
                                <a href="/editprofile"><i className="ti-pencil-alt" />edit profile</a>
                                <a href="/settings"><i className="ti-settings" />account setting</a>
                                <a href="#!" onClick={handleLogout}><i className="ti-power-off" />log out</a>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
