import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import axios from "axios";

import { FcCheckmark } from "react-icons/fc";
import { MdError } from "react-icons/md";

const baseUrl = "https://backend.shareupx.com/api/v1/auth/request/delete_account/";
const devUrl = "http://localhost:8080/api/v1/auth/request/delete_account/";

function VerifyDeleteProfile() {
  const { token, otp } = useParams();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const verifyDeleteProfile = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/${otp}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 200) {
          setIsSuccess(true);
        }
      } catch (error) {
        setIsError(true);
      }
    };

    if (token && otp) {
      verifyDeleteProfile();
    }
  }, [token, otp]);

  return (
    <div className="delete-profile-modal">
      <div className="delete-profile-content">
        <h2 className="delete-profile-title">
,          {isSuccess ? "Account Deleted" : "Error Deleting Account"}
        </h2>
        <div style={{ fontSize: "8rem", textAlign: "center" }}>
          {isSuccess ? (
            <FcCheckmark style={{ color: "green" }} />
          ) : (
            <MdError style={{ color: "red" }} />
          )}
        </div>
      </div>
    </div>
  );
}

export default VerifyDeleteProfile;
