import React, { useState, useEffect } from 'react';
import ReelItem from './ReelItem';
import '../../../assets/css/Reels.css';

import ReelsServices from '../../../services/ReelsServices';
import AppLayout from '../layout/AppLayout';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAndSetUserDetails } from '../../../store/actions/authActions';
import authServices from '../../../services/auth.services';



const Reels = () => {
    const [reels, setReels] = useState([]);
    const user = useSelector(state => state.auth.userDetails?.data);
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchAndSetUserDetails(authServices.getCurrentUser().id))
       
      }, [dispatch])
    useEffect(() => {
        // API'den verileri çek
        ReelsServices.getAllReels()
            .then(data => setReels(data))
            .catch(error => console.error('API hatası:', error));

        console.log('reels : ',reels)
    }, []);

    return (
        <AppLayout user={user} >
            <div className="reels-container">
                {reels.map(reel => (
                    <ReelItem key={reel.id} reel={reel} />
                ))}
            </div>
        </AppLayout>

    );
};

export default Reels;