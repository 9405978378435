import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import '../../assets/css/CustomAlert.css'; 

function CustomAlert({ message, onClose }) {
    useEffect(() => {
        const timer = setTimeout(onClose, 3000);
        return () => clearTimeout(timer);
    }, [onClose]);

    return ReactDOM.createPortal(
        <div className="alert">
            {message}
        </div>,
        document.body
    );
}

export default CustomAlert;