import React, { useState, useReducer } from 'react'
import Footer from './Footer'
import { Link } from 'react-router-dom'
import logo from '../../assets/images/logo/kare.png'



export default function Layout({ children }) {


  return (
    <>
      <div className="theme-layout">
        <div className="container-land pdng0">
          <div className="topbarLand transparent">
           
            {/* 
            
            <div className="top-area-land">
              <ul className="setting-area">
                <li> <Link to="/about"  > About </Link></li>
                <li><Link to="/privacyPolicy"  >Privasscy
                  Bill of Rights</Link></li>
                <li><a href="#" title="Languages" data-ripple><i className="fa fa-globe" /></a>
                  <div className="dropdowns languages">
                    <a href="#" ><i className="ti-check" />English</a> <a href="#" >Arabic</a> <a href="#" >Dutch</a> <a href="#" >French</a>
                  </div></li>
                <li>
                <Link to="/login"> <button  className="mtr-btn" ><span>Members Login</span> </button>  </Link>
             </li>
              </ul>
            </div>
            
            */}
            
          </div>
          <div className="mainContnr">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div >

                {
                  children
                }
              </div>
            </div>
           
          </div>
        </div>
       
       

      </div>
     
    </>
  )
}
