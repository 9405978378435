import React from 'react';
import { Link } from 'react-router-dom';

export default function LeftBar({ user }) {
    return (
        <aside className="sidebar static" >
            <div className="widget" style={{ borderBottom: '1px solid #75757530' }}>
                {/* User Information */}
                <div className="user" >
                    <img src={user.profilePic ? user.profilePic : '/assets/images/vector-34@2x.png'} style={{backgroundColor:'#033347'}} alt="Profile" />
                    <Link to="/profile"><p style={{ fontWeight: "bold" }}>{`${user.firstName} ${user.lastName}`}</p></Link>
                </div>
            </div>
            {/* Menü widget */}
            <div className="widget navmenu">
                <div>
                    <ul className="naves">
                        {/* Menu items */}
                        <li><i className="ti-write" /><Link to="/savedShares" title="Saved Posts">Saved Posts</Link></li>              
                        <li><i className="ti-control-shuffle"/><Link to="/swapPosts" title="Swap Posts">Swap Posts</Link></li>
                        <li><i className="ti-link" /><Link to="/hangPosts" title="Hang Share Posts">Hang Share Posts</Link></li>                                        
                        <li><i className="ti-shopping-cart" /><Link to="/redeemedItem" title="Redeemed Item">Redeemed Item</Link></li>
                        <li><i className="ti-support" /><Link to="/helpAndSupport" title="Help & Support">Help & Support</Link></li>
                        <li><i className="ti-settings" /><Link to="/settings" title="Settings & Privacy">Settings & Privacy</Link></li>
                        <li><i className="ti-back-right" /><Link to="/login" title="Logout">Logout</Link></li>
                    </ul>
                </div>
            </div>
        </aside>
    );
}
