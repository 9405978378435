export default {
    languages : {
      english: 'English',
      turkish: 'Türkçe',
      arabic: 'العربية',
    },
    shareupCoinsLimit: 1000,
    postTypes: {
      HANG_SHARE: 'HangShare',
      NORMAL: 'Normal',
      SWAP: 'Swap',
      GROUP_POST: 'GroupPost',
      SAVED: 'Saved',
      // SHARE_UP: "ShareUp",
    },
    folderNames: {
      PROFILE_PICTURES: 'profile-pictures',
      POSTS: 'posts',
      REELS: 'reels',
      OFFERS: 'offers',
      STORIES: 'stories',
      STORY_THUMBNAILS: 'story-thumbnails',
      GROUP_COVER_PICTURES: 'group-cover-pictures',
      HANG_SHARE_PROVIDER_PICTURES: 'hang-share-provider-pictures',
      HANG_SHARE_PROVIDER_ITEMS: 'hang-share-provider-items',
      CHAT_IMAGES: 'chat-images',
      CHAT_DOCUMENTS: 'chat-documents',
    },
    reportTypes: {
      REEL: 'reel',
      POST: 'post',
      COMMENT: 'comment',
      COMMENTREPLY: 'commentReply',
      MESSAGE: 'message',
    },
  
    /* 
    activityTypes
      activity: The activity itself
      activityType: The type of activity for the bell icons in the bottom navigation (like, comment, swap, user, group)
      notificationTitle: The title of the notification
      notificationBody: The body of the notification and also for the activity in the activity screen
      notificationType: The type of notification for notification grouping
      notificationStyle: The style of the notification 
      notificationStyleType: The type for android style property
      notificationStyleText: The text for android style property
      notificationActions: The actions for the notification
    */
    activityTypes: {
      POST_LIKE: {
        activity: 'POST_LIKE',
        activityType: 'like',
        notificationTitle: 'Post Starred',
        notificationBody: 'Starred your post',
        notificationType: 'like',
      },
      COMMENT_LIKE: {
        activity: 'COMMENT_LIKE',
        activityType: 'like',
        notificationTitle: 'Comment Starred',
        notificationBody: 'Starred your comment',
        notificationType: 'like',
      },
      MESSAGE: {
        activity: 'MESSAGE',
        notificationTitle: 'Message',
        notificationType: 'message',
        notificationStyleType: 'messaging',
      },
      FRIEND_REQUEST: {
        activity: 'FRIEND_REQUEST',
        activityType: 'user',
        notificationTitle: 'Friend Request',
        notificationBody: 'sent you a friend request',
        notificationType: 'friendRequest',
      },
      FRIEND_ACCEPTED: {
        activity: 'FRIEND_ACCEPTED',
        activityType: 'user',
        notificationTitle: 'Request Accepted',
        notificationStyleType: 'bigText',
        notificationStyleText: 'Accepted your friend request 🥳',
        notificationType: 'friendAccepted',
      },
      POST_COMMENT: {
        activity: 'POST_COMMENT',
        activityType: 'comment',
        notificationTitle: 'New Comment',
        notificationStyleType: 'bigText',
        notificationStyleText: 'commented',
        notificationType: 'comment',
      },
      POST_COMMENT_REPLY: {
        activity: 'POST_COMMENT_REPLY',
        activityType: 'comment',
        notificationTitle: 'New Reply',
        notificationStyleType: 'bigText',
        notificationStyleText: 'replied',
        notificationType: 'comment',
      },
      OFFER: {
        activity: 'OFFER',
        activityType: 'swap',
        notificationTitle: 'New Offer',
        notificationBody: 'sent you an offer',
        notificationType: 'message',
      },
      SWAP_AGREE: {
        activity: 'SWAP_AGREE',
        activityType: 'swap',
        notificationTitle: 'Swap',
        notificationBody: 'agreed to swap',
        notificationType: 'swap',
      },
      REEL_LIKE: {
        activity: 'REEL_LIKE',
        activityType: 'like',
        notificationTitle: 'Reel Starred',
        notificationBody: 'Starred your reel',
        notificationType: 'like',
      },
      REEL_COMMENT: {
        activity: 'REEL_COMMENT',
        activityType: 'comment',
        notificationTitle: 'New Comment',
        notificationStyleType: 'bigText',
        notificationStyleText: 'commented',
        notificationType: 'comment',
      },
      REEL_COMMENT_REPLY: {
        activity: 'REEL_COMMENT_REPLY',
        activityType: 'comment',
        notificationTitle: 'New Reply',
        notificationStyleType: 'bigText',
        notificationStyleText: 'replied',
        notificationType: 'comment',
      },
      GROUP_INVITE: {
        activity: 'GROUP_INVITE',
        activityType: 'group',
        notificationTitle: 'Group Invite',
        notificationBody: 'invited you to join',
        notificationType: 'group',
      },
      GROUP_JOIN_REQUEST_ACCEPTED: {
        activity: 'GROUP_JOIN_REQUEST_ACCEPTED',
        activityType: 'group',
        notificationTitle: 'Request Accepted',
        notificationStyleType: 'bigText',
        notificationStyleText: 'Accepted your request to join',
        notificationType: 'group',
      },
      GROUP_JOIN_REQUEST: {
        activity: 'GROUP_JOIN_REQUEST',
        activityType: 'group',
        notificationTitle: 'Group Join Request',
        notificationBody: 'is asking to join your group',
        notificationType: 'group',
      },
      GROUP_JOINED: {
        activity: 'GROUP_JOINED',
        activityType: 'group',
        notificationTitle: 'Group Joined',
        notificationStyleType: 'bigText',
        notificationStyleText: 'joined your group',
        notificationType: 'group',
      },
      STORY_LIKE: {
        activity: 'STORY_LIKE',
        activityType: 'like',
        notificationTitle: 'Story Starred',
        notificationBody: 'Starred your story',
        notificationType: 'like',
      },
      SWAP_INTERESTED: {
        activity: 'SWAP_INTERESTED',
        activityType: 'swap',
        notificationTitle: 'Swap',
        notificationBody: 'is interested in your last offer',
        notificationType: 'swap',
      },
    },
  };