import React, { useState, useEffect } from 'react';
import '../../../../assets/css/Post.css';
import PostServices from '../../../../services/post';
import Feed from './feed/Feed';
import Loading from '../../../elements/Loading';
import Post from './Post';
import { useSelector } from 'react-redux';
import { secondsToMilliseconds } from 'date-fns';
import GroupService from '../../../../services/GroupService';

const limit = 5;

export default function Posts({ type, user_id, group_id , refresh  }) {
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [posts, setPosts] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isUserDetailsLoaded, setUserDetailsLoaded] = useState(false);
  const userDetails = useSelector(state => state.auth.userDetails);

  useEffect(() => {
    if (userDetails && userDetails.data) {
      setUserDetailsLoaded(true);
    }
  }, [userDetails]);

  const userId = userDetails && userDetails.data ? userDetails.data.id : null;

  const loadNews = async (page = 1) => {
    console.log('post kontrol : ',posts.length, count)
    if (posts.length !== 0 && posts.length >= count) return;
    setLoading(true);
    try {
      if (page === 1) {
        const countRes = await PostServices.getNewsFeedPostCount();
        setCount(countRes.data.postCount);
      }
      const offset = (page - 1) * limit;
      const res = await PostServices.getNewsFeed(page, limit);
      setPosts(prevPosts => {
        const existingIds = new Set(prevPosts.map(post => post.id));
        const uniqueNews = res.data.filter(news => !existingIds.has(news.id));
        return [...prevPosts, ...uniqueNews];
      });
      setPage(page => page + 1);
      console.log( res.data)
    } catch (e) {
      console.error('Error loading news:', e);
    }finally {
      console.log(count, page, posts.length, posts)
      setLoading(false);
    }
  };

  const loadPostBytype = async (type) => {
    console.log('loadPostBytype çalıştı')
    if (posts.length >= count && page > 1) return;
    setLoading(true);
    try {
      if (page === 1) {
        const countRes = await PostServices.getFilteredFeedPostCount(type);
        setCount(countRes.data.postCount);
      }
      const offset = (page - 1) * limit;
      const res = await PostServices.getPostsByPostType(type, page, limit);
      

      setPosts(prevPosts => {
        const existingIds = new Set(prevPosts.map(post => post.id));
        const uniqueNews = res.data.filter(news => !existingIds.has(news.id));
        return [...prevPosts, ...uniqueNews];
      });
      setPage(prevPage => prevPage + 1);
      console.log('postlar : ', posts)
    } catch (e) {
      console.error('Error loading posts by type:', e);
    } finally {
      setLoading(false);
    }
};

  const loadUserPosts = async () => {
    try {
      const res = await PostServices.getPostsByUserId(user_id ? user_id : userId);
      setPosts(res.data);
    } catch (error) {
      console.error('Error loading user posts:', error);
    }
  };

  const loadGroupPost = async () => {
    try {
      const res = await GroupService.getGroupPosts(group_id && group_id);
      setPosts(res.data);
    } catch (error) {
      console.error('Error loading group posts:', error);
    }
  };

  async function loadHandle(type) {
    switch (type) {
      case 'newsfeed':
        await loadNews(page);
        break;
      case 'user':
        loadUserPosts()
        break;
      case 'group':
        loadGroupPost()
        break;
      case 'Swap':
        loadPostBytype(type)
        break;
      case 'hangShare':
        loadPostBytype(type)
        break;
      case 'Saved':
        loadPostBytype(type)
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    loadHandle(type)
  }, []);



  useEffect(() => {
    const handleScroll = async () => {
      const nearBottom = window.innerHeight + window.scrollY >= document.body.offsetHeight - 100;
      if (nearBottom) {
        if (posts.length <= count) {
          await loadHandle(type);
        }
       
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [type, page, posts]);

  if (!isUserDetailsLoaded) {
    return <Loading />;
  }

  return (
    <>
      {posts.map((item, index) => (
        <Post key={index} post={item} type={type}/>
      ))}
      {isLoading && <Loading />}
    </>
  );
}
