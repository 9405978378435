import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAndSetUserDetails } from '../../store/actions/authActions';
import UserService from '../../services/user';
import AuthService from '../../services/auth.services';
import DetailsModal from './DetailsModal';
import SocialModal from './socialModal';
import contance from '../../config/contance';
import AppHeader from "../app/layout/AppHeader";
import "../../assets/css/editprofile.css";
import AddressCard from "./AddressCard";
import ConfirmModal from './ConfirmModal';



export default function EditProfileComponent() {
    const [profilePicture, setProfilePicture] = useState(null)
    const [profileRender, setProfileRender] = useState(null)
    const [showProfilePicture, setShowProfilePicture] = useState(false)
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [aboutme, setAboutme] = useState("")
    const [homeTown, setHomeTown] = useState("")
    const [currentTown, setCurrentTown] = useState("")
    const [relationshipStatus, setRelationshipStatus] = useState("")
    const [interests, setInterests] = useState("");
    const [loading, setLoading] = useState(true);
    const [facebook, setFacebook] = useState('');
    const [instagram, setInstagram] = useState('');
    const [twitter, setTwitter] = useState('');
    const [linkedin, setLinkedin] = useState('');
    const [pinterest, setPinterest] = useState('');
    const [editBioMode, setEditBioMode] = useState(false);
    const [editHobbiesMode, setEditHobbiesMode] = useState(false);
    const [show, setShow] = useState('overview')
    const [DetailsModalOpen, setDetailsModalOpen] = useState(false);
    const [SocialModalOpen, setSocialModalOpen] = useState(false);
    const bioInputRef = useRef(null);
    const hobbiesInputRef = useRef(null);
    const [isPrivate, setIsPrivate] = useState(1);

    const [modalContent, setModalContent] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => { },
        onClose: () => setModalContent(prev => ({ ...prev, isOpen: false }))
    });

    


    const dispatch = useDispatch();
    const userDetails = useSelector(state => state.auth.userDetails?.data);

    useEffect(() => {
        const fetchDetails = async () => {
            try {
                const userId = AuthService.getCurrentUser().id;
                if (userId) {
                    await dispatch(fetchAndSetUserDetails(userId));

                    setIsPrivate(userDetails && userDetails.isPrivate)
                }
            } catch (error) {
                console.error("Error fetching user details:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchDetails();
    }, [dispatch]);

    useEffect(() => {
        if (profilePicture) {
            uploadProfilePicture();
        }
    }, [profilePicture]);

    const handleProfileImage = (event) => {
        const file = event.target.files[0];
        if (!file) {
            console.log("No file selected.");
            return;
        }
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setProfileRender(reader.result);
                setShowProfilePicture(true); 
            }
        };
        reader.readAsDataURL(file);
        setProfilePicture(file);
    };

    const handleTogglePrivacy = () => {
        setModalContent({
            isOpen: true,
            title: 'Change Privacy Setting',
            message: `Are you sure you want to make your account ${isPrivate ? 'public' : 'private'}?`,
            onConfirm: confirmPrivacyChange,
            onClose: () => setModalContent(prev => ({ ...prev, isOpen: false }))
        });
    };

    const uploadProfilePicture = async () => {
        if (!profilePicture) {
            console.log("No profile picture selected!");
            return;
        }

        const formData = new FormData();
        const userDetails = {
            user: {},
            userDetails: {
                profilePic: ""
            }
        }
        formData.append("data", JSON.stringify(userDetails));
        formData.append("file", profilePicture);
        formData.append("folder", contance.folderNames.PROFILE_PICTURES);

        try {
            const response = await UserService.editProfile(formData);
            console.log('Profile picture updated:', response);
            dispatch(fetchAndSetUserDetails(AuthService.getCurrentUser().id));
        } catch (error) {
            console.error('Error uploading profile picture:', error);
        }
    };

    const confirmPrivacyChange = () => {
        setModalContent(prev => ({ ...prev, isOpen: false }));
        setIsPrivate(!isPrivate);
        const formData = new FormData();
        const data = {
            userDetails: {
            },
            user: {
                isPrivate: !isPrivate 
            }
        };
        try {
            formData.append("data", JSON.stringify(data));
            const response = UserService.editProfile(formData);
            console.log('Profile updated:', response);
        } catch (error) {
            console.error('Error updating profile:', error);
        }  
    };


    useEffect(() => {
        if (editBioMode) {
            bioInputRef.current.focus();
        }
    }, [editBioMode]);


    useEffect(() => {
        if (editHobbiesMode) {
            hobbiesInputRef.current.focus();
        }
    }, [editHobbiesMode]);


    useEffect(() => {
        
        if (userDetails) {
            setFirstName(userDetails.firstName);
            setLastName(userDetails.lastName);
            setAboutme(userDetails.aboutme);
            setHomeTown(userDetails.city);
            setCurrentTown(userDetails.country);
            setRelationshipStatus(userDetails.relationshipstatus);
            setInterests(userDetails.hobbies);
            setFacebook(userDetails.facebook);
            setInstagram(userDetails.instagram);
            setLinkedin(userDetails.linkedin);
            setPinterest(userDetails.pinterest);
            setTwitter(userDetails.twitter);
            setIsPrivate(userDetails.isPrivate)

        }
    }, [userDetails]);


    const updateProfile = async () => {
        const formData = new FormData();
        
        const data = {
            userDetails: {
                aboutme: aboutme,
                country: currentTown,
                relationshipStatus: relationshipStatus,
                hobbies: interests,
                city: homeTown,
                facebook: facebook,
                instagram: instagram,
                twitter: twitter,
                linkedin: linkedin,
                pinterest: pinterest,
            },
            user: {
            }
        };
    
        try {
            formData.append("data", JSON.stringify(data));
            const response = await UserService.editProfile(formData);
            console.log('Profile updated:', response);
            dispatch(fetchAndSetUserDetails(AuthService.getCurrentUser().id));
        } catch (error) {
            console.error('Error updating profile:', error);
        }
        setDetailsModalOpen(false);
        setSocialModalOpen(false);
        setModalContent(false);
    };

    const handleInputChange = (setter) => (event) => {
        setter(event.target.value);
    };

    const handleSave = (setterMode, updateFunc) => (event) => {
        event.preventDefault();
        updateFunc();
        setterMode(false);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    const changeView = () => {
        if (show === 'overview') {
            return (
                <>
                    <div className="right-edit-profile">
                        <div className="right-edit-profile-content">
                            <div className="right-edit-profile-image">
                                <label className="fileContainer ">

                                    <div className="add-prof mrgnFileCntnrEdtProf" >
                                        +
                                    </div>
                                    <input id="file-input" type="file" name="profile_image" accept="image/*" onChange={handleProfileImage}></input>

                                </label>

                                {
                                    showProfilePicture ?
                                        <><img id="preview profprvw" src={profileRender} /><div>
                                            <a ></a></div> </> :
                                        <> <img src={userDetails.profilePic ? userDetails.profilePic : "/assets/images/resources/usericon.png"}></img> </>
                                }

                                <p className='font-weight-bold mt-4' style={{ color: '#033347' }}>{firstName} {lastName}</p>
                            </div>
                            <div className="right-edit-profile-bio-top">
                                <p>Bio</p>
                                <p>
                                    <a href="#" className="text-color" onClick={editBioMode ? handleSave(setEditBioMode, updateProfile) : () => setEditBioMode(true)}>
                                        {editBioMode ? 'Done' : (aboutme ? 'Edit' : 'Add')}
                                    </a>
                                </p>
                            </div>
                            <div className="right-edit-profile-bio-after">
                                {editBioMode ? (
                                    <input
                                        ref={bioInputRef}
                                        type="text"
                                        className="inpt"
                                        id="DescribeYourself"
                                        placeholder="Describe yourself"
                                        value={aboutme}
                                        onChange={handleInputChange(setAboutme)}
                                    />
                                ) : (
                                    <p>{aboutme || 'Describe yourself'}</p>
                                )}
                            </div>
                            <div className="right-edit-profile-bio">
                                <div className="right-edit-profile-bio-top">
                                    <p>Details</p>
                                    <p className="">
                                        <a href="#" className="text-color"

                                            onClick={() => {
                                                setDetailsModalOpen(true);
                                            }}
                                        >
                                            {currentTown ? 'Edit' : 'Add'}
                                        </a>

                                        {DetailsModalOpen && <DetailsModal setDetailsModalOpen={setDetailsModalOpen} homeTown={homeTown} currentTown={currentTown} relationshipStatus={relationshipStatus} updateProfile={updateProfile} setRelationshipStatus={setRelationshipStatus} setCurrentTown={setCurrentTown} setHomeTown={setHomeTown} />}
                                    </p>
                                </div>
                                <div className="right-edit-profile-details">
                                    <ul>
                                        <li><p><i className="las la-home" aria-hidden="true" style={{ fontSize: '1.8rem' }} /> {currentTown ? currentTown : 'Add current country'}</p></li>
                                        <li><p><i className="las la-map-marker" style={{ fontSize: '1.8rem' }}></i>{homeTown ? homeTown : 'Add current city'}</p></li>
                                        <li><p><i className="lab la-gratipay" style={{ fontSize: '1.8rem' }} ></i>{relationshipStatus ? relationshipStatus : 'Add Relationship status'}</p></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="right-edit-profile-bio">
                                <div className="right-edit-profile-bio-top">
                                    <p>Hobbies</p>
                                    <p>
                                        <a href="#" className="text-color" onClick={editHobbiesMode ? handleSave(setEditHobbiesMode, updateProfile) : () => setEditHobbiesMode(true)}>
                                            {editHobbiesMode ? 'Done' : (interests ? 'Edit' : 'Add')}
                                        </a>
                                    </p>
                                </div>
                                <div className="right-edit-profile-bio-after">
                                    {editHobbiesMode ? (
                                        <input
                                            ref={hobbiesInputRef}
                                            type="text"
                                            className="inpt"
                                            id="Interests"
                                            placeholder="Enter your hobbies"
                                            value={interests}
                                            onChange={handleInputChange(setInterests)}
                                        />
                                    ) : (
                                        <p>{interests || 'Add your hobbies'}</p>
                                    )}
                                </div>
                            </div>
                            <div className="right-edit-more-details" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <p><span className="text-color">Private Account</span></p>
                                <label className="switch">
                                    <input type="checkbox" checked={isPrivate}  onChange={handleTogglePrivacy} />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                            <div className="right-edit-more-details">
                                <p><span className="text-color">Switch to Professional account</span></p>
                            </div>
                            <div className="right-edit-more-details">
                                <p><span className="text-color">Professional information settings</span></p>
                            </div>
                        </div>
                    </div>
                    <ConfirmModal
                    isOpen={modalContent.isOpen}
                    title={modalContent.title}
                    message={modalContent.message}
                    onClose={modalContent.onClose}
                    onConfirm={modalContent.onConfirm}
                />
                </>
            )
        }
        if (show === "social media") {
            return (
                <div className="right-edit-profile">
                    <div className="right-edit-profile-content">
                        <div className="right-edit-personal-information-top">
                            <h1>Social Media</h1>

                        </div>
                        <div className="right-edit-personal-information">
                            <div className="right-edit-profile-bio-top">
                                <p></p>
                                <p className="">
                                    <a href="#" className="text-color"

                                        onClick={() => {
                                            setSocialModalOpen(true);
                                        }}
                                    >
                                        {aboutme ? 'Edit' : 'Add'}
                                    </a>

                                    {SocialModalOpen && <SocialModal setSocialModelOpen={setSocialModalOpen} updateProfile={updateProfile} facebook={facebook} setFacebook={setFacebook} instagram={instagram} setInstagram={setInstagram} twitter={twitter} setTwitter={setTwitter} linkedin={linkedin} setLinkedin={setLinkedin} pinterest={pinterest} setPinterest={setPinterest} />}
                                </p>
                                {/*modal ends */}
                            </div>
                            <div className="right-edit-profile-details padding">
                                <div className="col-lg-1"></div>
                                <div >
                                    <div className="profsts">
                                        <ul className="social-list">
                                            <li className="social-item">
                                                <span className="icons"><i className="fa-brands fa-facebook-f fa-lg"></i></span>
                                                <a href={facebook} className="social-link" target="_blank" rel="noopener noreferrer">{facebook}</a>
                                            </li>
                                            <li className="social-item">
                                                <span className="icons"><i style={{ cursor: 'pointer' }} className="fa-brands fa-instagram fa-lg"></i></span>
                                                <a href={instagram} className="social-link " target="_blank" rel="noopener noreferrer">{instagram}</a>
                                            </li>
                                            <li className="social-item">
                                                <span className="icons"><i className="fa-brands fa-twitter fa-lg"></i></span>
                                                <a href={twitter} className="social-link" target="_blank" rel="noopener noreferrer">{twitter}</a>
                                            </li>
                                            <li className="social-item">
                                                <span className="icons"><i className="fa-brands fa-linkedin-in fa-lg"></i></span>
                                                <a href={linkedin} className="social-link" target="_blank" rel="noopener noreferrer">{linkedin}</a>
                                            </li>
                                            <li className="social-item">
                                                <span className="icons"><i className="fa-brands fa-pinterest fa-lg"></i></span>
                                                <a href={pinterest} className="social-link" target="_blank" rel="noopener noreferrer">{pinterest}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        if (show === "your details") {
            return (

                <div className="right-edit-profile">
                    <div className="right-edit-profile-content">
                        <div className="right-edit-personal-information-top">
                            <h1>Details</h1>
                        </div>
                        <div className="profile-edit-right">
                            <div className="profile-edit-bio-top">
                                <p className="profile-edit-link"
                                    onClick={() => {
                                        setDetailsModalOpen(true);
                                    }}
                                >
                                    {currentTown ? 'Edit' : 'Add'}
                                </p>

                                {DetailsModalOpen && <DetailsModal setDetailsModalOpen={setDetailsModalOpen} homeTown={homeTown} currentTown={currentTown} relationshipStatus={relationshipStatus} updateProfile={updateProfile} setRelationshipStatus={setRelationshipStatus} setCurrentTown={setCurrentTown} setHomeTown={setHomeTown} />}
                            </div>
                            <div className="profile-edit-details">
                                <ul>
                                    <li>
                                        <i className="las la-home profile-edit-icon" aria-hidden="true"></i>
                                        <span className="profile-edit-text">{currentTown ? currentTown : 'Add current country'}</span>
                                    </li>
                                    <li>
                                        <i className="las la-map-marker profile-edit-icon"></i>
                                        <span className="profile-edit-text">{homeTown ? homeTown : 'Add current city'}</span>
                                    </li>
                                    <li>
                                        <i className="lab la-gratipay profile-edit-icon"></i>
                                        <span className="profile-edit-text">{relationshipStatus ? relationshipStatus : 'Add Relationship status'}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>


            )
        }
        if (show === "your adress") {
            return (
                <div className="right-edit-profile">
                    <AddressCard />
                </div>
            );
        }
        return (<div className="right-edit-profile"></div>)
    }
    return (
        <>
            <AppHeader user={userDetails} />
            <div className="central-edit-profile">
                <div className="left-edit-profile">
                    <div>
                        <h1>Edit Profile</h1>
                        {
                            show === "overview" ? <div className="edit-profile-list active" onClick={() => setShow('overview')}><p>Overview</p></div> : <div className="edit-profile-list" onClick={() => setShow('overview')}><p>Overview</p></div>
                        }
                        {
                            show === "social media" ? <div className="edit-profile-list active" onClick={() => setShow('social media')}><p>Social Media</p></div> : <div className="edit-profile-list" onClick={() => setShow('social media')}><p>Social Media</p></div>
                        }
                        {
                            show === "your details" ? <div className="edit-profile-list active" onClick={() => setShow('your details')}><p>Details</p></div> : <div className="edit-profile-list" onClick={() => setShow('your details')}><p>Details</p></div>
                        }
                        {
                            show === "your adress" ? <div className="edit-profile-list active" onClick={() => setShow('your adress')}><p>My Adress</p></div> : <div className="edit-profile-list" onClick={() => setShow('your adress')}><p>My Adress</p></div>
                        }

                    </div>
                </div>
                {
                    changeView()
                }
            </div>
        </>
    );
}

