import React, { useEffect, useRef, useState } from 'react';
import '../../../../assets/css/BuyHang.css';
import PostServices from '../../../../services/post';
import { FaCheck, FaPlus } from 'react-icons/fa';
import Modal from '../../../elements/Modal';
import Loading from '../../../elements/Loading';
import constance from '../../../../config/contance'
import Alert from '../../../elements/Alert'

export default function BuyHang({ state, dispatch, onclose }) {
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedProvider, setSelectedProvider] = useState(null);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [categories, setcategories] = useState(state.categories);
    const [openItems, setOpenItems] = useState([]);
    const [activeProducts, setActiveProducts] = useState([]);
    const wrapperRef = useRef(null);
    const [openItemId, setOpenItemId] = useState(null);
    const buttonRef = useRef({});
    const itemsRefs = useRef({});
    const [isModalOpen, setModalOpen] = useState(false);
    const [loading, setloading] = useState(false)
    const { postTypes, folderNames } = constance;
    const [cart, setcart] = useState(state.hangShareCart)
    const [alertText, setAlertText] = useState('')
    const [showAlert, setShowAlert] = useState(false)
    const toggleModal = () => {
        setModalOpen(!isModalOpen);
        setSelectedProvider(null)
    };
    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target) &&
                !Object.values(buttonRef.current).some(ref => ref && ref.contains(event.target)) &&
                !Object.values(itemsRefs.current).some(ref => ref && ref.contains(event.target))) {
                setOpenItemId(null);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        activeProducts.forEach((pp) => {
            setcart(prev => {

                const isProductAlreadyInCart = prev.some(item => item.id === pp.id);
                if (!isProductAlreadyInCart) {
                    console.log('CART : ', pp.id, pp.count)
                    return [...prev, { id: pp.id, quantity: pp.count }];
                }

                return prev;
            });
        });
        dispatch({ type: 'set_hangShareCart', payload: cart })
    }, [activeProducts])

    const toggleItemView = (productId, event) => {
        event.stopPropagation();
        setOpenItemId(openItemId === productId ? null : productId);
    };
    async function getHangShareCategoryItems(cid) {
        setloading(true)
        const res = await PostServices.getHangShareCategoryItems(cid);
        console.log('itemsss : ', res.data);
        return res.data;
    }

    const handleCategorySelect = async category => {
        const response = await getHangShareCategoryItems(category.id);
        setSelectedProvider(response);
        setloading(false)
    };

    const toggleItemAdd = item => {
        const existingItem = activeProducts.find(product => product.id === item.id);
        if (!existingItem) {
            setActiveProducts(prev => [...prev, { ...item, count: 1 }]);
        }else{
            setActiveProducts(prev => prev.filter(product => product.id !== item.id));
        }
    };
    const handleProviderSelect = provider => {
        setSelectedProvider(provider);
        setSelectedProducts(provider.products);
    };

    const incrementCount = productId => {
        setActiveProducts(prevProducts =>
            prevProducts.map(product =>
                product.id === productId ? { ...product, count: (product.count || 1) + 1 } : product
            )
        );
        setcart(prevCart =>
            prevCart.map(product =>
                product.id === productId ? { ...product, count: (product.count || 1) + 1 } : product
            )
        );

    };
    const total = activeProducts.reduce((sum, product) => sum + (product.price * product.count), 0);

    const decrementCount = productId => {
        setActiveProducts(prevProducts =>
            prevProducts.reduce((acc, product) => {
                if (product.id === productId) {
                    if (product.count > 1) {
                        acc.push({ ...product, count: product.count - 1 });
                    }
                } else {
                    acc.push(product);
                }
                return acc;
            }, [])
        );

        setcart(prevProducts =>
            prevProducts.reduce((acc, product) => {
                if (product.id === productId) {
                    if (product.count > 1) {
                        acc.push({ ...product, count: product.count - 1 });
                    }
                } else {
                    acc.push(product);
                }
                return acc;
            }, [])
        );
    };
    const handleProductAdd = product => {
        setSelectedProducts(prevProducts => [...prevProducts, product]);
    };

    const groupData = {}
    const privacy_ = {
        value: state.privacy
    }
    const handleOrder = async () => {
        const postData = {
            postType: postTypes.HANG_SHARE,
            anonymous: state.anonymous,
            privacy: privacy_,
            groupId: groupData?.id, // for group posts
        };
        const formData = new FormData();
        
        formData.append('data', JSON.stringify(postData));
        PostServices.createPost(formData).then(res => {
            PostServices.addHangSharePostItems(cart, res.data.id).then(res => {
                console.log('item ekleme başarılı !!!', cart)
                setAlertText('Success')

            });
        });
        onclose()
    };

    return (
        <>
            {alertText && <Alert text={alertText}></Alert>}
            <div className='popwidth buyhangpopup'>

                <div className="">
                    <button type="button" className="close-button" onClick={onclose}>×</button>

                    <div className="category-selector">
                        <div className="categories">
                            {state.categories.map(category => (
                                <button type='button' className='categoryButton' key={category.id} onClick={() => { handleCategorySelect(category); toggleModal(); }}>
                                    {category.name}
                                </button>
                            ))}
                        </div>

                        {selectedCategory && (
                            <div className="providers">
                                {selectedCategory.map(provider => (
                                    <div key={provider.id} className="provider" onClick={() => { handleProviderSelect(provider); toggleModal(); }}>
                                        <img src={provider.imageUrl} alt={provider.name} />
                                        <span>{provider.name}</span>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    <Modal isOpen={isModalOpen} onClose={toggleModal}>
                        <ul className="products" style={{ marginTop: '20px' }}>
                            {loading ? <Loading /> : selectedProvider && selectedProvider.length !== 0 ? selectedProvider.map(product => (
                                <li key={product.id} ref={wrapperRef}>
                                    <div className="product">
                                        <img src={product.imageUrl} alt={product.name} style={{ width: "100px", height: "100px", objectFit: 'contain' }} />
                                        <span>{product.name} <br />{product.items.length} items</span>
                                        <button type="button" ref={el => buttonRef.current[product.id] = el} onClick={(event) => toggleItemView(product.id, event)}>+</button>
                                        {openItemId === product.id && product.items && (
                                            <ul className="product-items" style={{ marginLeft: "20px" }}>
                                                {product.items.length !== 0 ? product.items.map(item => (
                                                    <li ref={el => itemsRefs.current[item.id] = el} key={item.id} className="product-item">
                                                        <img src={item.imageUrl} alt={item.name} style={{ width: "100px", height: "100px", objectFit: 'contain' }} />
                                                        <span>{item.name} <br />{item.state}<br /><p style={{color:'black'}}>{item.price} coin</p> </span>
                                                        <button
                                                            ref={el => buttonRef.current[item.id] = el}
                                                            type='button'
                                                            onClick={() => toggleItemAdd(item)}
                                                            style={{ marginLeft: 'auto', backgroundColor: activeProducts.some(ap => ap.id === item.id) ? 'green' : 'rgba(0, 0, 0, 0.5)' }}
                                                        >
                                                            {activeProducts.some(ap => ap.id === item.id) ? <FaCheck /> : <FaPlus />}
                                                        </button>
                                                    </li>
                                                )) : 'not added yet'}
                                            </ul>
                                        )}
                                    </div>
                                </li>
                            )) : 'not added yet'}
                        </ul>            
                    </Modal>
                    {
                        activeProducts.length > 0 && (
                            <>
                                <h2 style={{ fontWeight: 'bold', fontSize: '30px', marginBottom: '10px' }} >Cart</h2>
                                <ul className="products">
                                    {activeProducts.map(product => (
                                        <li key={product.id} className="product">
                                            <img src={product.imageUrl} alt={product.name} style={{ width: "50px", height: "50px", objectFit: 'contain' }} />
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <div style={{ textAlign: 'left' }}>
                                                    <span style={{ color: 'black' }}>{product.name}</span>
                                                    <div style={{ color: 'black', fontWeight: 'bold' }}>${product.price}</div>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
                                                <button type="button" onClick={() => decrementCount(product.id)}>-</button>
                                                <div style={{ padding: '5px 10px', backgroundColor: 'red', color: 'white', marginLeft: '5px', marginRight: '5px', borderRadius: '5px' }}>
                                                    {product.count}
                                                </div>
                                                <button type="button" onClick={() => incrementCount(product.id)}>+</button>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                                <div style={{ marginTop: '20px' }}>
                                    {activeProducts.map(product => (
                                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', fontSize: '16px', fontWeight: 'bold' }}>
                                            <span>{product.name} x{product.count}</span>
                                            <span>{product.price.toFixed(2) * product.count} TL</span>
                                        </div>
                                    ))}
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', fontSize: '16px', fontWeight: 'bold' }}>
                                    <span>VAT 20%:</span>
                                    <span>{(total * 0.20).toFixed(2)} TL</span>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', fontSize: '20px', fontWeight: 'bold' }}>
                                    <span>Total Price:</span>
                                    <span>{(total * 1.20).toFixed(2)} TL</span>
                                </div>
                                <button onClick={handleOrder} type='button' style={{ padding: '10px 20px', fontSize: '18px', width: '100%', backgroundColor: '#033347', color: 'white', borderRadius: '5px', marginTop: '10px' }}>Buy and Share</button>
                            </>
                        )
                    }

                </div>
            </div>
        </>
    );
}
