import React from 'react';
import img1 from '../../../assets/images/news1.jpg';
import GroupsWidgetComponent from '../../widgets/GroupsWidgetComponent';
import FollowingWidgetComponent from '../../widgets/FollowingWidgetComponent';


export default function RightBar() {
    return (
        <aside className="sidebar static " style={{ marginLeft: 'auto', marginRight: '0' }}>
            {/* Trending news */}
            <div style={{ paddingBottom: '20px' }}>
                <div className="sidebar-news">
                    <div className="media-date">What's trending</div>
                    <ul>
                        {/* News items */}
                        <li>
                            <div className="headline-cont">
                                <p className="headline">
                                    Omicron variant of COVID-19: New travel guidelines to come into force from December 1
                                </p>
                                <img src={img1} />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <a href=" https://www.aljazeera.com/where/qatar/" target="_blank" className="source">Aljazeera Qatar News</a>
                                <p className="date">12/1/2021</p>
                            </div>
                        </li>
                    </ul>
                    {/* Show more link */}
                    <div style={{ textAlign: "center", paddingTop: '10px' }}>
                        <a href="https://www.aljazeera.com/" style={{ fontSize: '12px', color: "#258eae" }} target="_blank">Show More</a>
                    </div>
                </div>
            </div>
            {/* Groups */}
            <GroupsWidgetComponent/>
            <FollowingWidgetComponent/>
            
        </aside>
    );
}
