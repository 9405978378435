import React, { useEffect, useState } from 'react'
import Popup from 'reactjs-popup';
import Form from 'react-bootstrap/Form';
import PopAudience from './PopAudience';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import postService from '../../../../services/post';
import '../../../../assets/css/BuyHang.css';
import ImageUploader from './SwapImageUploader';
import contance from '../../../../config/contance';
import PostServices from '../../../../services/post'



export default function CreateSwapPost({ user, state, dispatch , refresh}) {

  const [swapLoad, setswapLoad] = useState(false)
  const [kontrol, setkontrol] = useState(false)
  const { postTypes, folderNames } = contance;
  const [myItems, setMyItems] = useState([]);
  const [requested, setRequested] = useState([]);
  const SWAP_DEFAULT_TEXT = 'Hi, i want to swap.'
  const [content, setContent] = useState('')
  const [swapCategory, setswapCategory] = useState('')
 

  const handlePostSubmit = async (e) => {

    e.preventDefault()

    dispatch({ type: 'set_anonymous', payload: false })
    console.log(state.privacy)
    const formData = new FormData();
    console.log(state.postType, state.privacy, state.feeling, state.anonymous)
    var imageFiles;
    const privacy_ = {
      value: state.privacy
    }
    const myItemsArray = Array.from(myItems);
    const requestedArray = Array.from(requested);
      imageFiles = myItemsArray.concat(requestedArray);
    


    const postContents = {
      content: state.content ? state.content :SWAP_DEFAULT_TEXT,
      privacy: privacy_,
      feeling: state.feeling,
      postType: 'Swap',
      anonymous: state.anonymous,
      swapCategoryId: swapCategory,
      groupId: state.groupId&&state.groupId,
    };
    formData.append('data', JSON.stringify(postContents));

    console.log('frup swap', postContents)

    for (const file of imageFiles) {
      formData.append('files', file); 
    }

    try {
      const response = await PostServices.createPost(formData, myItems.length)
      refresh()
      console.log('başarılı : ', response.data)
    } catch (error) {
      console.log("hata", error)
    }


  }

  const uploadSwap = () => {

  }
  const handleType = () => {
    console.log('handle type çalıştı')
    dispatch({ type: 'set_postType', payload: 'Swap' })
  }

  return (

    <Popup

      trigger={
        <span style={{ cursor: 'pointer', display: "flex", alignItems: "center" }} onClick={handleType}  >
          <span style={{ marginRight: '10px', padding: '5px' }}   >
            <img style={{ verticalAlign: 'middle', width: '30px',marginLeft: '27%' }} src='/assets/images/swap-icon3.png' alt='img' />
          </span>
          Swap
        </span>
      }
      modal
      nested
      closeOnDocumentClick
    >
      {(close) => (
        <Form className='popwidth' onSubmit={(e) => {
          close();  handlePostSubmit(e); 
        }}>
          <div className='headpop'>



            <div className='row'>

              <div style={{ width: '20%' }}>
                <a href='#!' style={{ padding: '10px 80px 10px 0' }}>
                  <i class='las la-times'></i>
                </a>
                {console.log(state.postType)}
              </div>
              <div
                style={{ color: '#000000', fontSize: '18px', fontWeight: 'bold', width: '60%', textAlign: 'center' }}
              >
                {' '}
                <span>Create Swap</span>
              </div>

            </div>
          </div>

          <div style={{ padding: '0 11px 11px 11px' }}>
            <div className='popupimg'>
              <img
                src={
                  user.profilePic ? user.profilePic : '/assets/images/vector-34@2x.png'
                }
                alt=''
                style={{ backgroundColor: '#033347' }}
              />
            </div>
            <div class='popupuser-name'>
              <div style={{ display: 'inline' }}>
                <span>
                  {`${user.firstName} ${user.lastName}`}
                </span>
                <span style={{ marginTop: '4px ', display: 'block', fontSize: '10px' }}>
                  <li style={{ paddingLeft: '0%', paddingTop: '1%', listStyleType: 'none' }}>
                    <PopAudience state={state} dispatch={dispatch} />
                  </li>
                </span>
              </div>{' '}
            </div>{' '}

          </div>
          <div className="category-selector">
            <div className="categories">
              <div class="mb-3 mr-2">

                <select
                  class="form-select form-select-sm"
                  name=""
                  id=""
                  onChange={e=>setswapCategory(e.target.value)}
                >
                  <option  selected>Select Swap Categories</option>
                  {state.swapCategories.map(item => (
                    <option value={item.id} >{item.name}</option>
                  ))}

                </select>
              </div>

            </div>
          </div>
          <div style={{ minHeight: '80px' }}>
            <span className='textPop'>
              <textarea
                className='textpopup'
                onChange={e => dispatch({ type: 'set_content', payload: e.target.value })}
                rows='3'
                style={{ borderRadius: '0' }}
                placeholder={'We share,do you?'}
                name='post_content'
                value={state.content}

              />

            </span>
          </div>


          {swapLoad ?
            (<div style={{ minHeight: '150px' }}>
              <span className='textPop'>

                <textarea
                  className='textpopup'
                  rows={2}
                  placeholder={'We share,do you?'}
                  name='swap_content'
                  value={content}
                  onChange={e => setContent(e.target.value)}
                />


              </span>
            </div>)
            : <ImageUploader swapLoad={setswapLoad} kontrol={setkontrol} myItemsSet={setMyItems}  requestedSet={setRequested} ></ImageUploader>}




          <button
            type='submit'
            value='Submit'
            className="popsbmt-btn"
            disabled={!kontrol}
          >
            SWAP 
          </button>
        </Form>
      )}
    </Popup>
  );
}
