import { authenticateAxios, authenticateMultipartAxios } from './authAxios';
import constants from '../config/contance';

const { activityTypes } = constants;



class StoriesService {

    addStory = async (data) => {
        try {
            const result = await authenticateMultipartAxios().post(`/stories`, data);
            return result;
        } catch (error) {
            console.log('addStory', error)
            return error
        }

    }

    uploadThumbnail = async (data) => {
        try {
            const result = await authenticateMultipartAxios().post(`/stories/upload_thumbnail/`, data);
            return result;
        } catch (error) {
            console.log('addStory', error)
            return error
        }

    }

    getStories = async () => {
        try {
            const res = await authenticateAxios().get(`/stories`);
            return res
        } catch (error) {
            console.log('getStories Error : ', error)
            return error
        }

    }

    deleteStory = async (sid) => {
        try {
              authenticateAxios().put(`stories/delete_story/${sid}`);
        } catch (error) {
            console.log('deleteStory error :', error)
            return error
        }

    }


    viewStory = async (sid) => {
        try {
              authenticateAxios().put(`stories/view_story/${sid}`);
        } catch (error) {
            console.log('viewStory error :', error)
            return error
        }

    }

    likeUnlikeStory = (sid, storyOwnerId) => {
        try {
            authenticateAxios().put(
                `stories/like_unlike/${sid}`,
                {},
                {
                    params: {
                        activityType: activityTypes.STORY_LIKE,
                        tokenOwner: storyOwnerId,
                    },
                },
            );
        } catch (error) {
            console.log('likeUnlikeStory error : ', error)
            return error
        }
    }




}

export default new StoriesService();