import React, { useEffect, useReducer } from 'react'
import NewPost from './NewPost';
import PostServices from '../../../../services/post';

function createPostReducer(state, action) {
    switch (action.type) {
        case 'set_privacy':
            return { ...state, privacy: action.payload };
        case 'set_content':
            return { ...state, content: action.payload };
        case 'set_feeling':
            return { ...state, feeling: action.payload };
        case 'set_postType':
            return { ...state, postType: action.payload };
        case 'set_anonymous':
            return { ...state, anonymous: action.payload };
        case 'set_swapCategoryId':
            return { ...state, swapCategoryId: action.payload };
        case 'set_group_id':
            return { ...state, groupId: action.payload };  // Düzeltme yapıldı
        case 'set_categories':
            return { ...state, categories: action.payload };
        case 'set_hangShareCart':
            return { ...state, hangShareCart: action.payload };
        case 'set_swapCategories':
            return { ...state, swapCategories: action.payload };
        case 'set_group_id':
            return { ...state, groupId: action.payload };
        default:
            throw new Error();
    }
}

const createPostInstance = {
    privacy: 'Public',
    content: null,
    feeling: null,
    postType: null,
    anonymous: null,
    swapCategoryId: null,
    groupId: null,
    categories: [],
    hangShareCart: [],
    swapCategories: []
}

export default function CreatePosts({ user, refresh, groupId }) {
    const [state, dispatch] = useReducer(createPostReducer, createPostInstance);

    useEffect(() => {
        async function fetchCategories() {

            try {
                const hangShareData = await PostServices.getHangShareCategories();
                const swapData = await PostServices.getSwapPostCategories();
                dispatch({type:'set_group_id',payload:groupId})
                dispatch({ type: 'set_categories', payload: hangShareData.data });
                dispatch({ type: 'set_swapCategories', payload: swapData.data });
                console.log('SWAP CATEGORİES : ', state.swapCategories)
            } catch (error) {
                console.error('Failed to fetch categories', error);
            }
        }

        fetchCategories();
    }, [])

    return <NewPost user={user} state={state} dispatch={dispatch} refresh={refresh} />;
}
