import React, { useRef, useState } from 'react'
import Popup from 'reactjs-popup';
import Form from 'react-bootstrap/Form';
import OwlCarousel from 'react-owl-carousel';
import PopAudience from './PopAudience';
import constants from '../../../../config/contance'
import PostServices from '../../../../services/post';
import { FaImage } from 'react-icons/fa';
import MenuItem from './MenuItem';


export default function AddPost({ user, state, dispatch, handleSubmit }) {
  const [showPostImage, setShowPostImage] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [uploadError, setUploadError] = useState()
  const [postContent, setpostContent] = useState('')
  const [postImage, setPostImage] = useState([]);
  const [postImages, setPostImages] = useState([]);
  const menuRef = useRef();
  const fileInputRef = useRef(null);
  const { privacy, content, feeling, postType, anonymous, swapCategoryId, groupId } = state;
  const SWAP_DEFAULT_TEXT = "swap_default_text"
  const { postTypes, folderNames } = constants;
  const groupData = {}
  const swapData = {
    myItems: []
  }

  const buttonRef = useRef();
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const imageURLs = files.map(file => URL.createObjectURL(file));
    setPostImages(imageURLs);
    setShowPostImage(true);
  };

  const handleRemoveImage = () => {
    setPostImages([]);
    setShowPostImage(false);
  };

  const handlePostSubmit = async (e) => {

    e.preventDefault()

    dispatch({ type: 'set_anonymous', payload: false })
    console.log(privacy)
    const formData = new FormData();
    const files = fileInputRef.current.files;
    console.log(postType, privacy, feeling, anonymous)

    const privacy_ = {
      value: privacy
    }

    const postContents = {
      content: postType === postTypes.SWAP && !content ? SWAP_DEFAULT_TEXT : content,
      privacy: privacy_,
      feeling: feeling,
      postType: postType,
      anonymous: anonymous,
      swapCategoryId: swapData?.swapCategory?.id,
      groupId: groupData?.id,
    };
    formData.append('data', JSON.stringify(postContents));

    for (const file of files) {
      formData.append('files', file); // Dosyaları form data'ya ekleyin
    }

    try {
      const response = PostServices.createPost(formData, swapData?.myItems.length)
      console.log('başarılı : ', response.data)
    } catch (error) {
      console.log("hata", error)
    }


  }
  const toggleMenu = (event) => {

    setShowMenu(!showMenu);
  };

  function menuImagesclick() {
    document.getElementById('fileInput').click()
  }

  const imageshowPost = () => {

 

  }

  
  const handleType = () => {
    console.log('handletype çalıştı')
    dispatch({type :'set_postType', payload : 'Normal'})
  }

  return (
    <Popup
      trigger={
        <div  className='textbox'>
          <span onClick={handleType}  style={{ cursor: 'pointer', padding: '5px' }}>We share,do you?</span>
        </div>
      }
      modal
      nested
    >
      {(close) => (
        <Form className='popform popwidth' onSubmit={(e) => {
          close(); handlePostSubmit(e);
        }}>
          <div className='headpop'>
            <div className='row'>
              <div style={{ width: '20%' }}>
                <a href='#!' style={{ padding: '10px 80px 10px 0' }} onClick={close}>
                  <i class='las la-times'></i>
                </a>
              </div>
              <div
                style={{ color: '#000000', fontSize: '18px', fontWeight: 'bold', width: '60%', textAlign: 'center' }}
              >
                {' '}
                <span>We share, do you</span>
              </div>
              <div style={{ width: '20%', textAlign: 'right' }}>
                <div style={{ position: 'relative', marginLeft: 'auto' }}>

                  <a href="#" className='popup-btn' ref={buttonRef} onClick={() => { toggleMenu(); }}>
                    +
                  </a>
                  {showMenu && (
                    <div ref={menuRef} style={{
                      position: 'absolute',
                      right: 0,
                      top: '100%',
                      backgroundColor: 'white',
                      borderRadius: '8px',
                      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                      width: '200px',
                      zIndex: 1500
                    }}>
                      <MenuItem icon={<FaImage />} text="Images" onClick={menuImagesclick} />

                    </div>
                  )}
                </div>

                <input
                  type="file"
                  ref={fileInputRef}
                  multiple
                  id="fileInput"
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                />
              </div>
            </div>
          </div>

          <div style={{ padding: '0 11px 11px 11px' }}>
            <div className='popupimg'>
              <img
                src={
                  user.profilePic ? user.profilePic : '/assets/images/vector-34@2x.png'
                }
                alt=''
                style={{ backgroundColor: '#033347' }}
              />
            </div>
            <div class='popupuser-name'>
              <div style={{ display: 'inline' }}>
                <span>
                  {`${user.firstName} ${user.lastName}`}
                </span>
                <span style={{ marginTop: '4px ', display: 'block', fontSize: '10px' }}>
                  <li style={{ paddingLeft: '0%', paddingTop: '1%', listStyleType: 'none' }}>
                    <PopAudience state={state} dispatch={dispatch} />
                  </li>
                </span>
              </div>{' '}
            </div>{' '}
          </div>

          <div style={{ minHeight: '150px' }}>
            <span className='textPop'>
              <textarea
                className='textpopup'
                onChange={e => dispatch({ type: 'set_content', payload: e.target.value })}
                rows={2}
                style={{ borderRadius: '0' }}
                placeholder={uploadError ? `${uploadError}` : 'We share,do you?'}
                name='post_content'
                value={content}

              />
              <div>
                {showPostImage && (
                  <div style={{ position: 'relative', padding: '5px' }}>
                    {postImages.length > 1 ? (
                      <OwlCarousel items={1} className="owl-theme grp-carousel post-carousel" nav center dots={false} margin={10}>
                        {postImages.map((image, key) => (
                          <img
                            src={image}
                            key={key}
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              borderRadius: '10px',
                              paddingBottom: '10px !important'
                            }}
                          />
                        ))}
                      </OwlCarousel>
                    ) : (
                      <img
                        src={postImages[0]}
                        style={{
                          display: 'inline-block',
                          verticalAlign: 'middle',
                          borderRadius: '10px',
                          width: 'fit-content',
                          maxHeight: '450px',
                          marginBottom: '10px!important'
                        }}
                      />
                    )}
                    <button onClick={handleRemoveImage} style={{
                      right: '20px',
                      top: '10px',
                      position: 'absolute',
                      borderRadius: '100%',
                      background: 'rgb(183 183 183 / 82%)',
                      padding: '10px 10px',
                      zIndex: '99',
                    }}>
                      <i className='las la-times'></i>
                    </button>
                  </div>
                )}
              </div>
            </span>
          </div>

          {imageshowPost()}
          <button
            type='submit'
            value='Submit'
            className="popsbmt-btn"
          // onClick={uploadPost}
          >
            POST
          </button>
        </Form>
      )}
    </Popup>
  )
}

