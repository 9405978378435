import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAndSetUserDetails } from '../../store/actions/authActions';
import { fetchFollowers, fetchConversations, startNewConversation, sendMessage } from '../../store/actions/chatActions';
import AppHeader from '../app/layout/AppHeader';
import AuthService from '../../services/auth.services';
import ConversationsList from './ConversationsList';
import ChatWindow from './ChatWindow';
import '../../assets/css/MessageComponent.css';
import socket from '../../services/socket';
import MessageServices from '../../services/MessageServices';
import encryptionService from '../../services/encryptionService';

export default function MessageComponent() {
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState('chats');
    const [messages, setMessages] = useState([]);
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [secretKeys, setSecretKeys] = useState({});
    const [searchTerm, setSearchTerm] = useState(''); // Arama terimi


    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.userDetails?.data);
    const conversations = useSelector(state => state.chat.conversations);
    const selectedConversation = useSelector(state => state.chat.selectedConversation);
    const followers = useSelector(state => state.chat.followers);
    const filteredFollowers = followers.filter(user =>
        `${user.firstName} ${user.lastName}`.toLowerCase().includes(searchTerm.toLowerCase())
    );

    useEffect(() => {
        const fetchDetails = async () => {
            try {
                const userId = AuthService.getCurrentUser().id;
                if (userId) {
                    await dispatch(fetchAndSetUserDetails(userId));
                }
            } catch (error) {
                console.error("fetchAndSetUserDetails err:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchDetails();
        dispatch(fetchConversations());
    }, [dispatch]);

    useEffect(() => {
        if (user?.id) {
            dispatch(fetchFollowers(user.id));
        }
    }, [user?.id, dispatch]);

    useEffect(() => {
        if (selectedConversation?.id) {
            const fetchMessages = async () => {
                try {
                    const response = await MessageServices.getMessages(selectedConversation.id, limit, offset);
                    setMessages(response);
                } catch (error) {
                    console.error('Error fetching messages:', error);
                }
            };

            fetchMessages();
        }
    }, [selectedConversation, limit, offset]);

    const handleConversationClick = async (conversation) => {
        console.log("SOHBET KONTROL", conversation)
        dispatch({ type: 'START_NEW_CONVERSATION', payload: conversation });
        const secretKey = await encryptionService.generateSecretKeys(conversation.publicKey);
        console.log('secret key üretildi : ',secretKey)
        setSecretKeys((prevKeys) => ({
            ...prevKeys,
            [conversation.id]: secretKey
        }));
    };

    const handleStartNewConversation = (user) => {
        dispatch(startNewConversation(user));
        setActiveTab('chats');
    };

    const handleSendMessage = (message) => {
        if (selectedConversation) {
            const secretKey = secretKeys[selectedConversation.id];
            dispatch(sendMessage(secretKey, message, selectedConversation.id, user.id, selectedConversation.user2 || selectedConversation.user1));
        }
    };

    useEffect(() => {
        socket.on('new_message', (data) => {
            if (data.conversationId === selectedConversation.id) {
                setMessages(prevMessages => [...prevMessages, data.message]);
            }
        });

        return () => {
            socket.off('new_message');
        };
    }, [selectedConversation]);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <AppHeader user={user} />
            <div className="message-container">
                <div className="tab-bar">
                    <button className={activeTab === 'chats' ? 'active' : ''} onClick={() => setActiveTab('chats')}>Chats</button>
                    <button className={activeTab === 'followers' ? 'active' : ''} onClick={() => setActiveTab('followers')}>Followers</button>
                    <button className={activeTab === 'groups' ? 'active' : ''} onClick={() => setActiveTab('groups')}>Groups</button>
                    <button className={activeTab === 'favorites' ? 'active' : ''} onClick={() => setActiveTab('favorites')}>Favorites</button>
                    <button className={activeTab === 'swaps' ? 'active' : ''} onClick={() => setActiveTab('swaps')}>Swap</button>
                </div>
                <div className="message-tab-content">
                    {activeTab === 'chats' && (
                        <div className="tab-pane active">
                            <div className="conversations-and-chat">
                                <ConversationsList
                                    conversations={conversations}
                                    selectedConversation={selectedConversation}
                                    onConversationClick={handleConversationClick}
                                    onStartNewConversation={() => setActiveTab('followers')}
                                />
                                {selectedConversation && (
                                    <ChatWindow
                                        conversation={selectedConversation}
                                        secretKey={secretKeys[selectedConversation.id]}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                    {activeTab === 'followers' && (
                        <div className="tab-pane active">
                            <div className="followers-list" style={{ width: '450px', height: '500px', overflowY: 'auto' }}>
                                <input
                                    className='search-message-list'
                                    type="text"
                                    placeholder="Search Friends..."
                                    value={searchTerm}
                                    onChange={e => setSearchTerm(e.target.value)}
                                />
                                {filteredFollowers.slice(0, 7).map(user => (
                                    <li key={user.id} className="follower-item">
                                        <div className="profile-pic-follow">
                                            <a href={`/profile/${user.id}`} title={`${user.id}`}>
                                                <img style={{ objectFit: 'cover' }} src={user.profilePic || '../../assets/images/unnamed.png'} alt="" />
                                            </a>
                                        </div>
                                        <div className="user-info-follow">
                                            <p className="name-tag-follow">
                                                <a href={`/profile/${user.id}`} title={`${user.id}`}>{`${user.firstName} ${user.lastName}`}</a>
                                            </p>
                                        </div>
                                        <div className="follow-button-follow">
                                            <button
                                                className='follow-toggle-button'
                                                onClick={() => handleStartNewConversation(user)}
                                            >
                                                New Message
                                            </button>
                                        </div>
                                    </li>
                                ))}
                                {filteredFollowers.length <=0  && (
                                    <div style={{ textAlign: 'center', padding: '10px' }}>
                                        User not found...

                                    </div>
                                )}

                            </div>
                        </div>
                    )}

                    {activeTab === 'groups' && (
                        <div className="tab-pane">
                            <h2>Groups</h2>
                            {/* Groups içeriği */}
                        </div>
                    )}
                    {activeTab === 'favorites' && (
                        <div className="tab-pane">
                            <h2>Favorites</h2>
                            {/* Favorites içeriği */}
                        </div>
                    )}
                    {activeTab === 'swaps' && (
                        <div className="tab-pane">
                            <h2>Swaps</h2>
                            {/* Swaps içeriği */}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
