import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAndSetUserDetails } from '../../store/actions/authActions';
import AuthServices from '../../services/auth.services';
import LayoutComponent from '../LayoutComponent';
import { fetchStories } from '../../store/actions/storyActions';
import post from '../../services/post';
import '../../assets/css/RedeemedItemCard.css';
import RedeemedItemCard from './RedeemedItemCard';


export default function RedeemItemComponent() {
    const [redeemedItems, setRedeemedItems] = useState([]);
    const userDetails = useSelector(state => state.auth.userDetails);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchAndSetUserDetails(AuthServices.getCurrentUser().id));
        dispatch(fetchStories());
        getRedeemedItems();
    }, [dispatch]);

    const getRedeemedItems = async () => {
        try {
            const response = await post.getRedeemedItems();
            setRedeemedItems(response.data);
        } catch (error) {
            console.log("getRedeemedItems Error", error);
        }
    };



    return (
        <>
            {userDetails && (
                <LayoutComponent user={userDetails.data}>
                    <div className='redeemed-item-title'>
                    <img  src="/assets/images/icon2.png" alt="Coin" className="redeemed-coin-icon" />
                    <h1 className="item-name">Remaining Shareup Coins: {userDetails.data.shareupCoins}</h1>
                    </div>

                    <div className="redeemed-items-list">
                        {redeemedItems.map(item => (
                            <RedeemedItemCard key={item.id} item={item} />
                        ))}
                    </div>
                </LayoutComponent>
            )}
        </>
    );
}
