import React from 'react';

const CustomStoryTextInput = ({ value, onChange, placeholder}) => {
  const inputStyle = {
    padding: '5px 20px',
    borderRadius: '10px',
    border: '1px solid #033347',
    outline: 'none',
    width: '100%',  
    boxSizing: 'border-box',
    backgroundColor: '#e0f2fa',
    marginTop:'10px',
    color: '#333',
    fontSize: '16px',
    height: '40px', 
  };

  return (
    <input
      type="text"
      style={inputStyle}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
    />
  );
};

export default CustomStoryTextInput;
