import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAndSetUserDetails } from '../store/actions/authActions';
import AuthService from '../services/auth.services';
import AppHeader from "../components/app/layout/AppHeader";
import LeftBar from './app/layout/LeftBar';
import RightBar from './app/layout/RightBar';
import fileStorage from '../config/fileStorage';
import ReelsServices from '../services/ReelsServices';

export default function Layout(props) {

  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.userDetails?.data);

  useEffect(() => {
    async function fetchDetails() {
      try {
        const userId = AuthService.getCurrentUser().id;
        if (userId) {
          await dispatch(fetchAndSetUserDetails(userId));
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchDetails();
  }, [dispatch]);



  useEffect(() => {
    setIsLoading(!props.user);
  }, [props.user]);



  const [refresh, setRefresh] = useState(null);
  const [reels, setReels] = useState([]);

  const [filesReel, setFilesReel] = useState({});
  const [ReelVideo, setReelVideo] = useState([]);
  const [ShowReelVideo, setShowReelVideo] = useState(false);
  const [uploadErrorReel, setUploadErrorReel] = useState('');
  const [reelPreviewPath, setReelPreviewPath] = useState([]);


  const uploadReels = (event) => {
    event.preventDefault();
    setUploadErrorReel('');
    console.log('uploading reels working');

    if (Object.keys(filesReel).length === 0 && filesReel.constructor === Object) {
      console.log('cant be null');
      setUploadErrorReel('Please Add reel video');
      console.log(uploadErrorReel);
      return;
    }

    var video = document.getElementById("video");
    const canvas = document.createElement("canvas");
    // scale the canvas accordingly
    canvas.width = video.videoWidth / 10;
    canvas.height = video.videoHeight / 10;
    // draw the video at that frame
    canvas.getContext('2d').drawImage(video, 10, 10);
    // convert it to a usable data URL
    const dataURL = canvas.toDataURL();
    var img = document.createElement("img");
    img.src = fileStorage.baseUrl + "/user-stories/1643529794109/Picture.jpg";


    const formData = new FormData();
    var content = "test";


    formData.append(`content`, content);
    formData.append(`reelfiles`, filesReel);


    ReelsServices.createReels(user.id, formData).then((res) => {
      console.log("jsonnn", JSON.stringify(res));
      handleRemoveReelVideo();
      setReels(res.data);
      setRefresh(res.data);

      console.log("response", reels);

    });


  };

  useEffect(() => {
    async function getPreviewReel() {
      try {
        const response = await ReelsServices.getPreviewReel(AuthService.getCurrentUser().username);
        setReelPreviewPath(response.data);
      } catch (error) {
        console.error('Error fetching reel preview:', error);
      }
    }

    getPreviewReel();
  }, [refresh]);

  const getPreviewReel = async () => {

    await ReelsServices.getPreviewReel(AuthService.getCurrentUser().username).then(res => {
      console.log("jsonnn", JSON.stringify(res));
      console.log(" This is the response", res.data)
      setReelPreviewPath(res.data)


    })
  }




  const handleFileReel = (event) => {

    setFilesReel(event.target.files[0]);
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setReelVideo(reader.result);
      }
    };
    console.log(event.target.files[0]);
    // if(event.target.files[0].type === blob){
    reader.readAsDataURL(event.target.files[0]);
    // }
    setShowReelVideo(true);


  };

  const handleRemoveReelVideo = () => {
    setFilesReel({});
    setShowReelVideo(false);
  };





  if (isLoading) {
    return null
  }
  const curdate = () => {
    let date = new Date()
    let dd = date.getDate()
    let mm = date.getMonth()
    let yy = date.getFullYear()
    return `${dd}/ ${mm}/ ${yy}`
  }
  return (
    props.user &&
    <>
      <AppHeader/>
      <div className="container">
        <section>
          <div className="gap gray-bg">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="row" id="page-contents">
                    <div className="col-lg-3">
                      <LeftBar user={user} />
                    </div>
                    <div className="col-lg-6">
                      {props.children}
                    </div>
                    <div className="col-3">
                      <RightBar />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}