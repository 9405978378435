import React, { useState } from 'react';
import AppHeader from '../app/layout/AppHeader';
import '../../assets/css/HelpAndSupportComponent.css';

const HelpAndSupportComponent = () => {
    const [activeSection, setActiveSection] = useState('faq');
    const [searchTerm, setSearchTerm] = useState('');
    const [chatVisible, setChatVisible] = useState(false);

    const faqItems = [
        { question: 'How do I create an account?', answer: 'To create an account, click on the "Sign Up" button and follow the instructions.' },
        { question: 'How do I reset my password?', answer: 'Click on the "Forgot Password" link on the login page and follow the instructions to reset your password.' },
    ];

    const filteredFaqItems = faqItems.filter(item =>
        item.question.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div>


            <AppHeader />
            <div className="help-and-support-container">

                <div className="content-wrapper">
                    <div className="sidebar-helpSupport">
                        <button
                            className={`sidebar-item ${activeSection === 'faq' ? 'active' : ''}`}
                            onClick={() => setActiveSection('faq')}
                        >
                            FAQ
                        </button>
                        <button
                            className={`sidebar-item ${activeSection === 'guides' ? 'active' : ''}`}
                            onClick={() => setActiveSection('guides')}
                        >
                            Guides
                        </button>
                        <button
                            className={`sidebar-item ${activeSection === 'contact' ? 'active' : ''}`}
                            onClick={() => setActiveSection('contact')}
                        >
                            Contact
                        </button>

                    </div>

                    <div className="main-content">
                        {/* <input 
            type="text" 
            placeholder="Ara..." 
            className="search-bar"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          /> */}

                        {activeSection === 'faq' && (
                            <div className="faq-section">
                                <h2>Frequently Asked Questions </h2>
                                {filteredFaqItems.map((item, index) => (
                                    <div key={index} className="faq-item">
                                        <h3>{item.question}</h3>
                                        <p>{item.answer}</p>
                                    </div>
                                ))}
                            </div>
                        )}

                        {activeSection === 'guides' && (
                            <div className="guides-section">
                                <h2>Guides</h2>
                                {/* Kılavuz içeriklerini buraya ekleyin */}
                            </div>
                        )}
                        {activeSection === 'contact' && (
                            <div className="contact-section">
                                <div className="contact-content">
                                    <h2>Contact</h2>
                                    <p>

                                        We're here to make your ShareUp experience even better! If you have any questions, suggestions, or need technical support, please don't hesitate to contact us.      </p>
                                    <p>
                                        To best assist you, please use the following contact information
                                    </p>
                                    <div className="contact-info">
                                        <div className="contact-item">
                                            <i className="fas fa-envelope"></i>
                                            <span>info@shareupx.com</span>
                                        </div>
                                        {/* İsterseniz telefon numarası veya diğer iletişim bilgilerini de ekleyebilirsiniz */}
                                    </div>
                                </div>
                            </div>
                        )}


                    </div>
                </div>


            </div>
        </div>
    );
};

export default HelpAndSupportComponent;