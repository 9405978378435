import { authenticateAxios } from './authAxios';

class FriendService {

    getFollowers = async (userId) => {
        try {
            const result = await authenticateAxios().get(`/followers/get_followers/${userId}`);
            console.log("FOLLOWİNGSS", result.data)
            return result.data;
        } catch (error) {
            console.error('Error getFollowers:', error);
            return error;
        }
    }

    getFollowings = async (userId) => {
        try {
            const result = await authenticateAxios().get(`/followers/get_followings/${userId}`);
            console.log("FOLLOWİNGSS", result.data)
            return result.data; 
        } catch (error) {
            console.error('Error getFollowings:', error);
            return error;
        }
    }


    //Direkt olarak takip etme servisi
    sendFollow = async (rid) => {
        console.log("GİDEN KULLANICI ", rid)
        try {
            const result = await authenticateAxios().post(`/followers/follow/${rid}`, {},
                {
                    params: {
                        tokenOwner: rid,
                    },
                },
            );
            return result;
        } catch (error) {
            console.error('Error sendFollow:', error);
            return error;
        }
    }


    //Gelen Takip İsteklerini Çekme
    getRecievedFollowRequests = async () => {
        try {
            const result = await authenticateAxios().get(`/followers/received_follow_requests`);
            return result;
        } catch (error) {
            console.error('Error getRecievedFollowRequests:', error);
            return error;
        }
    }


    //Bizi Takip edeni takipten çıkartma
    removeFollower = async (fid) => {
        try {
            const result = await authenticateAxios().delete(`/followers/remove_follower/${fid}`);
            return result;
        } catch (error) {
            console.error('Error removeFollower:', error);
            return error;
        }
    }

    //Takip ettiğimizi Takipten çıkartma
    unfollow = async (fid) => {
        try {
            const result = await authenticateAxios().delete(`/followers/unfollow/${fid}`);
            return result;
        } catch (error) {
            console.error('Error unfollow:', error);
            return error;
        }
    }


    //Gelen takip isteğini kabul eder
    acceptRequest = async (sid) => {
        try {
            const result = await authenticateAxios().post(`/followers/accept_follow_request/${sid}`);
            return result;
        } catch (error) {
            console.error('Error accepting friend request:', error);
            return error;
        }
    }

    //Takip isteğini reddeder
    declineRequest = async (sid) => {
        try {
            const result = await authenticateAxios().post(`/followers/reject_follow_request/${sid}`);
            return result;
        } catch (error) {
            console.error('Error declining friend request:', error);
            return error;
        }
    }


    //istek attıklarımızı çeker
    getSentFollowRequests = async () => {
        try {
            const result = await authenticateAxios().get('/followers/sent_follow_requests');
            return result;
        } catch (error) {
            console.error('Error getSentFollowRequests:', error);
            return error;
        }
    }

    getSuggestedFollows = async (offset = 0, limit = 10) => {
        try {
            const result = await authenticateAxios().get('/followers/suggested_follows', {
                params: { offset, limit }
            });
            return result;
        } catch (error) {
            console.error('Error getSuggestedFollows:', error);
            return error;
        }
    }

    rej


}

export default new FriendService();