import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import router from './routes/approuter';
import store from './store/store';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './index.css';
import * as serviceWorker from './serviceWorker';
import 'lightbox2/dist/css/lightbox.css';
import 'lightbox2/dist/js/lightbox';
import 'reactjs-popup/dist/index.css';
import 'react-phone-number-input/style.css'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './App.css';
import './assets/css/main.min.css';
import './assets/css/style.css';
import './assets/css/slider.css';
import './assets/css/color.css';
import './assets/css/responsive.css';
import './assets/css/fontawesome/css/all.min.css';
import './assets/css/styleguide.css';
import './assets/css/notifications.css';
import './assets/css/globals.css';
import './assets/css/emojionearea.min.css';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
        <Provider store={store}>
                <RouterProvider router={router} />
        </Provider>
);

serviceWorker.unregister();



