import { authenticateAxios } from './authAxios';

class GroupService {
    constructor() {
        this.authAxios = authenticateAxios();  
    }

    createGroup = async (data) => {
        try {
            const result = await this.authAxios.post(`groups/create`, data);
            return result;
        } catch (error) {
            console.error("Error creating group:", error);
            throw error;  
        }
    }

    editGroup = async (gid, data) => {
        console.log("GİDEN DATAA", data)
        try {
            const result = await this.authAxios.put(`groups/${gid}/edit_group`, data);
            return result;
        } catch (error) {
            console.error("Error editing group:", error);
            throw error;  
        }
    }

    deleteGroup = async (gid) => {
        try {
            const result = await this.authAxios.delete(`groups/delete/${gid}`);
            return result;
        } catch (error) {
            console.error("Error deleting group:", error);
            throw error;  
        }
    }

    addGroupImage = async (gid, data) => {
        try {
            const response = await this.authAxios.post(`groups/upload_image/${gid}`, data);
            return response;
        } catch (error) {
            console.error("Error uploading group image:", error);
            throw error;
        }
    }

    getMemberGroups = async () => {
        try {
            const result = await this.authAxios.get(`groups/member_groups`);
            return result;
        } catch (error) {
            console.error("Error fetching member groups:", error);
            throw error;
        }
    }

    getGroupsOfOwner = async () => {
        try {
            const result = await this.authAxios.get(`groups/owner_groups`);
            return result;
        } catch (error) {
            console.error("Error fetching groups of owner:", error);
            throw error;
        }
    }

    getGroupById = async (gid) => {
        try {
            const result = await this.authAxios.get(`groups/id/${gid}`);
            return result;
        } catch (error) {
            console.error("Error fetching group by ID:", error);
            throw error;
        }
    }

    getGroupUsers = async (gid) => {
        try {
            const result = await this.authAxios.get(`groups/${gid}/users`);
            return result.data; 
        } catch (error) {
            console.error("Error fetching group users:", error);
            throw error; 
        }
    }

    searchGroups = async (keyword) => {
        try {
            const result = await this.authAxios.get(`groups/search/${keyword}`);
            return result;
        } catch (error) {
            console.error("Error searching groups:", error);
            throw error;
        }
    }

    searchMembers = async (gid, keyword) => {
        try {
            const result = await this.authAxios.get(`groups/members_search/${gid}/${keyword}`);
            return result;
        } catch (error) {
            console.error("Error searching group members:", error);
            throw error;
        }
    }

    checkRole = async (gid) => {
        try {
            const result = await this.authAxios.get(`groups/role/${gid}`);
            return result;
        } catch (error) {
            console.error("Error checking role:", error);
            throw error;
        }
    }

    listOfRequests = async (gid) => {
        try {
            const result = await this.authAxios.get(`groups/${gid}/member_requests`);
            return result;
        } catch (error) {
            console.error("Error fetching list of requests:", error);
            throw error;
        }
    }

    getInviteSuggestions = async () => {
        try {
            const result = await this.authAxios.get(`groups/invite_suggestions`);
            return result;
        } catch (error) {
            console.error("Error fetching invite suggestions:", error);
            throw error;
        }
    }

    getGroupPosts = async (gid) => {
        try {
            const result = await this.authAxios.get(`groups/posts/${gid}`);
            return result;
        } catch (error) {
            console.error("Error fetching group posts:", error);
            throw error;
        }
    }

    getSingleGroupRequestCount = async (gid) => {
        try {
            const result = await this.authAxios.get(`groups/single_group_request_count/${gid}`);
            return result;
        } catch (error) {
            console.error("Error fetching single group request count:", error);
            throw error;
        }
    }

    getAllGroupsRequestCount = async () => {
        try {
            const result = await this.authAxios.get(`groups/all_groups_request_count`);
            return result;
        } catch (error) {
            console.error("Error fetching all groups request count:", error);
            throw error;
        }
    }

    getGroupInviteByUserId = async () => {
        try {
            const result = await this.authAxios.get(`groups/user/`);
            return result;
        } catch (error) {
            console.error("Error fetching group invite by user ID:", error);
            throw error;
        }
    }

    inviteToJoin = async (gid, fid) => {
        try {
            const result = await this.authAxios.post(`groups/${gid}/invite/${fid}`);
            return result;
        } catch (error) {
            console.error("Error inviting to join group:", error);
            throw error;
        }
    }

    joinGroup = async (gid) => {
        try {
            console.log("SERVİS KONTROL",gid )
            const result = await this.authAxios.post(`groups/join_group/${gid}`);
            return result;
        } catch (error) {
            console.error("Error joining group:", error);
            throw error;
        }
    }

    addMember = async (gid) => {
        try {
            const result = await this.authAxios.post(`groups/join/${gid}`);
            return result;
        } catch (error) {
            console.error("Error adding member:", error);
            throw error;
        }
    }

    blockMember = async (gid, uid) => {
        try {
            const result = await this.authAxios.put(`groups/${gid}/block/${uid}`);
            return result;
        } catch (error) {
            console.error("Error blocking member:", error);
            throw error;
        }
    }

    unblockMember = async (gid, uid) => {
        try {
            const result = await this.authAxios.put(`groups/${gid}/unblock/${uid}`);
            return result;
        } catch (error) {
            console.error("Error unblocking member:", error);
            throw error;
        }
    }

    setOwner = async (gid, oid) => {
        try {
            const result = await this.authAxios.put(`groups/${gid}/set_owner/${oid}`);
            return result;
        } catch (error) {
            console.error("Error setting owner:", error);
            throw error;
        }
    }

    setAdmin = async (gid, aid) => {
        try {
            const result = await this.authAxios.put(`groups/${gid}/set_admin/${aid}`);
            return result;
        } catch (error) {
            console.error("Error setting admin:", error);
            throw error;
        }
    }

    deleteAdmin = async (gid, aid) => {
        try {
            const result = await this.authAxios.put(`groups/${gid}/delete_admin/${aid}`);
            return result;
        } catch (error) {
            console.error("Error deleting admin:", error);
            throw error;
        }
    }

    acceptMemberRequest = async (rid) => {
        try {
            const result = await this.authAxios.put(`groups/accept_member_requests/${rid}`);
            return result;
        } catch (error) {
            console.error("Error accepting member request:", error);
            throw error;
        }
    }

    rejectMemberRequest = async (rid) => {
        try {
            const result = await this.authAxios.put(`groups/reject_member_requests/${rid}`);
            return result;
        } catch (error) {
            console.error("Error rejecting member request:", error);
            throw error;
        }
    }

    acceptInvitation = async (rid) => {
        try {
            const result = await this.authAxios.put(`groups/accept_invite/${rid}`);
            return result;
        } catch (error) {
            console.error("Error accepting invitation:", error);
            throw error;
        }
    }

    rejectInvitation = async (rid) => {
        try {
            const result = await this.authAxios.delete(`groups/reject_invite/${rid}`);
            return result;
        } catch (error) {
            console.error("Error rejecting invitation:", error);
            throw error;
        }
    }

    leaveGroup = async (gid) => {
        try {
            const result = await this.authAxios.delete(`groups/leave/${gid}`);
            return result;
        } catch (error) {
            console.error("Error leaving group:", error);
            throw error;
        }
    }

    deleteMember = async (gid, uid) => {
        try {
            const result = await this.authAxios.delete(`groups/${gid}/delete_member/${uid}`);
            return result;
        } catch (error) {
            console.error("Error deleting member:", error);
            throw error;
        }
    }
}

export default new GroupService();
