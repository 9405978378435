import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { FaEllipsisV, FaUserAlt, FaCog, FaSignOutAlt, FaStar } from 'react-icons/fa';
import { formatDistanceToNow } from 'date-fns';
import { FaRegBookmark, FaBookmark, FaRotate } from "react-icons/fa6";
import { BiHide } from "react-icons/bi";
import { LiaUserFriendsSolid } from "react-icons/lia";
import { GoReport, GoTrash } from "react-icons/go";
import ReportDialog from './ReportDialog';
import PostServices from '../../../../services/post'
import ConfirmModal from '../../../elements/ConfirmModal';
import { fetchAndSetUserDetails } from '../../../../store/actions/authActions';
import AuthService from '../../../../services/auth.services';

function PostUserContent({ post, myPost , type}) {
    const [showMenu, setShowMenu] = useState(false);
    const [reportDialogOpen, setReportDialogOpen] = useState(false);
    const [isSaved, setIsSaved] = useState(post.isSaved);
    const menuRef = useRef();
    const buttonRef = useRef();
    const [showConfirmDelete, setshowConfirmDelete] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userDetails = useSelector(state => state.auth.userDetails?.data);

    useEffect(() => {
        const fetchDetails = async () => {
            try {
                const userId = AuthService.getCurrentUser().id;
                if (userId) {
                    await dispatch(fetchAndSetUserDetails(userId));
                }
            } catch (error) {
                console.error("Error fetching user details:", error);
            }
        };
        fetchDetails();
    }, [dispatch]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (menuRef.current && !menuRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
                setShowMenu(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const toggleMenu = (event) => {
        event.stopPropagation();
        setShowMenu(!showMenu);
    };

    const openReportDialog = () => {
        setShowMenu(false);
        setReportDialogOpen(true);
    };

    const saveUnsavePostHandle = () => {
        setIsSaved(!isSaved);
        PostServices.saveUnsavePost(post.id);
    };

    const confirmDeleteHandle = () => {
        setShowMenu(false);
        setshowConfirmDelete(true);
    };

 

    const handleDeleteConfirm = () => {
        PostServices.deletePost(post.id);
        setshowConfirmDelete(false); 
    };

    const handleCloseModal = () => {
        setshowConfirmDelete(false);
    };

    const handleProfileClick = (event) => {
        event.preventDefault();
        if (userDetails.id === post.user.id) {
            navigate('/profile');
        } else {
            navigate(`/profile/${post.user.id}`);
        }
    };

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <figure>
                    <img src={post.user.profilePic} alt='' className="post-user-img" />
                </figure>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingLeft: '10px' }}>
                    <a href="#" onClick={handleProfileClick} title='#' style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>
                        {`${post.user.firstName} ${post.user.lastName}`}
                    </a>
                    <span style={{ display: 'block', fontSize: '12px', paddingTop: '5px' }}>
                        {formatDistanceToNow(new Date(post.createdAt), { addSuffix: true })}
                    </span>
                </div>
                <div style={{ position: 'relative', marginLeft: 'auto' }}>
                    <button ref={buttonRef} onClick={toggleMenu} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                        <FaEllipsisV />
                    </button>
                    {showMenu && (
                        <div ref={menuRef} style={{
                            position: 'absolute',
                            right: 0,
                            top: '100%',
                            backgroundColor: 'white',
                            borderRadius: '8px',
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                            width: '200px',
                            zIndex: 1500
                        }}>
                            <MenuItem icon={isSaved ? <FaBookmark /> : <FaRegBookmark />} text="Save Post" onClick={saveUnsavePostHandle} />
                            <MenuItem icon={<BiHide />} text="Hide My Profile" />
                            <MenuItem icon={<FaRotate />} text="Swap" />
                            <MenuItem icon={<LiaUserFriendsSolid />} text="Share Friends" />
                            {userDetails.id !== post.user.id && <MenuItem icon={<GoReport />} text="Report" onClick={openReportDialog} />}
                            {userDetails.id === post.user.id && <MenuItem style={{ color: 'red' }} icon={<GoTrash />} text="Delete" onClick={confirmDeleteHandle} />}
                        </div>
                    )}
                </div>
            </div>
            {showConfirmDelete && (
                <ConfirmModal
                    isOpen={showConfirmDelete}
                    title="Confirm Delete"
                    message="Are you sure you want to delete this post?"
                    onClose={handleCloseModal}
                    onConfirm={handleDeleteConfirm}
                />)}
            <ReportDialog open={reportDialogOpen} post={post} onClose={() => setReportDialogOpen(false)} />
        </div>
    );
}

function MenuItem({ icon, text, onClick, style }) {
    return (
        <div onClick={onClick} style={{
            display: 'flex',
            alignItems: 'center',
            padding: '10px',
            borderBottom: '1px solid #ccc',
            cursor: 'pointer',
            ...style
        }}>
            <span style={{ marginRight: '10px', fontSize: '16px', color: '#741ebd' }}>{icon}</span>
            <span style={{ fontSize: '16px', color: '#555' }}>{text}</span>
        </div>
    );
}

export default PostUserContent;
