import axios from 'axios';

const PEXEL_API_KEY = "xHmM7TLdhHTl4anlqJbspc4udOfkXOz8RPCoSMnMGDjcOWIfEQEOneQJ";

const fetchPhotos = async (search, page, cancelToken) => {
  const url = search
    ? `https://api.pexels.com/v1/search?query=${search}&per_page=10&page=${page}&orientation=landscape&size=small`
    : `https://api.pexels.com/v1/curated?page=${page}&per_page=10&orientation=landscape&size=small`;

  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: PEXEL_API_KEY,
      },
      cancelToken: cancelToken.token,
    });
    return response.data.photos;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('Request canceled', error.message);
    } else {
      console.error('Error fetching photos:', error.message);
    }
    throw error;
  }
};

export default fetchPhotos;
