import { authenticateAxios } from "./authAxios";
import constance from '../config/contance'
const { activityTypes } = constance;

class CommentsService {
    getAllComments = async (pid) => {
        try {
            const res = authenticateAxios().get(`comment/get_comment/${pid}`)
            return res
        } catch (error) {
            console.log('getAllComments Error : ', error)
        }
    }

    addComment = (postid, tokenOwner, comment) =>
        authenticateAxios().post(`comment/add_comment/${postid}`, comment, {
            params: { tokenOwner, activityType: activityTypes.POST_COMMENT },
        });


    likeUnlikeComment = async (cid, tokenOwner) => {
        try {
            const res = await authenticateAxios().put(`comment/like-unlike-comment/${cid}`,
            {},
            {
              params: {tokenOwner},
            },)
            return res
        } catch (error) {
            console.log('likeUnlikeComment error : ',error)
        }
    }




}

export default new CommentsService;