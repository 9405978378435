import React, { useState } from 'react';
import ImageModal from '../../../elements/ImageModal';

function PostImage({ media }) {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(0);

    const handleOpen = (index) => {
        setSelectedImage(index);
        setIsOpen(true);
    };

    const handleClose = () => setIsOpen(false);

    const renderImages = () => {
        const rows = [];
        let row = [];

        media.forEach((item, index) => {
          
            const flexValue = media.length === 1 || (index === 2 && media.length === 3) ? 1 : 0.5;

            row.push(
                <div key={item.id} style={{ flex: flexValue, overflow: 'hidden' }}>
                    <img
                        src={item.mediaUrl}
                        alt=""
                        style={{ width: '100%', height: '100%', objectFit: 'cover', transition: 'transform 0.3s ease-in-out', cursor: 'pointer' }}
                        onMouseEnter={e => e.currentTarget.style.transform = 'scale(1.05)'}
                        onMouseLeave={e => e.currentTarget.style.transform = 'scale(1)'}
                        onClick={() => handleOpen(index)}
                    />
                </div>
            );

            if (row.length === 2 || index === media.length - 1) {
                rows.push(<div key={index} style={{ display: 'flex', maxHeight: '200px', borderRadius: '0px', border: 'none', overflow: 'hidden' }}>{row}</div>);
                row = [];
            }
        });

        return rows;
    };

    return (
        <div>
            {renderImages()}
            {isOpen && (
                <ImageModal
                    media={media}
                    selectedIndex={selectedImage}
                    onClose={handleClose}
                />
            )}
        </div>
    );
}

export default PostImage;
