import React, {useEffect} from 'react';
import { RouterProvider,useNavigate } from 'react-router-dom';
import router from '../routes/approuter';
import AuthServices from '../services/auth.services';


function AuthChecker() {
  const navigate = useNavigate();

  useEffect(() => {
    if (AuthServices.isAuthenticated()) {
      navigate('/newsfeed');
    } else {
      navigate('/login');
    }
  }, [navigate]);

  return null; 
}

export default function App() {
  return (
    <>
      <AuthChecker />
    
    </>
  );
}