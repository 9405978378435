import React, { useState } from 'react';

const Modal = ({ isOpen, children, onClose }) => {
  


  if (!isOpen) return null;
  

  return (
    <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)',zIndex:'1800', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <div style={{ position: 'relative', padding: '10px', background: 'white', borderRadius: '8px', width: '100%',  maxWidth: '600px' }}>
        <button onClick={onClose}  style={{ position: 'absolute', top: '10px', right: '10px' }} className='close-button2'>×</button>
        {children}
        <div className="row">
        <button style={{marginLeft:'auto', height:'30px', borderRadius:'10px' }}  onClick={onClose} >Continue</button>
        </div>
       
      </div>
      
    </div>
  );
};

export default Modal;