import React, { useState, useEffect, useContext, useRef } from 'react';
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { fetchAndSetUserDetails } from '../../store/actions/authActions';

import UserService from '../../services/user';
import AuthService from '../../services/auth.services';
import FriendsService from '../../services/FriendService';
import LayoutComponent from '../LayoutComponent'
import { testScript } from '../js/script';
import settings from '../../services/Settings';
import fileStorage from '../../config/fileStorage';
import "./friends.css"
import FriendService from '../../services/FriendService';

function FriendsComponent() {


	const [refresh, setRefresh] = useState([]);
	const [searchedFriends, setSearchedFriends] = useState([]);
	const [searchedNonFriends, setSearchedNonFriends] = useState([]);
	const [searchedSentRequests, setSearchedSentRequests] = useState([]);
	const [searchedReceivedRequests, setSearchedReceivedRequests] = useState([]);
	const searchInputRef = useRef(null);

	// const []
	const [loading, setLoading] = useState(true);
	const [suggestedUser, setsuggestedUser] = useState([]);
	const [showComp, setShowComp] = useState("members");
	const [friendRequestSent, setFriendRequestSent] = useState([]);
	const [friendRequestRecieved, setFriendRequestRecieved] = useState([]);
	const dispatch = useDispatch();
	const user = useSelector(state => state.auth.userDetails?.data);


	useEffect(() => {
		const fetchDetails = async () => {
			try {
				const userId = AuthService.getCurrentUser().id;
				if (userId) {
					await dispatch(fetchAndSetUserDetails(userId));
				}
			} catch (error) {
				console.error("Error fetching user details:", error);
			} finally {
				setLoading(false);
			}
		};

		fetchDetails();
	}, [dispatch]);

	useEffect(() => {
		if (searchInputRef.current) {
			searchInputRef.current.value = "";
		}
		getSuggestedFollows();
		// getFriendsList();
		getAllFriendRequestSent();
		getAllFriendRequestRecieved();

	}, [showComp, refresh]);



	useEffect(() => {
		if (showComp === "friendRequestSent") {
			getAllFriendRequestSent();
		} else if (showComp === "friendRequestRecieved") {
			getAllFriendRequestRecieved();
		}
	}, [showComp]);

	useEffect(() => {
		const fetchUsers = async () => {
			try {
				if (suggestedUser && suggestedUser.length > 0) {
					const usersWithDefaults = suggestedUser.map(user => ({
						...user,
						followStatus: 'Follow',
						buttonColor: '#033347',
						color: '#ffffff',
					}));
					setSearchedNonFriends(usersWithDefaults);
				} else {
					console.log("No users found or empty response.");
				}
			} catch (error) {
				console.error('Error fetching users:', error);
			} finally {
				setLoading(false);
			}
		};
		fetchUsers();
	}, [suggestedUser])


	//Start Friends Services

	const acceptFriendRequest = (fid) => {
		FriendsService.acceptRequest(fid).then(res => {
			setRefresh(res.data)
		})
	}

	const declineFriendRequest = (fid) => {
		FriendsService.declineRequest(fid).then(res => {
			setRefresh(res.data)
		})
	}

	const unsendFriendRequest = async (fid) => {
		try {
			 const result = await FriendsService.unfollow(fid);
				console.log("Follow request unsent:", result);
				setRefresh(result.data)
		} catch (error) {
			
		}
		
	}

	const handleFriendRequest = async (user) => {
		console.log("Handling friend request for:", user.id);
	
		try {
			let result;
			if (user.followStatus === 'Follow') {

				result = await FriendsService.sendFollow(user.id);
					console.log("Followed successfully:", result);

				setSearchedNonFriends(prevUsers => prevUsers.map(u => u.id === user.id ? {
					...u,
					followStatus: user.isPrivate ? 'Unsend' : 'Unfollow',
					buttonColor: user.isPrivate ? '#eaeaea' : '#eaeaea',
					color: '#033347',
				} : u));
			} else if (user.followStatus === 'Unsend') {
				result = await FriendsService.unfollow(user.id);
				console.log("Follow request unsent:", result);
				setSearchedNonFriends(prevUsers => prevUsers.map(u => u.id === user.id ? {
					...u,
					followStatus: 'Follow',
					buttonColor: '#033347',
					color: '#ffffff',
				} : u));
			} else if (user.followStatus === 'Unfollow') {
				result = await FriendsService.unfollow(user.id);
				console.log("Unfollowed successfully:", result);
				setSearchedNonFriends(prevUsers => prevUsers.map(u => u.id === user.id ? {
					...u,
					followStatus: 'Follow',
					buttonColor: '#033347',
					color: '#ffffff',
				} : u));
			}
		} catch (error) {
			console.error('Error handling friend request:', error);
		}
	};
	

	const handleShowComp = () => {
		if (showComp === "members") {
			return FcomponentFunction()
		} else if (showComp === "friendRequestSent") {
			return FriendRequestSentComponentFunction()
		} else if (showComp === "friendRequestRecieved") {
			return FriendRequestRecievedComponentFunction()
		}
		else {
			return "<h1>None To Show</h1>"
		}
	}

	

	//End Friends Services

	//Start Get Friends





	// const getFriendsList = async () => {
	// 	await FriendsService.getFriends().then(res => {
	// 		setFriendsList(res.data)
	// 		setSearchedFriends(res.data)
	// 	})
	// }

	const getAllFriendRequestSent = async () => {
		try {
			const sentRequests = await FriendService.getSentFollowRequests();
			setFriendRequestSent(sentRequests.data);
			setSearchedSentRequests(sentRequests.data);
		} catch (error) {
			console.error('Error fetching friend requests sent:', error);
		}
	}

	const getAllFriendRequestRecieved = async () => {
		try {
			const receivedRequests = await FriendService.getRecievedFollowRequests();
			setFriendRequestRecieved(receivedRequests.data);
			setSearchedReceivedRequests(receivedRequests.data);
		} catch (error) {
			console.error('Error fetching friend request received:', error);
		}
	}

	const getSuggestedFollows = () => {
		FriendsService.getSuggestedFollows((0, 10)).then(res => {
			setsuggestedUser(res.data);
			console.log("ÖNERİLENLER", res.data)
		}).catch(err => {
			console.error('Error sending friend request:', err);
		});
	}
	//End Get Friends 

	//Search Start


	const handleSearchedUser = (event) => {
		const query = event.target.value.toLowerCase();
		if (query === "") {
			setSearchedNonFriends(suggestedUser);
		} else {
			const filtered = suggestedUser.filter(user =>
				user.firstName.toLowerCase().includes(query) ||
				user.lastName.toLowerCase().includes(query) ||
				user.email.toLowerCase().includes(query)
			);
			setSearchedNonFriends(filtered);
		}
	};

	const handleSearchedRequestsSent = (event) => {
		const query = event.target.value.toLowerCase();
		if (query === "") {
			setSearchedSentRequests(friendRequestSent);
		} else {
			const filtered = friendRequestSent.filter(request =>
				request.firstName.toLowerCase().includes(query) ||
				request.lastName.toLowerCase().includes(query) ||
				request.email.toLowerCase().includes(query)
			);
			setSearchedSentRequests(filtered);
		}
	};

	const handleSearchedRequestsReceived = (event) => {
		const query = event.target.value.toLowerCase();
		if (query === "") {
			setSearchedReceivedRequests(friendRequestRecieved);
		} else {
			const filtered = friendRequestRecieved.filter(request =>
				request.firstName.toLowerCase().includes(query) ||
				request.lastName.toLowerCase().includes(query) ||
				request.email.toLowerCase().includes(query)
			);
			setSearchedReceivedRequests(filtered);
		}
	};
	//Search End

	if (loading) {
        return <div>Loading...</div>;
    }

	//Lister Start
	const FcomponentFunction = () => {
		return (
			<div className="tab-content">
				<div className="friends-search-container grp-search">
					<input
						ref={searchInputRef}
						className="friend-search"
						type="text"
						id="header-search-users"
						placeholder="Search Users"
						name="searchUsers"
						onChange={(e) => handleSearchedUser(e)}
					/>
				</div>
				<div className="tab-pane active fade show " id="frends">
					<div className="scroll-container">
						<ul className="nearby-contct" style={{ marginTop: '15px' }}>
							{console.log("önerilen arkadaşlar", searchedNonFriends, "asdsadsad", suggestedUser)}
							{searchedNonFriends.length > 0 ?
								searchedNonFriends.slice(0, 10).map(userM => (
									<li key={userM.id} className="friends-card grp">
										<div className="grid-container">
											<div className="item1">
												<a href={`/profile/${userM.id}`} title={`${userM.id}`}>
													<img style={{ objectFit: 'cover' }} src={userM.profilePic ? `${fileStorage.baseUrl}${userM.profilePic}` : '../../assets/images/unnamed.png'} alt="" />
												</a>
											</div>
											<div className="item2">
												<p className="nameTag">
													<a href={`/profile/${userM.id}`} title={`${userM.id}`}>{`${userM.firstName} ${userM.lastName}`}</a>
												</p>
												<div style={{ fontSize: '12px', paddingTop: '5px' }}>Recommended</div>
											</div>
											<div className="item4">
												<button
													className='friend-request-button'
													onClick={() => handleFriendRequest(userM)}
													style={{ backgroundColor: userM.buttonColor, color: userM.color }} // Dinamik arka plan rengi
												>
													{userM.followStatus}
												</button>
											</div>
										</div>
									</li>
								))
								:
								<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', minHeight: '100px' }}>
									<h2>No Users Found</h2>
								</div>
							}
						</ul>
					</div>
					<div className="lodmore"><button className="btn-view btn-load-more" onClick={setRefresh} /></div>
				</div>
			</div>
		);
	};


	const FriendRequestSentComponentFunction = () => {
		return (
			<div className="tab-content">
				<div className="friendss-search-container grp-search">
					<button className="back-button" onClick={() => setShowComp("members")}>
						<i className="fas fa-arrow-left"></i>
					</button>
					<input
						ref={searchInputRef}
						className="friend-search"
						type="text"
						id="header-search-requests-sent"
						placeholder="Search Sent Requests"
						name="searchRequestsSent"
						onChange={(e) => handleSearchedRequestsSent(e)}
					/>
				</div>
				<div className="tab-pane active fade show " id="following">
					<ul className="nearby-contct" style={{ marginTop: '15px' }}>
						{searchedSentRequests.map(userM => (
							<li key={userM.id} className="friends-card grp">
								<div className="grid-container">
									<div className="item1">
										<a href={`/profile/${userM.id}`} title={`${userM.id}`}>
											<img style={{ objectFit: 'cover' }} src={userM.profilePic ? `${fileStorage.baseUrl}${userM.profilePic}` : 'assets/images/unnamed.png'} alt="" />
										</a>
									</div>
									<div className="item2">
										<p className="nameTag">
											<a href={`/profile/${userM.id}`} title={`${userM.id}`}>{`${userM.firstName} ${userM.lastName}`}</a>
										</p>
										<div style={{ fontSize: '12px', paddingTop: '5px' }}>Sent Requests</div>
									</div>
									<div className="item4">
										<a
											href="#!"
											className="button add-butn more-action cancel-request-button"
											onClick={() => unsendFriendRequest(userM.id)}
											style={{marginTop: '21%'}}
										>
											Cancel Request
										</a>
										</div>
								</div>
							</li>
						))}
					</ul>
					<div className="lodmore"><button className="btn-view btn-load-more" onClick={setRefresh} /></div>
				</div>
			</div>
		);
	};

	const FriendRequestRecievedComponentFunction = () => {
		return (
			<div className="tab-content">
				<div className="friendss-search-container grp-search">
					<button className="back-button" onClick={() => setShowComp("members")}>
						<i className="fas fa-arrow-left"></i>
					</button>
					<input
						ref={searchInputRef}
						className="friend-search"
						type="text"
						id="header-search-requests-received"
						placeholder="Search Received Requests"
						name="searchRequestsReceived"
						onChange={(e) => handleSearchedRequestsReceived(e)}
					/>
				</div>
				<div className="tab-pane active fade show" id="following">
					<ul className="nearby-contct" style={{ marginTop: '15px' }}>
						{searchedReceivedRequests.map(userM => (
							<li key={userM.id} className="friends-card grp">
								<div className="grid-container">
									<div className="item1">
										<a href={`/profile/${userM.id}`} title={`${userM.id}`}>
											<img style={{ objectFit: 'cover' }} src={userM.profilePic ? `${fileStorage.baseUrl}${userM.profilePic}` : 'assets/images/unnamed.png'} alt="" />
										</a>
									</div>
									<div className="item2">
										<p className="nameTag">
											<a href={`/profile/${userM.id}`} title={`${userM.id}`}>{`${userM.firstName} ${userM.lastName}`}</a>
										</p>
										<div style={{ fontSize: '12px', paddingTop: '5px' }}>Friend request</div>
									</div>
									<div className="item4">
										<a href="#!" className="button add-butn more-action" data-ripple style={{ background: '#EAEAEA', color: '#000000', fontSize: '12px' }} onClick={acceptFriendRequest(userM.id)}>
											Accept
										</a>
										<a href="#!" className="button add-butn more-action" data-ripple style={{ background: '#FF6347', color: '#FFFFFF', fontSize: '12px' }} onClick={() => declineFriendRequest(userM.id)}
										>
											Decline
										</a>
									</div>
								</div>
							</li>
						))}
					</ul>
					<div className="lodmore"><button className="btn-view btn-load-more" onClick={setRefresh} /></div>
				</div>
			</div>
		);
	};

	//Lister End
	return (
		<LayoutComponent user={user}>
				<div className="central-meta swap-pg-cont">
					<div className="frnds">
						{/* <ul className="nav nav-tabs"> */}
						<div >
							<p className="Friends-Title">Friends</p>
							<i style={{ float: "right", fontSize: 20 }} className="fas fa-ellipsis-v"></i>
						</div>
						<div className="navContent">

							<ul className="nav nav-pills swap-page-nav" role="tablist">


								<li className="nav-item" style={{ justifyContent: 'flex-start' }} >
									<div className="all" onClick={() => setShowComp("friendRequestRecieved")}>
										<span style={{ cursor: 'pointer' }}  >
											<span style={{ padding: '5px' }}>
												<i className="las la-user" style={{ fontSize: '20px' }}></i>
											</span>
											Requests
										</span>
									</div>
									{/* <span>{`${allUser.length}`}</span> */}
								</li>


								<li className="nav-item" style={{ justifyContent: 'flex-end' }}>
									<div className="new" onClick={() => setShowComp("friendRequestSent")}>
										<span style={{ cursor: 'pointer' }} >
											<span style={{ padding: '5px' }}>
												<i className="las la-user-tag" style={{ fontSize: '20px' }}></i>
											</span>
											Sent Requests
										</span>
									</div>
								</li>
							</ul>
						</div>

						{/* <li className="nav-item"><a className="" href="#!" onClick={() => setShowComp("members")}
														data-toggle="tab">Members</a> <span>{`${allUser.length}`}</span></li>
													<li className="nav-item"><a className="" href="#!" onClick={() => setShowComp("following")}
														data-toggle="tab">Following</a> <span>{`${following.length}`}</span></li>
													<li className="nav-item"><a className="" href="#!" onClick={() => setShowComp("followers")}
														data-toggle="tab">Followers</a> <span>{`${followers.length}`}</span></li> */}
						{/* <li className="nav-item"><a className="" href="#!" onClick={() => setShowComp("friendRequestSent")}
														data-toggle="tab">Request Sent</a><span>{`${friendRequestSent.length}`}</span></li> */}
						{/* <li className="nav-item"><a className="" href="#!" onClick={() => setShowComp("friendRequestRecieved")}
														data-toggle="tab">Request Recieved</a><span>{`${friendRequestRecieved.length}`}</span></li> */}
						{/* </ul> */}
						{/* <br/> */}
						{handleShowComp()}
					</div>
				</div>

		</LayoutComponent>
	);
}
export default FriendsComponent;