import { createStore, applyMiddleware, combineReducers } from 'redux';
import {thunk} from 'redux-thunk';

import authReducer from './reducers/authReducer';
import storyReducer from './reducers/storyReducer'
import postReducer from './reducers/postReducer';
import commentReducer from './reducers/commentReducer';
import chatReducer from './reducers/chatReducer';


const rootReducer = combineReducers({
  auth: authReducer,
  story: storyReducer,
  post: postReducer,
  comment:commentReducer,
  chat: chatReducer
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
