
export default function MenuItem({ icon, text, onClick, style }) {
    return (
      <div onClick={onClick} style={{
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
        borderBottom: '1px solid #ccc',
        cursor: 'pointer',
        ...style
      }}>
        <span style={{ marginRight: '10px', fontSize: '16px', color: '#741ebd' }}>{icon}</span>
        <span style={{ fontSize: '16px', color: '#555' }}>{text}</span>
      </div>
    );
  }