import React from 'react';


const ReelItem = ({ reel }) => {
  return (
    <div className="reel-item">
      <video className="reel-video" controls>
        <source src={reel.videoUrl} type="video/mp4" />
        Tarayıcınız video etiketini desteklemiyor.
      </video>
      <div className="reel-info">
        <h2>{reel.title}</h2>
        <p>{reel.description}</p>
      </div>
    </div>
  );
};

export default ReelItem;