import UserService from '../../services/user';



export const SET_USER = 'SET_USER';
export const SET_USER_DETAILS = 'SET_USER_DETAILS';


export const fetchAndSetUserDetails = () => async (dispatch) => {
  try {
    const userDetails = await UserService.getCurrentUser();
    dispatch(setUserDetails(userDetails));
  } catch (error) {
    console.error("fetchAndSetUserDetails ERROR : ", error);
    
  }
};

export const setUser = (userData) => async (dispatch) => {
  console.log('setUser redux: ',userData)
  dispatch({
    type: SET_USER,
    payload: userData,
  });

  
};
export const setUserDetails = (userDetails) => ({
  type: 'SET_USER_DETAILS',
  payload: userDetails,
});