import React, { useState } from 'react'
import { FaArrowsRotate } from "react-icons/fa6";
import '../../../../assets/css/Swap.css'
import { CiMobile2 } from "react-icons/ci";
import ImageModal from '../../../elements/ImageModal';


export default function Swap({ post }) {

    const [swapRequestedMedia, setswapRequestedMedia] = useState(post.swapMedia ? post.swapMedia : [])
    const [swapOwnedMedia, setswapOwnedMedia] = useState(post.media ? post.media : [])
    const [swapCategory, setswapCategory] = useState({})
    const [media, setmedia] = useState([])
    const [isOpen, setisOpen] = useState(false)
    const [selectedImage, setselectedImage] = useState([])


    function contactHandle() {

    }

    function sendofferHandle(params) {

    }

    function requestedImgHandle() {
        console.log('requestedImgHandle',swapRequestedMedia)
        setmedia(swapRequestedMedia)
        setisOpen(true)
        setselectedImage(0)
    }

    function ownedImgHandle() {
        console.log('ownedImgHandle',swapOwnedMedia)
        setmedia(swapOwnedMedia)
        setisOpen(true)
        setselectedImage(0)
    }

    const handleClose = () => setisOpen(false);





    return (
        <div>
            <div className='swaprow' >
                <div className="col-md-5">
                    <div className="image-container" onClick={ownedImgHandle}>
                        <img src={swapOwnedMedia[0].mediaUrl && swapOwnedMedia[0].mediaUrl} className='image-fluid' alt="" />
                    </div>
                </div>
                <div className="col-md-2 icon-container">
                    <FaArrowsRotate />
                </div>
                <div className="col-md-5">
                    <div className="image-container" onClick={requestedImgHandle}>
                        <img src={swapRequestedMedia[0].mediaUrl && swapRequestedMedia[0].mediaUrl } className='image-fluid' alt="" />
                    </div>
                </div>
            </div>
            <div className="swap-categories" style={{padding:'10px'}}>
                <div className="swap-category-icon">
                    <CiMobile2 />
                </div>
                <span className="category-name" >{post.swapCategory.name}</span>
                
            </div>
            {isOpen && (
                <ImageModal
                    media={media}
                    selectedIndex={selectedImage}
                    onClose={handleClose}
                />
            )}
        </div>
    )
}
