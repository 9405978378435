import React, { useState, useRef, useEffect } from 'react'
import Layout from './Layout'
import { useNavigate } from 'react-router-dom'
import { NavLink } from 'react-router-dom';
import AuthService from '../../services/auth.services';
import { useDispatch } from 'react-redux';
import { setUser } from '../../store/actions/authActions';
import { gapi } from 'gapi-script';
import { auth, provider, signInWithPopup } from '../../services/GoogleLoginConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import Typewriter from 'typewriter-effect';
import logo from '../../assets/images/logo/kare.png'
import leftImage from '../../assets/images/loginimage.png'
import playStore from '../../assets/images/qr/play.jpg'
import appStore from '../../assets/images/qr/app.jpg'
import shareupxlogo from '../../assets/images/shareupx_logo.jpg'
import '../../assets/css/login.css';
import TermsModal from './TermsModal';

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

export default function Login() {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  //state
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loginError, setLoginError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [showPassword, setShowPassword] = useState(false);
  const [featureIndex, setFeatureIndex] = useState(-1); // featureIndex'i state olarak tanımla
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);

  //ref
  const emailRef = useRef(null)
  const passwordRef = useRef(null)
  const emailEmptyRef = useRef(null)
  const passwordEmptyRef = useRef(null)
  const emailInputErrorRef = useRef(null)
  const passwordInputErrorRef = useRef(null)
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  useEffect(() => {
    const start = () => {
      gapi.client.init({
        clientId: clientId,
        scope: ''
      });
    };
    gapi.load('client:auth2', start);
  }, []);

  const features = [
    "Secure Messaging: End-to-end Encryption",
    "Swap: Trade and Exchange Items Seamlessly",
    "Social Networking: Personalized News Feed",
    "Hang Share: Buy and Giveaway Items with Ease",
  ];

  const handleTermsChange = () => {
   
        setShowTermsModal(true);
    
};




  // function showFeatureText(features, containerSelector) {
  //   const container = document.querySelector(containerSelector);
  //   const sentenceElement = container.querySelector('.sentence') || container.querySelector('.sentence-mobile');

  //   let featureIndex = -1;

  //   function nextFeatureText() {
  //     featureIndex = (featureIndex + 1) % features.length;
  //     const feature = features[featureIndex];

  //     deleteSentence(sentenceElement).then(async () => {
  //       await typeSentence(feature, sentenceElement); // sentenceElement'i doğrudan gönder

  //       await waitForMs(1000);

  //       deleteSentence(sentenceElement).then(() => {
  //         nextFeatureText(); 
  //       });
  //     });
  //   }

  //   nextFeatureText();
  // }
  // useEffect(() => {
  //   showFeatureText(features, '.features-animation');
  //   showFeatureText(features, '.features-animation-mobile');
  // }, []);

  // async function typeSentence(sentence, eleRef) { // eleRef artık bir element
  //   const letters = sentence.split(""); 
  //   let i = 0;
  //   while (i < letters.length) {
  //     await waitForMs(100);
  //     eleRef.append(letters[i]); // eleRef üzerinde append metodunu kullan
  //     i++;
  //   }
  // }
  // function waitForMs(ms) {
  //   return new Promise(resolve => setTimeout(resolve, ms));
  // }

  // deleteSentence fonksiyonu
  // async function deleteSentence(eleRef) {
  //   const sentence = eleRef.innerHTML; // eleRef'in innerHTML'ini kullan
  //   const letters = sentence.split("");
  //   let i = 0;
  //   while (letters.length > 0) {
  //     await waitForMs(50);
  //     letters.pop();
  //     eleRef.innerHTML = letters.join(""); // eleRef'in innerHTML'ini güncelle
  //   }
  // }

  const handleClick = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const idToken = await result.user.getIdToken();
      try {
        const result = await AuthService.alternativeLogin({ idToken });
        console.log(result);
        console.log('Login successful with JWT:', result.jwt);
        navigate('/newsfeed');
      } catch (error) {
        console.error("Login failed:", error.message);
      }
    } catch (error) {
      console.error("Google sign in failed:", error);
    }
  }

  const handleLogin = async () => {
    console.log("working");

    await AuthService.login(email, password).then(res => {
      console.log(res.data + " THIS IS THE DATA");
      navigate("/newsfeed");
    },
      error => {
        const resMessage = (error.response && error.response.data && error.response.data.message)
          || error.message || error.toString();

        // Check for error.response existence
        if (error.response) {
          console.log(error.response.status);
          if (error.response.status === 401) {
            console.log(' emailOTPVerification 401', error.response)
            navigate("/email-verification");
          } else {
            setLoginError("Incorrect Email and or Password");
          }
        } else {
          // Log for cases when server doesn't respond
          console.log('No server response:', resMessage);
          setLoginError(resMessage);
        }
      });
  }


  // This function checks the validity of the email address
  const emailIsValid = email => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }


  const validateLogin = (event) => {
    event.preventDefault();
    let validated = true;
    setEmailError('');
    setLoginError('');
    emailEmptyRef.current.innerHTML = "";
    passwordEmptyRef.current.innerHTML = "";
    if (!emailRef.current.value) {
      emailEmptyRef.current.innerHTML = "Please enter email";
      emailInputErrorRef.current.style.visibility = "visible";
      validated = false;
    } else if (!emailRef.current.value.includes('@')) {
      emailEmptyRef.current.innerHTML = "Please include @";
      validated = false;
    }

    if (!emailIsValid(email)) {
      setEmailError("Invalid email format");
      validated = false;
    }

    if (!passwordRef.current.value) {
      passwordEmptyRef.current.innerHTML = "Please enter password";
      passwordInputErrorRef.current.style.visibility = "visible";
      validated = false;
    }

    if (validated) {
      handleLogin();
    }
  };





  return (
    <Layout>
      <div className="row justify-content-center">
        <div className="col-6 col-md-6 hide-on-mobile" style={{ marginTop: '1%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <img src={shareupxlogo} alt="" width='500px' />
          <p style={{ fontSize: '45px', color: 'black' }}>We Share, Do You?</p>
          <div className="features-animation">
            {/* 
            <Typewriter
              options={{
                strings: [
                  "<span class='typewriter'>Secure Messaging:</span> End-to-end Encryption",
                  "<span class='typewriter'>Swap:</span> Trade and Exchange Items Seamlessly",
                  "<span class='typewriter'>Social Networking:</span> Personalized News Feed",
                  "<span class='typewriter'>Hang Share:</span> Buy and Giveaway Items with Ease",
                ],
                autoStart: true,
                loop: true,

              }}
            />
            */ }

          </div>
          <img src={leftImage} alt="" width='500px' className="hide-on-mobile" />
        </div>

        <div className="col-lg-6 col-md-6 " style={{ display: 'inline-block' }}>
          <div className="log-area-2">

            <div className="log-reg-areaa">

              <div className=" col-md-6 hide-off-mobile" style={{ maxWidth: '100%' , display: 'flex', justifyContent: 'center', flexWrap: 'wrap', alignItems: 'center', flexDirection: 'column' }} >
                <img src={shareupxlogo} alt="" width="500px" />
                <p style={{ fontSize: '23px', color: 'black' }}>We Share, Do You?</p>
                <div className="features-animation-mobile">
                  {/*
                <Typewriter
                  options={{
                    strings: [
                      "<span class='typewriter'>Secure Messaging:</span> End-to-end Encryption",
                      "<span class='typewriter'>Swap:</span> Trade and Exchange Items Seamlessly",
                      "<span class='typewriter'>Social Networking:</span> Personalized News Feed",
                      "<span class='typewriter'>Hang Share:</span> Buy and Giveaway Items with Ease",
                    ],
                    autoStart: true,
                    loop: true,
                  }}
                /> */ }
                </div>
              </div>

              <div className="logo hide-on-mobile" >
                <a href="/"><img src={logo} alt="" width='50px' height='50px' /></a>
              </div>
              <p>

                {loginError &&
                  <div style={{ fontSize: 20, color: 'red', textAlign: 'center' }}>
                    {loginError}
                  </div>
                }

              </p>
              {emailError &&
                <p style={{ fontSize: 15, color: 'red', textAlign: 'center' }}>{emailError}</p>
              }

              <form onSubmit={validateLogin} style={{ color: 'white', marginTop: '15%' }}>
                <div className="row">
                  <div className=" login-form-icon">
                    {/* <label className="form-label pb-1">Email:</label> */}
                    <p><input
                      ref={emailRef}
                      placeholder='Enter email' id='loginemail' type="text" name="email" value={email} onChange={(e) => { setEmail(e.target.value) }} required="required" className='form-control m-0 border-radius' />
                      <i ref={emailInputErrorRef} className="fas fa-exclamation-circle input-error-icon"></i>
                      <small ref={emailEmptyRef} id='email-empty'></small></p>

                  </div>

                  <div className=" login-form-icon" style={{ marginBottom: '5px' }}>
                    {/* <label className="form-label pb-1">Password:</label> */}
                    <p>  <input
                      ref={passwordRef}
                      id='loginpassword' type="password" name="password" value={password} onChange={(e) => { setPassword(e.target.value) }} required="required" placeholder='Enter password' className='form-control m-0 border-radius' />
                      <i ref={passwordInputErrorRef} className="fas fa-exclamation-circle  input-error-icon2"></i>

                      <small ref={passwordEmptyRef} id='password-empty'></small></p>


                  </div>
                  <div className="submit-btns-log" style={{ marginTop: '0' }}>
                    <button className="mtr-btn signup" type='submit' style={{ backgroundColor: '#033347' }} >
                      <span>Log in</span>
                    </button>
                  </div>
                </div>



                <div className="submit-btns-log">
                  <a href="#" style={{ width: '150px' }} >Forgot Password?</a>

                </div>

                <div className="submit-btns-log">
                  <button className="google-login-btn" onClick={handleClick}>
                    <FontAwesomeIcon icon={faGoogle} /> {/* Google icon */}
                  </button>
                </div>
                <div className="submit-btns-log">





                </div>
              </form>

              <div className="row" style={{ marginBottom: '10%', marginTop: '10px' }}>
                <div className="col-6">
                  <img src={playStore} alt="" className="play-store-image" style={{ maxHeight: '60px' }} />
                </div>
                <div className="col-6">
                  <img src={appStore} alt="" className="app-store-image" style={{ maxHeight: '60px' }} />
                </div>
              </div>
              <div className="row justify-content-center" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <span style={{ color: 'black', fontSize: '15px', display: 'flex' }}>
                  Don't have an account?
                </span>

                <NavLink
                  to="/register"
                  className="already-have signupButton"
                  style={{

                    fontSize: '18px',
                    fontWeight: 'bold',
                    border: '1px solid #033347',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '10px',
                    height: '40px',
                    borderRadius: '10px'
                  }}
                >
                  Sign up
                </NavLink>
              </div>
            </div>
            <div className="widget">
              <ul className="list-style">
                <li><NavLink  to="/about">About</NavLink></li>
                <li><a href='#' onClick={handleTermsChange}  title="Privacy">Privacy</a></li>
              </ul>

            </div>
          </div>
          {showTermsModal && (
                <TermsModal
                    onClose={() => setShowTermsModal(false)}
                    onAccept={() => {
                        
                        setShowTermsModal(false);
                    }}
                />
            )}
        </div>

      </div>


    </Layout>
  )
}
