import { authenticateAxios } from './authAxios';

class ReelsServices {
    constructor() {
        this.authAxios = authenticateAxios();  // Her zaman geçerli kullanıcı ile axios instance'ını başlat
    }
    
    createReels = async (userId, formData) => {
        console.log(formData);
        const result = await this.authAxios.post(`/reels/web/${userId}`, formData);
        return result;
    }

    getReelForUser = async (email) => {
        const result = await this.authAxios.get(`reel/user/${email}`);
        return result;
    }

    getPreviewReel = async () => {
        const result = await this.authAxios.get(`/reels/following_reels`);
        console.log(result)
        return result;
    }

    getAllReels = async () => {
        const result = await this.authAxios.get(`reels/my_reels`);
        console.log(result)
        return result.data;
    }
}

export default new ReelsServices();