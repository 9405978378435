import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAndSetUserDetails } from '../../../store/actions/authActions';
import AppHeader from '../layout/AppHeader';
import AuthService from '../../../services/auth.services';
import Activities from '../../../services/ActivityService';
import FriendsService from '../../../services/FriendService';
import GroupService from '../../../services/GroupService';
import { formatDistanceToNow, parseISO } from 'date-fns';
import '../../../assets/css/activity.css';

export default function ActivityComponent() {
  const [loading, setLoading] = useState(true);
  const [activities, setActivities] = useState([]);
  const [filteredActivities, setFilteredActivities] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.userDetails?.data);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const userId = AuthService.getCurrentUser().id;
        if (userId) {
          await dispatch(fetchAndSetUserDetails(userId));
        }
      } catch (error) {
        console.error("fetchAndSetUserDetails err:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDetails();
    getActivities();
  }, [dispatch]);
  
  useEffect(() => {
    const lowercasedFilter = searchTerm.toLowerCase();
    const filteredData = activities.filter(activity => {
      const userName = `${activity.user.firstName} ${activity.user.lastName}`.toLowerCase();
      const notificationBody = activity.activityDetails.notificationBody?.toLowerCase() || '';
      const notificationText = activity.activityDetails.notificationStyleText?.toLowerCase() || '';
      return userName.includes(lowercasedFilter) || notificationBody.includes(lowercasedFilter) || notificationText.includes(lowercasedFilter);
    });
    setFilteredActivities(filteredData);
  }, [searchTerm, activities]);

  const getActivities = async () => {
    try {
      const activities = await Activities.getActivities();
      setActivities(activities.data);
      setFilteredActivities(activities.data);
      readActivities(activities.data);
    } catch (error) {
      console.error("getActivities err:", error);
    }
  };
  
  const readActivities = async (activities) => {
    try {
      const unreadActivities = activities.filter(activity => activity.isRead === 0);
      if (unreadActivities.length > 0) {
        await Activities.readActivities();
      }
    } catch (error) {
      console.error("readActivities err:", error);
    }
  };

  const handleSearchedFollowers = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleAccept = async (activityId, activity, acceptedId) => {
    try {
      if (activity === 'FOLLOW_REQUEST') {
        await FriendsService.acceptRequest(acceptedId);
      } else if (activity === 'GROUP_INVITE') {
        await GroupService.acceptInvitation(acceptedId);
      } else if (activity === 'GROUP_JOIN_REQUEST') {
        await GroupService.acceptMemberRequest(acceptedId);
      }
      setFilteredActivities(prevActivities => prevActivities.filter(a => a.id !== activityId));
      setActivities(prevActivities => prevActivities.filter(a => a.id !== activityId));
    } catch (error) {
      console.error('Error accepting request:', error);
    }
  };

  const handleDecline = async (activityId, activity, declinedId) => {
    try {
      if (activity === 'FOLLOW_REQUEST') {
        await FriendsService.declineRequest(declinedId);
      } else if (activity === 'GROUP_INVITE') {
        await GroupService.rejectInvitation(declinedId);
      } else if (activity === 'GROUP_JOIN_REQUEST') {
        await GroupService.rejectMemberRequest(declinedId);
      }
      setFilteredActivities(prevActivities => prevActivities.filter(a => a.id !== activityId));
      setActivities(prevActivities => prevActivities.filter(a => a.id !== activityId));
    } catch (error) {
      console.error('Error declining request:', error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <AppHeader />
      <div className="notifications-panel">
        <div className="notifications-header">
          <h2>Notifications</h2>
          <input
            type="text"
            placeholder="Search Activities"
            onChange={handleSearchedFollowers}
          />
        </div>
        <div className="notifications-content">
          {filteredActivities.length > 0 ? (
            <div className="activity-list">
              {filteredActivities.map(activity => (
                <div key={activity.id} className="activity-item">
                  <div className="activity-header">
                    <img
                      className="activity-profile-pic"
                      src={activity.user.profilePic || '../assets/images/img/main-logo--dont-touch-or-edit-@2x.png'}
                      alt="Profile"
                    />
                    <div className="activity-info">
                      <div className="activity-user-name">
                        {activity.user.firstName} {activity.user.lastName}
                      </div>
                      <div className="activity-description">
                        {activity.activityDetails.notificationBody || activity.activityDetails.notificationStyleText}
                      </div>
                      <div className="activity-time">
                        {formatDistanceToNow(parseISO(activity.createdAt), { addSuffix: true })}
                      </div>
                    </div>
                  </div>
                  {(activity.comment || activity.reply) && (
                    <div className="activity-body">
                      <p className="activity-comment">
                        "{(activity.comment?.content ?? activity.reply?.content).substring(0, 100)}"
                      </p>
                    </div>
                  )}
                  {(activity.activity === 'FOLLOW_REQUEST' ||
                    activity.activity === 'GROUP_INVITE' ||
                    activity.activity === 'GROUP_JOIN_REQUEST') && (
                      <div className="activity-actions">
                        <button onClick={() => handleDecline(activity.id, activity.activity, activity.group?.id || activity.user.id)}>Reject</button>
                        <button onClick={() => handleAccept(activity.id, activity.activity, activity.group?.id || activity.user.id)}>Accept</button>
                      </div>
                    )}
                </div>
              ))}
            </div>
          ) : (
            <div className="no-activities">No activities yet!</div>
          )}
        </div>
      </div>
    </>
  );
}
