import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import CustomAlert from '../../../elements/CustomAlert'; 
import '../../../../assets/css/ReportDialog.css';
import ReportServices from '../../../../services/report';
import constance from '../../../../config/contance';

function ReportDialog({ open, onClose, post }) {
    const [reportType, setReportType] = useState('');
    const [error, setError] = useState('');
    const { reportTypes } = constance;

    const handleReport = async () => {
        try {
            const res = await ReportServices.createReport(reportType, post.id, reportTypes.POST);
          
            if (res.data.success) {
                setError(''); 
                onClose();
            } else {
                setError('This post has already been reported.');
            }
        } catch (error) {
            console.error('handle report error: ', error);
            setError('An error occurred while reporting. Please try again.');
        }
    };

    if (!open) return null;

    return ReactDOM.createPortal(
        <>
            {error && <CustomAlert message={error} onClose={() => setError('')} />}
            <div className="dialog-backdrop">
                <div className="dialog-content">
                    <div className="dialog-body">
                        <label>Type of Report</label>
                        <select className="select" value={reportType} onChange={e => setReportType(e.target.value)}>
                            <option value="spam">Spam</option>
                            <option value="abuse">Abuse</option>
                            <option value="other">Other</option>
                        </select>
                    </div>
                    <div className="dialog-footer" style={{ display: 'flex' }}>
                        <button className="button" onClick={onClose}>Cancel</button>
                        <button className="button" onClick={handleReport} style={{ backgroundColor: '#28a745' }}>Report</button>
                    </div>
                </div>
            </div>
        </>,
        document.body
    );
}

export default ReportDialog;
