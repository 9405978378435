import axios from "axios";
import settings from "./Settings";
import { setUser } from '../store/actions/authActions';
import store from '../store/store';
import { jwtDecode } from "jwt-decode";
import encryptionService from '../services/encryptionService'; 

const api = `${settings.apiUrl}/api/v1/auth`;

class AuthService {

  login = async (email, password) => {
    let userData = {};
    return await axios
      .post(api + "/login", { email, password })
      .then(async (response) => {
        if (response.data.jwt) {
          localStorage.setItem("user", JSON.stringify(response.data));
          const decodedToken = jwtDecode(response.data.jwt);

          userData = {
            ...decodedToken,
            jwt: response.data.jwt,
          };

          console.log('Login successfuly : ', userData);

          store.dispatch(setUser(userData));

          // Key kontrolü ve oluşturulması
          await encryptionService.checkStoredKeys(userData.id);

        }
        return userData;
      });
  };

  alternativeLogin = async (userData) => {
    const response = await axios.post(`${api}/alternative_login`, userData);
    if (response.data.jwt) {
      localStorage.setItem('user', JSON.stringify(response.data));
      return response.data;
    } else {
      throw new Error('No JWT token received');
    }
  };

  verifyEmailConfirmOTP = async (otpValue, email) => {
    console.log(otpValue);
    return await axios.get(`${api}/verify_otp_email_verify/${email}`, {
      params: {
        otp: otpValue
      }
    });
  }

  isAuthenticated = () => {
    const userString = localStorage.getItem("user");
    const user = userString ? JSON.parse(userString) : null;
    return user && user.jwt ? true : false;
  };

  getCurrentUser = () => {
    const userString = localStorage.getItem("user");

    if (userString !== null) {
      try {
        const user = JSON.parse(userString);
        const decoded = jwtDecode(user.jwt);
        return { ...decoded, rawJwt: user.jwt };
      } catch (error) {
        console.error("JSON parse or JWT decode error:", error);
        return null;
      }
    } else {
      return null;
    }
  };

  logout() {
    localStorage.removeItem("user");
    window.location = '/login';
  }

  createUser = async (user) => {
    console.log('Attempting to create user:', user);
    try {
      const response = await axios.post(`${api}/register`, user);
      console.log('User created successfully:', response.data);

      // Yeni kullanıcı için anahtar oluşturma
      const decodedToken = jwtDecode(response.data.jwt);
      const userId = decodedToken.id;
      await encryptionService.generateKeys(userId);

    } catch (error) {
      console.error('Error creating user:', error.response ? error.response.data : error);
      throw error;
    }
  };
}

export default new AuthService();
